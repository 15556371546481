<template>
	<aside class="toolbox col-3" id="control_panel">
		<div class="flex flex-wrap align-items-center justify-content-center ml-1 mr-1 mb-2">
			<div class="shadow-none pl-2 font-semibold text-xs pt-2 pb-2 w-full flex align-items-left justify-content-left">
				<i class="pi pi-cog mr-2 mt-2"></i>
				<span class="text-sm">Control Panel</span>
			</div>
			<div v-if="currentElementType == null && !this.$store.state.profileModule.isAdmin && this.$store.state.order.isProduct==1">
				Please select object for editting
			</div>
		</div>
		<template v-if="this.$store.state.profileModule.isAdmin || this.$store.state.order.isAdminAddEdit || ((this.$store.state.profileModule.isAdmin || (!this.$store.state.profileModule.isAdmin && this.$store.state.order.isProduct==0) || this.$store.state.order.isAdminAddEdit))">
			<div class="flex flex-wrap bg-white align-items-center justify-content-center ml-1 mr-1 mb-2">
				<div class="border-bottom-1 border-gray-200 shadow-none pl-2 font-semibold text-xs pt-2 pb-2 surface-card w-full flex align-items-left justify-content-left">
					<img :src="`${this.pathUrl}/images/icons/svg/shape-config.svg`" width="18">
					<span>Shape type & size</span>
				</div>
				<div class="control-content w-full ml-1 mr-1 pl-2 pr-2 mt-0 pb-3">
					<div class="size-list-group">
						<span class="text-left font-medium">Type Presets:</span>
						<select
							:disabled="!this.$store.state.profileModule.isAdmin && this.$store.state.order.isProduct==1 && !this.$store.state.order.isAdminAddEdit"
							class="shapes-select custom-select"
							v-model="general.settedShape[$store.state.canvas.$side].backgrounds_shape"
							@change="setShape($event)">
							<option
								v-for="(shape, index) in shapes"
								:value="shape.backgrounds_shape"
								v-bind:key="index">
								{{ shape.backgrounds_shape }}
							</option>
						</select>
					</div>
					<div class="mt-3 control-content p-0">
						<div class="size-list-group">
							<span class="text-left font-medium">Size Presets:</span>
							<select
								:disabled="!this.$store.state.profileModule.isAdmin && this.$store.state.order.isProduct==1 && !this.$store.state.order.isAdminAddEdit"
								class="shapes-select custom-select"
								v-model="general.settedShape[this.$store.state.canvas.$side].prefixName"
								@change="setShapeSize($event)">
								<option
									v-for="(option, index) in selectedSizeOfShape"
									:value="option.prefixName"
									v-bind:key="index">
									{{option.value}} {{ option.text }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>            
			<div class="flex flex-wrap bg-white align-items-left justify-content-left ml-1 mb-2 mr-1">
				<div class="border-bottom-1 border-gray-200 shadow-none pl-2 font-semibold text-xs pt-2 pb-2 surface-card w-full flex align-items-left justify-content-left">
					<img :src="`${this.pathUrl}/images/icons/svg/colors.svg`" width="18">
					<span>Shape Colors</span>
				</div>			
				<div class="control-content">
					<div class="swatch-group">
						<Button type="button" class="background-button" :style="{'backgroundColor': general.settedShape[$store.state.canvas.$side].fill || backgroudShapeColor}" label="" @click="toggleBackGround" />
						<OverlayPanel ref="opBackShape">
							<Sketch 
								:preset-colors="presetColor"
								v-model="backgroudShapeColor">
							</Sketch>
							<div class="flex mt-2">
								<div class="flex-1 flex align-items-center justify-content-center">
									<Button label="Cancel" @click="toggleBackGround" severity="secondary" text raised />
								</div>
								<div class="flex-1 flex align-items-center justify-content-center">
									<Button label="Ok" raised @click="setShapeColor(true)" />
								</div>
							</div>
						</OverlayPanel>
						<span>Background Color</span>
					</div>
				</div>
			</div>
		</template>

		<template v-if="currentElementType === 'i-text' || currentElementType === 'text-curved' && currentElementType != 'image'">
			<div class="flex flex-wrap bg-white align-items-left justify-content-leftnt-3 ml-1 mr-1 pl-2">
				<div class="border-bottom-1 border-gray-200 shadow-none pl-2 font-semibold text-xs pt-2 pb-2 surface-card w-full flex align-items-left justify-content-left">
					<img :src="`${this.pathUrl}/images/icons/svg/text.svg`" width="18">
					<span>Text Formatting</span>
				</div>
				<div class="swatch-group grid pl-1">
					<div class="col-6">
						<Button
							type="button"
							class="background-button button-height button-color-width"
							:style="{'backgroundColor': general.fill}"
							label=""
							@click="toggleTextColor" />
						<OverlayPanel ref="opTextColor">
							<Sketch
								:preset-colors="presetColor"
								v-model="textColor">
							</Sketch>
							<div class="overflow-hidden mt-2">
							<div class="flex">
								<div class="flex-1 flex align-items-center justify-content-center">
									<Button label="Cancel" @click="toggleTextColor" severity="secondary" text raised />
								</div>
								<div class="flex-1 flex align-items-center justify-content-center">
									<Button label="Ok" raised @click="setColor" />
								</div>
							</div>
						</div>
						</OverlayPanel>
						<span class="ml-1 text-xs">Text color</span>
					</div>
					<div class="col-6 grid">
						<div class="col-6"> 
						<InputSwitch
							class="button-height button-width"
							@change="convertCurvedText"
							v-model="text.isCurved"
							/>
						</div>
						<div class="col-6">
							<img :src="`${this.pathUrl}/images/icons/svg/text-curved.svg`" width="32" class="ml-1">
						</div>
					</div>
				</div>
				<div class="grid pl-1">
					<div class=" col-12 p-0">
						<span class="text-xs ml-2">Font Family</span>
					</div>
					<div class="col-12 p-1">
						<Dropdown
							v-model="text.fontFamilyName"
							:options="text?.fontFamily"
							optionLabel="name"							
							@change="setFontFamily"
							placeholder="Select Font family"
							:showClear="true"
							class="button-height"
							>
							 <template #value="slotProps">
								<div class="country-item country-item-value" v-if="slotProps?.value?.name">
									<div>{{slotProps.value.name}}</div>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="country-item">
									<div :style="{'fontFamily': slotProps.option.value}">{{slotProps.option.name}}</div>
								</div>
							</template>
						</Dropdown>
					</div>				
					<div class="col-12 mt-2 p-0">
						<span class="text-xs ml-2">Font Weight</span>
					</div>
					<div class="col-12 p-1">
						<Dropdown 
							v-model="text.fontWeightName"
							:options="text.fontWeight"
							optionLabel="name"
							@change="setFontWeight"
							placeholder="Select Font Weight"
							:showClear="true">
							<template #value="slotProps">
								<div class="country-item country-item-value" v-if="slotProps.value.name">							
									<div>{{slotProps.value.name }}</div>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="country-item">
									<div :style="{'fontWeight': slotProps.option.value}">{{slotProps.option.name}}</div>
								</div>
							</template>
						</Dropdown>
					</div>
				</div>
				<div class="grid mt-2 ml-0">
					<div class="col-12 grid p-0">
						<div class="col-6 ml-2" v-if="text.isCurved">
							<div class="grid">
								<div class="col-12 m-0 p-0 pt-1 text-center">
									<span class="text-xs white-space-nowrap">Flip text</span>
								</div>
								<div class="col-12 m-0 p-0">
									<Button class="flip-class-size" :variant="!text.isFlipped ? 'dark' : 'light'" 
									v-model="text.isFlipped"
									@click="flipCurvedText(false)">
										<img :src="`${this.pathUrl}/images/icons/svg/flip-bottom.svg`" width="32">
								</Button>
								<Button class="ml-1 flip-class-size" :variant="text.isFlipped ? 'dark' : 'light'" 
									@click="flipCurvedText(true)">
										<img :src="`${this.pathUrl}/images/icons/svg/flip-top.svg`" width="32">
								</Button>
								</div>
							</div>
						</div>					
						<div class="col-4" v-if="!text.isCurved">
							<div class="grid">
								<div class="col-12 m-0 p-0 pt-1 text-center">
									<span class="text-xs white-space-nowrap">Line height</span>
								</div>
								<div class="col-12 m-0 p-0">
									<InputNumber
										:min="0.5"
										:step="0.05"
										:max="3"
										class="params-text button-height"
										v-model="text.lineHeight"
										@click="setLineHeight"
										mode="decimal"
										showButtons
									/>
								</div>
							</div>
						</div>
						<div class="col-4" v-if="!text.isCurved">
							<div class="grid">
								<div class="col-12 m-0 p-0 pt-1 text-center">
									<span class="text-xs white-space-nowrap">Letter spacing</span>
								</div>
								<div class="col-12 m-0 p-0">
									<InputNumber
										class="params-text button-height"
										v-model="text.charSpacing"
										@click="setLetterSpacing"
										mode="decimal"
										showButtons
									/>
								</div>
							</div>
						</div>
						<div class="col-4">
							<div class="grid">
								<div class="col-12 m-0 p-0 pt-1 text-center">
									<span class="text-xs">Font size</span>
								</div>
								<div class="col-12 m-0 p-0">
									<InputNumber
										inputId="fontsize"
										class="params-text button-height"
										v-model="text.fontSize"
										mode="decimal"
										@click="setFontSize"
										showButtons
										:min="1"
										:max="600" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="grid mt-2 w-full" v-if="currentElementType === 'text-curved'">
					<div class="col-12 m-0 p-0 pt-1">
						<span class="text-xs ml-2">Diameter</span>
					</div>
					<div class="col-12 grid m-0 p-0">
						<div class="col-10">
							<Slider
								v-model="text.diameter"
								@change="setDiameter"
								@mouseup="addHistory"
								:min="150"
								:max="720"/>
						</div>
						<div class="slider-value col-2 vertical-align-middle pb-0 pt-0 pr-1">{{ parseInt(text.diameter) }}px</div>
					</div>
				</div>	
				<div class="grid mt-0 w-full">
					<div class="col-5 ml-1 mt-0 grid">
						<div class="col-12 m-0 p-0 pl-2">
							<span class="text-xs">Font Style</span>
						</div>
						<div class="col-12 grid m-0 p-0 ml-0 m-auto">
							<Button class="text-decoration pl-2" :variant="text.italic ? 'dark' : 'light'" @click="setTextItalic"><font-awesome-icon :icon="icons.italic"/></Button>
							<Button v-if="currentElementType !== 'text-curved'" class="text-decoration pl-2" :variant="text.underline ? 'dark' : 'light'" @click="setTextUnderline"><font-awesome-icon :icon="icons.underline"/></Button>
						</div>
					</div>	
					<div class="col-7 mt-0 ml-0 mr-0 grid" v-if="!text.isCurved">						
						<div class="col-12 m-0 p-0 text-center">
							<span class="text-xs">Text Align</span>
						</div>
						<div class="col-12 grid m-0 p-0">
							<Button class="text-align ml-0 mr-1 pl-2" :variant="text.textAlign === 'left' ? 'dark' : 'light'" @click="setTextAlign('left')"><font-awesome-icon :icon="icons.left"/></Button>
							<Button class="text-align ml-0 mr-1 pl-2" :variant="text.textAlign === 'right' ? 'dark' : 'light'" @click="setTextAlign('right')"><font-awesome-icon :icon="icons.right"/></Button>
							<Button class="text-align ml-0 mr-1 pl-2" :variant="text.textAlign === 'center' ? 'dark' : 'light'" @click="setTextAlign('center')"><font-awesome-icon :icon="icons.center"/></Button>
							<Button class="text-align ml-0 mr-0 pl-2" :variant="text.textAlign === 'justify' ? 'dark' : 'light'" @click="setTextAlign('justify')"><font-awesome-icon :icon="icons.justify"/></Button>
						</div>
					</div>				
				</div>
			</div>
		</template>
		<template  v-if="currentElementType != null && currentElementType == 'border'">
			<div class="flex flex-wrap bg-white align-items-center justify-content-center ml-1 mt-1 mr-1 mb-3">
				<div class="border-bottom-1 border-gray-200 shadow-none pl-2 font-semibold text-xs pt-2 pb-2 surface-card w-full flex align-items-left justify-content-left">
					<img :src="`${this.pathUrl}/images/icons/svg/shape-config.svg`" width="18">
					<span>Border options</span>
				</div>		
				<div class="col-12 grid mt-1 m-0 p-0 block-params">
					<div class="col-12 mt-3 mb-2">
						<Button type="button" class="background-button" :style="{'backgroundColor': general.stroke}" label="" @click="toggleStrokeColor" />
						<OverlayPanel ref="opStrokeColor">
							<Sketch
								:preset-colors="presetColor"
							 	v-model="borderStrokeColor">
							</Sketch>
							<div class="overflow-hidden mt-2">
								<div class="flex">
									<div class="flex-1 flex align-items-center justify-content-center">
										<Button label="Cancel" @click="toggleStrokeColor" severity="secondary" text raised />
									</div>
									<div class="flex-1 flex align-items-center justify-content-center">
										<Button label="Ok" raised @click="setBorderStrokeColor" />
									</div>
								</div>
							</div>
						</OverlayPanel>
						<span class="text-xs ml-2 white-space-nowrap">Border Color</span>
					</div>
					<div class="col-12 p-0 ml-2 grid">
						<div class="col-10">
							<div class="mb-1 text-xs">Border width</div>
							<Slider
								:min="1"
								:max="100"
								v-model="general.strokeWidth"
								@change="setStrokeWidthBorder"/>
						</div>
						<div class="col-2 m-0 p-0 mt-4 text-xs">{{ parseInt(general.strokeWidth) }}px</div>
					</div>
					<div class="col-12 p-0 ml-2 grid">
						<div class="col-10">
							<div class="mb-1 text-xs">Border scale width</div>
							<Slider
								:min="0.5"
								:max="2.555"
								:step="0.01"
								v-model="general.scaleX"
								@change="setScaleX"/>
						</div>
						<div class="col-2 m-0 p-0 mt-4 text-xs">{{ general.scaleX.toFixed(2) }}</div>
					</div>
					<div class="col-12 p-0 ml-2 grid">
						<div class="col-10">
							<div class="mb-1 text-xs">Border scale height</div>
							<Slider
								:min="0.5"
								:max="2.555"
								:step="0.01"
								v-model="general.scaleY"
								@change="setScaleY"/>
						</div>
						<div class="col-2 m-0 p-0 mt-4 text-xs">{{ general.scaleY.toFixed(2) }}</div>
					</div>
				</div>
			</div>
		</template>
		<div class="grid mb-0 pb-0 mt-3 border-top-1 border-500" v-if="currentElementType != null && currentElementType != 'border'">
			<div class="col-12 grid">
				<div class="col-7">
					<span><img :src="`${this.pathUrl}/images/icons/svg/path.svg`" width="18"></span>
					<span class="font-bold text-sm">Stroke/Outline</span>
				</div>
				<div class="col-5">
					<Checkbox
						v-model="general.hasStroke"
						@click="setStroke"
						:binary="true"
					/><b class="open-block ml-1">enable</b>
				</div>
			</div>
			<div class="col-12 grid m-0 p-0 block-params" :style="{display: general.hasStroke ? 'block' : 'none'}">
				<div class="col-12">
					<Button type="button" class="background-button" :style="{'backgroundColor': general.stroke}" label="" @click="toggleStrokeColor" />
					<OverlayPanel ref="opStrokeColor">
						<Sketch
							:preset-colors="presetColor"
							v-model="strokeColor"
							></Sketch>
							<div class="overflow-hidden mt-2">
								<div class="flex">
									<div class="flex-1 flex align-items-center justify-content-center">
										<Button label="Cancel" @click="toggleStrokeColor" severity="secondary" text raised />
									</div>
									<div class="flex-1 flex align-items-center justify-content-center">
										<Button label="Ok" raised @click="setStrokeColor" />
									</div>
								</div>
							</div>
					</OverlayPanel>
					<span class="text-xs ml-2 white-space-nowrap">Stroke Color</span>
				</div>
				<div class="col-12 p-0 ml-2 grid">
					<div class="col-10">
						<span class="mb-1 text-xs">Stroke width</span>
						<Slider
							class="mt-1 m-0 p-0"
							:min="0"
							:step="1"
							:max="50"
							v-model="general.strokeWidth"
							@change="setStrokeWidth"
							@mouseup="addHistory">
						</Slider>
					</div>
					<div class="col-2 m-0 p-0 mt-5 text-xs">{{ general.strokeWidth }}px</div>
				</div>
			</div>
		</div>
		<div class="grid mb-0 pb-0 mt-3 border-top-1 border-500" v-if="currentElementType != null">
			<div class="col-12 grid">
				<div class="col-7">
					<span><img :src="`${this.pathUrl}/images/icons/svg/shadow.svg`" width="18"></span>
					<span class="font-bold text-sm">Drop Shadow</span>
				</div>
				<div class="col-5">
					<Checkbox
						v-model="general.hasShadow"
						@click="openShadowBlock"
						:binary="true" 
					/><b class="open-block">enable</b>
				</div>
			</div>
			<div class="col-12 grid m-0 p-0 block-params" :style="{display: general.hasShadow ? 'block' : 'none'}">
				<div class="col-12 mt-1">
					<Button type="button" class="background-button" :style="{'backgroundColor': general.shadow.color}" label="" @click="toggleShadowColor" />
					<OverlayPanel ref="opShadowColor">
						<Sketch
							:preset-colors="presetColor"
							v-model="shadowColor">
						</Sketch>
						<div class="flex mt-2">
								<div class="flex-1 flex align-items-center justify-content-center">
									<Button label="Cancel" @click="toggleShadowColor" severity="secondary" text raised />
								</div>
								<div class="flex-1 flex align-items-center justify-content-center">
									<Button label="Ok" raised @click="setShadowColor" />
								</div>
							</div>
					</OverlayPanel>
					<span class="text-xs ml-2 white-space-nowrap">Shadow Color</span>
				</div>
				<div class="col-12 p-0 ml-2 grid">
					<div class="col-10  ">
						<div class="mb-1 text-xs">X offset</div>
						<Slider
							:min="-30"
							:max="30"
							v-model="general.shadow.offsetX"
							@change="setShadow"
							@mouseup="addHistory"/>
					</div>
					<div class="col-2 m-0 p-0 pt-4 text-xs">{{ general.shadow.offsetX }}px</div>
				</div>
				<div class="col-12 p-0 ml-2 grid">
					<div class="col-10  ">
						<div class="mb-1 text-xs">Y offset</div>
						<Slider
							:min="-30"
							:max="30"
							v-model="general.shadow.offsetY"
							@change="setShadow"
							@mouseup="addHistory"/>
					</div>
					<div class="col-2 m-0 p-0 pt-4 text-xs">{{ general.shadow.offsetY }}px</div>
				</div>
				<div class="col-12 p-0 ml-2 grid">
					<div class="col-10  ">
						<div class="mb-1 text-xs">Blur</div>
						<Slider
						:min="0"
						:max="100"
						v-model="general.shadow.blur"
						@change="setShadow"
						@mouseup="addHistory"/>
					</div>
					<div class="col-2 m-0 p-0 pt-4 text-xs">{{ general.shadow.blur }}px</div>
				</div>
			</div>
		</div>
		<template v-if="currentElementType">
			<div class="slider-group-container opacity-block mt-3">
				<div class="slider-group">
					<div class="ml-3">Opacity</div>
					<div class="slider-params">
						<div class="ui-slider">
							<input
								class="cursor-pointer"
								type="range"
								min="0"
								max="100"
								v-model="general.opacity"
								@change="setOpacity"
							/>
						</div>
						<div class="slider-value">{{ parseInt(general.opacity) }}%</div>
					</div>
				</div>
			</div>
		</template>
	
	</aside>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Checkbox from 'primevue/checkbox';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Slider from 'primevue/slider';
import {mapActions, mapMutations} from 'vuex';
import OverlayPanel from 'primevue/overlaypanel';
import { Sketch, Photoshop } from '@ckpack/vue-color';
import {toRaw, isProxy} from 'vue';
import axios from 'axios';

import {faCogs,
		faItalic,
		faBold,
		faUnderline,
		faStrikethrough,
		faAlignCenter,
		faAlignJustify,
		faAlignLeft,
		faChevronDown,
		faChevronUp,
		faAlignRight} from "@fortawesome/free-solid-svg-icons";

export default {
	name: "ControlPanel",
	components:{
		Accordion: Accordion,
		AccordionTab: AccordionTab,
		Checkbox: Checkbox,
		InputSwitch: InputSwitch,
		Dropdown: Dropdown,
		InputNumber: InputNumber,
		Slider: Slider,
		OverlayPanel: OverlayPanel,
		Sketch: Sketch,
		photoshop: Photoshop
	},
	props: ['zoom', 'factor'],
	data() {
		return {
			backgroudShapeColor: '#ffffff',
			textColor: '#000000',
			strokeColor: '#000000',
			borderStrokeColor: '#000000',
			shadowColor: '#000000',
			suckerCanvas: null,
			suckerArea: [],
			isOpenSucker: false,
			theme: '',
			inAnimation: false,
			icons: {
				cogs: faCogs,
				italic: faItalic,
				bold: faBold,
				underline: faUnderline,
				strike: faStrikethrough,
				center: faAlignCenter,
				justify: faAlignJustify,
				right: faAlignRight,
				left: faAlignLeft,
				chevronDown: faChevronDown,
				chevronUp: faChevronUp,
			},
			currentElementType: null,
			text: {
				options: ['',''],
				value: '',
				fontFamily: [
					{name: "Libre Baskerville", value: 'LibreBaskerville'},
					// {name: 'Times New Roman', value: 'Times New Roman'},
					{name: 'Agency', value: 'Agency'},
					{name: 'Alex Brush', value: 'Alex Brush'},
					{name: 'Alger', value: 'Alger'},
					{name: 'Allura', value: 'Allura'},
					{name: "Anton", value: 'Anton'},
					{name: "Arcade", value: 'Arcade'},
					{name: "Asap condensed", value: 'Asap condensed'},
					{name: "Attic", value: 'Attic'},
					{name: "Bad script", value: 'Bad script'},
					{name: "Bell", value: 'Bell'},
					{name: "Blackchance", value: 'Blackchance'},
					{name: "Broadway", value: 'Broadway'},
					{name: "Campanile", value: 'Campanile'},
					{name: "Cantor", value: 'Cantor'},
					{name: "Charlemagne", value: 'Charlemagne'},
					{name: "Chewy", value: 'Chewy'},
					{name: "Cinzel", value: 'Cinzel'},
					{name: "Cloister", value: 'Cloister'},
					{name: "Copper Plate", value: 'Copper Plate'},
					{name: "Curlz", value: 'Curlz'},
					{name: "Delius", value: 'Delius'},
					{name: "Dymaxion Script", value: 'Dymaxion Script'},
					{name: "Eccentric", value: 'Eccentric'},
					{name: "Eluded", value: 'Eluded'},
					{name: "Engrave", value: 'Engrave'},
					{name: "Fondamento", value: 'Fondamento'},
					{name: "Frizzy", value: 'Frizzy'},
					{name: "Great Vibes", value: 'Great Vibes'},
					{name: "Harrington", value: 'Harrington'},
					{name: "Hill House", value: 'Hill House'},
					{name: "Lemon", value: 'Lemon'},					
					{name: "Luggie", value: 'Luggie'},
					{name: "Lunges", value: 'Lunges'},
					{name: "Lovers Quarrel", value: 'Lovers Quarrel'},
					{name: "Macule", value: 'Macule'},
					{name: "Ostrich", value: 'Ostrich'},
					{name: "Rubik", value: 'Rubik'},
					{name: "Saginaw", value: 'Saginaw'},
					{name: "Scriptina", value: 'Scriptina'},
					{name: "Seaside", value: 'Seaside'},
					{name: "Southampton", value: 'Southampton'},
					{name: "Staatliches", value: 'Staatliches'},
					{name: "Tech", value: 'Tech'},
					{name: "Tusj", value: 'Tusj'}
				],
				fontFamilyName: {			
					name: "Libre Baskerville", value: 'LibreBaskerville'		
					// name: 'Times New Roman',
					// value: 'Times New Roman'
				},			
				fontWeightName: {
					name: 'Thin',
					value: 100
				},
				fontWeight: [
					{
						name: 'Thin',
						value: 100
					},
					{
						name: 'Extra Light',
						value: 200
					},
					{
						name: 'Light',
						value: 300
					},
					{
						name: 'Normal',
						value: 400
					},
					{
						name: 'Medium',
						value: 500
					},
					{
						name: 'Semi Bold',
						value: 600
					},
					{
						name: 'Bold',
						value: 700
					},
					{
						name: 'Extra Bold',
						value: 800
					},
					{
						name: 'Black',
						value: 900
					},
					{
						name: 'Extra Black',
						value: 950
					},
				],
				fontSize: 10,
				isCurved: false,
				isFlipped: false,
				textAlign: 'left',
				diameter: 0,
				bold: false,
				italic: false,
				underline: false,
				textalign: 'left',
				lineHeight: 1,
				charSpacing: 1,
				letterHeight: 1
			},
			strokeType: [
				{ 
					name: 'Solid',
					value: [0, 0]
				},
				{ 
					name: 'Dash1',
					value: [2, 2]
				},
				{ 
					name: 'Dash2',
					value: [4, 4]
				},
				{ 
					name: 'Dash3',
					value: [6, 6]
				},
				{ 
					name: 'Dash4',
					value: [10, 10]
				},
				{ 
					name: 'Dash5',
					value: [15, 15]
				},
				{
					name: 'Dash6',
					value: [20, 20]
				},
				{ 
					name: 'Dash7',
					value: [10, 5]
				},
				{ 
					name: 'Dash8',
					value: [5, 5, 1, 5]
				},
				{ 
					name: 'Dash9',
					value: [7, 4, 14, 6]
				},
				{ 
					name: 'Dash10',
					value: [30, 30]
				} 
			],
			paternType:[
				{
					name: 'Checkerboard',
					value: 'checkerboard',
				},
				{
					name: 'Squares',
					value: 'squares',
				},
				{
					name: 'Diamonds',
					value: 'diamonds'
				},
				{
					name: 'Polkadots',
					value: 'polkadots'
				},
				{
					name: 'Stripes',
					value: 'stripes'
				},
				{
					name: 'Triangles',
					value: 'triangles'
				},
				{
					name: 'Triangles 2',
					value: 'triangles2'
				},
				{
					name: 'Paws',
					value: 'paws'
				},
				{
					name: 'Hearts',
					value: 'hearts'
				}
			],
			patternClipping: [
				{
					name: 'Not Set',
					value: ''
				},
				{
					name: 'Rectangle',
					value: 'rectangle'
				},
				{
					name: 'Circle',
					value: 'circle'
				},
				{
					name: 'Heart',
					value: 'heart'
				},
				{
					name: 'Scallop',
					value: 'scallop'
				},
				{
					name: 'Ellipse',
					value: 'ellipse'
				},
				{
					name: 'Star',
					value: 'star'
				}
			],
			shape: {
				type: null,
			},
			isObjectPattern: false,
			shapes: [],
			widthShape: 0,
			heightShape: 0,
			materialType: '',
			showCustomSizeBlock: false,			
			selectedShape: 'Rectangle',
			selectedSizeOfShape: [],			
			defaultShape: 'Rectangle',
			defaultWidth: 3.3,
			defaultHeight: 4,
			general: {
				activeSide: this.$store.state.canvas.$side,
				hasStroke: false,
				stroke: '#000000',
				strokeWidth: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: 100,
				width: 200,
				hasShadow: false,
				settedShape: {
					front: {
						backgrounds_shape: 'Rectangle',
						prefixName: '3.3x4',
						fill: '#FFFFFF',
						width: 3.3,
						height: 4,
						value: '3.3" × 4"'
					},
					back: {
						backgrounds_shape: 'Rectangle',
						prefixName: '2x2.4',
						fill: '#FFFFFF',
						width: 2,
						height: 2.4,
						value: '2" × 2.4"'
					}
				},
				shadow: {
					blur: 10,
					offsetX: 0,
					offsetY: 0,
					affectStroke: false,
					color: '#000000',
					opacity: 1
				}
			},
			Deselect: {
				render: createElement => createElement('span', ''),
			},
			presetColor: ['#000','#373737','#595959', '#747474','#999999','#ccc','#fff',
						'#ff0000','#f4bfbf','#ea9999','#e06666','#bb0404','#990000','#740d0d','#ff9900',
						'#fad4ad','#f9c188','#f6ad60','#e69138','#a85907','#783f04','#fff22d','#faf496',
						'#ffe599','#fcd359','#f9c114','#ce9d07','#8a6d11','#03fb52','#bce7ad','#acd899',
						'#8ac171','#5ca83b','#2f8c06','#2b5c11','#00ffff','#aadbe4','#92c1c8','#5ba3b3',
						'#388a9c','#156677','#063f4c','#0000ff','#a3ccf1','#89b8e4','#599cd9','#1973c5',
						'#0156a3','#164067','#9900ff','#cbbdea','#aa99d6','#7d66c0','#674ea7','#3c169b',
						'#2c1968','#ff00ff','#fbbafb','#dc9dd5','#c66dbf','#b444ab','#ac049e','#801e78']
		}
	},
	methods: {
		...mapMutations({			
			setProductMaterialType: 'order/setProductMaterialType',
			setProductSizes: 'order/setProductSizes',
			setProductPreset: 'order/setProductPreset',
			setProductPrice: 'order/setProductPrice'
		}),
		...mapActions({
			setProductPrice: 'order/setProductPrice',
			setProductMaterialType: 'order/setProductMaterialType',			
			setProductSizes: 'order/setProductSizes',
			setProductPreset: 'order/setProductPreset'
		}),
		setActiveSide(side) {
			this.general.activeSide = side;
			let activeSideShape = side == 'front' ? this.data.$shapeFront : this.data.$shapeBack;
			this.general.settedShape[this.$store.state.canvas.$side].backgrounds_shape = activeSideShape;
			//this.emitter.emit('selectShape', {shape: activeSideShape || this.general.settedShape[side]});
		},
		getShapeData() {
			let self = this;
			let form = new FormData;
			form.append('action', 'dm_get_shape_data');
			axios({
				method: 'post',
				url: this.$wpAjaxUrl,
				data: form
			}).then(response => {
				if (response && response.data) {
					self.shapes = response.data.shapes;
					// this.setDataShape()
					self.setSizeParams();
				}
			}).catch(error => {
				console.log(error);
			});
		},
		setDataShape(data) {
			this.general.settedShape.front = {
				backgrounds_shape: data.data.front.backgrounds_shape || 'Rectangle',
				fill: data.data.front.fill || '#FFFFFF',
				width: data.data.front.width || 3.3,
				height: data.data.front.height || 4,
				value: data.data.front.width ? data.data.front.width + '" × ' + data.data.front.height + '"' : '3.3" × 4"',
				prefixName: data.data.front.width ? data.data.front.width + '×' + data.data.front.height : '2×2.4'
			};
			this.general.settedShape.back = {
				backgrounds_shape: data.data.back.backgrounds_shape || 'Rectangle',
				fill: data.data.back.fill || '#FFFFFF',
				width: data.data.back.width || 2,
				height: data.data.back.height || 2.4,
				value: data.data.back.width ?  data.data.back.width + '" × ' + data.data.back.height + '"' : '2" × 2.4"',
				prefixName: data.data.back.width ? data.data.back.width + '×' + data.data.back.height : '2×2.4'
			};
			this.selectedShape = data.data[this.$store.state.canvas.$side].backgrounds_shape;
		},
		setShape(event) {
			let side = this.$store.state.canvas.$side;
			let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let border = null;
			canvas.getObjects().map((item) => {
				if (item.type == 'border') {		
					border = item;		
					this.emitter.emit('deleteObjects', item);
				}
			});

			let presets = this.$store.state.order.productPreset;
			this.general.settedShape[this.$store.state.canvas.$side].backgrounds_shape = event.target.value;
			this.selectedShape = event.target.value;
			presets[this.$store.state.canvas.$side] = {
				shape: event.target.value,
				fill: this.general.settedShape[this.$store.state.canvas.$side].fill
			};
			this.setProductPreset(presets);
			this.setSizeParams(null, true);
			// this.setShapeColor();
			// this.emitter.emit('selectShape', {shape: this.general.settedShape[this.$store.state.canvas.$side], border: border, setNewBorder: true});
		},
		setSizeParams(data, setPosition) {
			let shape = this.selectedShape;
			let widthOfShape = null;
			let heightOfShape = null;
			if (data && 'data' in data) { 
				shape = data.data[this.$store.state.canvas.$side].backgrounds_shape;
				widthOfShape = data.data[this.$store.state.canvas.$side].width;
				heightOfShape = data.data[this.$store.state.canvas.$side].height;
			}
			let side = this.$store.state.canvas.$side;			
			let paramsSize = this.$store.state.order.productSizes;

			for (let i in this.shapes) {
				if (this.shapes[i].backgrounds_shape == shape) { 
					this.selectedSizeOfShape = this.shapes[i].sizes;
					if (this.defaultShape == shape) {
						for (let j in this.selectedSizeOfShape) {
							if (+widthOfShape == +this.selectedSizeOfShape[j].width && +heightOfShape == +this.selectedSizeOfShape[j].height) {
								this.general.settedShape[side].width = this.selectedSizeOfShape[j].width;
								this.general.settedShape[side].height = this.selectedSizeOfShape[j].height;
								this.general.settedShape[side].prefixName = this.selectedSizeOfShape[j].width + 'x' + this.selectedSizeOfShape[j].height;//this.selectedSizeOfShape[j].prefixName;
								 break;
							} else {
								this.general.settedShape[side].width = this.defaultWidth;
								this.general.settedShape[side].height = this.defaultHeight;
								this.general.settedShape[side].prefixName = this.defaultWidth + 'x' + this.defaultHeight;
							}
						}
					} else {
						for (let j in this.selectedSizeOfShape) {
							if(widthOfShape && heightOfShape) {
								if (widthOfShape === this.selectedSizeOfShape[j].width && heightOfShape == this.selectedSizeOfShape[j].height) {
									this.general.settedShape[side].width = this.selectedSizeOfShape[j].width;
									this.general.settedShape[side].height = this.selectedSizeOfShape[j].height;
									this.general.settedShape[side].prefixName = this.selectedSizeOfShape[j].width + 'x' + this.selectedSizeOfShape[j].height;
								break;
								} 
							} else {
								this.general.settedShape[side].width = this.shapes[i].sizes[0].width;
								this.general.settedShape[side].height = this.shapes[i].sizes[0].height;
								this.general.settedShape[this.$store.state.canvas.$side].value = this.shapes[i].sizes[0].value;
								this.general.settedShape[side].prefixName = this.shapes[i].sizes[0].width + 'x' + this.shapes[i].sizes[0].height;
							}
						}
					}
					paramsSize[side].width = (shape == this.general.settedShape[side].backgrounds_shape) ? this.general.settedShape[side].width : this.shapes[i].sizes[0].width;
					paramsSize[side].height = (shape == this.general.settedShape[side].backgrounds_shape) ? this.general.settedShape[side].height : this.shapes[i].sizes[0].height;

					this.setProductSizes(paramsSize);
					this.emitter.emit('selectShape', { shape: this.general.settedShape[side], setNewBorder: true, setPosition: setPosition});
				}
			}

		},
		/**
		 * Set size for shape after chenge the side
		 * TODO
		 */
		setSizeAfterChanegSide(shapeSel, side) {			
			let widthOfShape = null;
			let heightOfShape = null;
			let	shape = shapeSel.backgrounds_shape;
			widthOfShape = shapeSel.width;
			heightOfShape = shapeSel.height;
	
			for (let i in this.shapes) {
				if (this.shapes[i].backgrounds_shape == shape) { 
					this.selectedSizeOfShape = this.shapes[i].sizes;
					if (this.defaultShape == shape) {
						for (let j in this.selectedSizeOfShape) {
							if (+widthOfShape == +this.selectedSizeOfShape[j].width && +heightOfShape == +this.selectedSizeOfShape[j].height) {
								this.general.settedShape[side].width = this.selectedSizeOfShape[j].width;
								this.general.settedShape[side].height = this.selectedSizeOfShape[j].height;
								this.general.settedShape[side].prefixName = this.selectedSizeOfShape[j].width + 'x' + this.selectedSizeOfShape[j].height;//this.selectedSizeOfShape[j].prefixName;
								 break;
							} else {
								this.general.settedShape[side].width = this.defaultWidth;
								this.general.settedShape[side].height = this.defaultHeight;
								this.general.settedShape[side].prefixName = this.defaultWidth + 'x' + this.defaultHeight;
							}
						}
					} else {
						for (let j in this.selectedSizeOfShape) {
							if(widthOfShape && heightOfShape) {
								if (widthOfShape === this.selectedSizeOfShape[j].width && heightOfShape == this.selectedSizeOfShape[j].height) {
									this.general.settedShape[side].width = this.selectedSizeOfShape[j].width;
									this.general.settedShape[side].height = this.selectedSizeOfShape[j].height;									
									this.general.settedShape[side].prefixName = this.selectedSizeOfShape[j].width + 'x' + this.selectedSizeOfShape[j].height;
								break;
								} 
							} else {
								this.general.settedShape[side].width = this.shapes[i].sizes[0].width;
								this.general.settedShape[side].height = this.shapes[i].sizes[0].height;
								this.general.settedShape[this.$store.state.canvas.$side].value = this.shapes[i].sizes[0].value;
								this.general.settedShape[side].prefixName = this.shapes[i].sizes[0].width + 'x' + this.shapes[i].sizes[0].height;
							}
						}
					}
				}
			}
		},
		setShapeColor(param) {
			let side = this.$store.state.canvas.$side;
			this.general.settedShape[side].fill = this.backgroudShapeColor.hex || this.general.settedShape[side].fill;
			let presets = this.$store.state.order.productPreset;
			presets[this.$store.state.canvas.$side].fill = this.general.settedShape[side].fill;
			this.setProductPreset(presets);
			this.emitter.emit('selectShape', {shape: this.general.settedShape[this.$store.state.canvas.$side], color: true, setNewBorder: false});
			if (param) {
				this.$refs.opBackShape.toggle();
			}
		},
		toggleBackGround(event) {
            this.$refs.opBackShape.toggle(event);
        },
		toggleTextColor(event) {
			this.$refs.opTextColor.toggle(event);
		},
		toggleShadowColor(event) {
			this.$refs.opShadowColor.toggle(event);
		},
		toggleStrokeColor(event) {
			this.$refs.opStrokeColor.toggle(event);
		},
		/**
		 * Selecting size for selected shape, after selected shape
		 */
		setShapeSize(event) {
			let side = this.$store.state.canvas.$side;
			let paramsSize = this.$store.state.order.productSizes;
			let currentSize = event && event.target && event.target.value ? event.target.value : paramsSize[side].width + '×' + paramsSize[side].height;
			let border = null;
			let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			canvas.getObjects().map((item) => {
				if (item.type == 'border') {		
					border = item;		
					this.emitter.emit('deleteObjects', item);
				}
			});
			for (let i in this.shapes) {
				if (this.shapes[i].backgrounds_shape === this.general.settedShape[side].backgrounds_shape) {
					for (let s in this.shapes[i].sizes) {
						if (this.shapes[i].sizes[s].prefixName == currentSize) {
							this.general.settedShape[side].width = this.shapes[i].sizes[s].width;
							this.general.settedShape[side].height = this.shapes[i].sizes[s].height;
							this.general.settedShape[side].prefixName = this.shapes[i].sizes[s].width + 'x' + this.shapes[i].sizes[s].height; //this.shapes[i].sizes[s].prefixName;

							paramsSize[side].width = this.shapes[i].sizes[s].width;
							paramsSize[side].height = this.shapes[i].sizes[s].height;
							this.emitter.emit('selectShape', {shape: this.general.settedShape[side], border: border, setNewBorder: true, setPosition: true});
							this.setProductSizes(paramsSize);
							this.general.settedShape[this.$store.state.canvas.$side].value = currentSize;
							// this.emitter.emit('setNewPositionForObject');
						}
					}
				}
			}
			
		},
		setSizeParamsForShape() {
			let shape = this.general.settedShape[this.$store.state.canvas.$side].backgrounds_shape;
			// if (this.$store.state.order.productPreset && this.$store.state.order.productPreset[this.$store.state.canvas.$side]) {
			// 	shape = this.$store.state.order.productPreset[this.$store.state.canvas.$side].shape;
			// }
			for (let i in this.shapes) {
				if (this.shapes[i].backgrounds_shape == shape) {
					this.selectedSizeOfShape = this.shapes[i].sizes;
				}
			}
		},
		openShadowBlock() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;			
			let elem = activeCanvas.getActiveObject();
			this.general.hasShadow = elem.hasShadow ? false : true;
			elem.set({
				hasShadow: this.general.hasShadow
			});
			this.emitter.emit('updateHistory', true);
			this.setShadow();
		},
		setDefaultShadow() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			this.general.shadow.color = this.general.shadow.color && this.general.shadow.color.hex ? this.general.shadow.color.hex : this.general.shadow.color;
			this.general.shadow.blur = elem.shadow ? elem.shadow.blur : null;
			this.general.shadow.offsetX = elem.shadow ? elem.shadow.offsetX : null;
			this.general.shadow.offsetY = elem.shadow ? elem.shadow.offsetY : null;
			this.general.shadow.affectStroke = elem.shadow ? elem.shadow.affectStroke : null;
			//this.general.shadow.color = elem.shadow ? elem.shadow.color : null;
			this.general.shadow.opacity = elem.shadow ? elem.shadow.opacity : null;
			this.general.hasShadow = !!elem.hasShadow;
			elem.set({
				shadow: {
					blur: elem.shadow ? elem.shadow.blur : null, //this.general.hasShadow ? this.general.shadow.blur : 0,
					offsetX: elem.shadow ? elem.shadow.offsetX : null,//this.general.hasShadow ? this.general.shadow.offsetX : 0,
					offsetY: elem.shadow ? elem.shadow.offsetY : null,// this.general.hasShadow ? this.general.shadow.offsetY : 0,
					affectStroke: true,
					color: elem.shadow ? elem.shadow.color : this.general.shadow.color, //this.general.shadow.color,
					opacity: elem.shadow ? elem.shadow.opacity : null//this.general.hasShadow ? this.general.shadow.opacity : 0
				}
			});			
			activeCanvas.renderAll();
			//this.emitter.emit('updateHistory', true);
		},
		setShadow() {			
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({
				shadow: {
					blur: this.general.shadow.blur,
					offsetX: this.general.shadow.offsetX,
					offsetY: this.general.shadow.offsetY,
					affectStroke: true,
					color: this.general.shadow.color,
					opacity: this.general.shadow.opacity
				}
			});			
			activeCanvas.renderAll();
		},
		setScaleX() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({
				scaleX: this.general.scaleX
			});
			activeCanvas.centerObjectH(elem);
			activeCanvas.centerObjectV(elem);
			activeCanvas.renderAll();
		},
		setScaleY() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({
				scaleY: this.general.scaleY
			});
			activeCanvas.centerObjectH(elem);
			activeCanvas.centerObjectV(elem);
			activeCanvas.renderAll();
		},
		setStrokeWidthBorder() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({
				strokeWidth: parseFloat(this.general.strokeWidth),
				scaleY: this.general.scaleY,
				scaleX: this.general.scaleX
			});
			activeCanvas.centerObjectH(elem);
			activeCanvas.centerObjectV(elem);
			activeCanvas.renderAll();
		},
		setShadowColor() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			this.general.shadow.color = this.shadowColor.hex;
			// this.general.stroke = this.backgroudStrokeColor.hex;
			elem.set({
				'shadow': {
					blur: this.general.shadow.blur,
					offsetX: this.general.shadow.offsetX,
					offsetY: this.general.shadow.offsetY,
					affectStroke: true,
					color: this.general.shadow.color,
					opacity: this.general.shadow.opacity
				}}
			);			
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
			this.$refs.opShadowColor.toggle();
		},
		setOpacity() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({opacity: parseInt(this.general.opacity) / 100});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setColor() {
			this.general.fill = this.textColor.hex;
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({fill: this.general.fill});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
			this.$refs.opTextColor.toggle();
		},
		setBorderStrokeColor() {
			this.general.stroke = this.borderStrokeColor.hex;
			let borderColor = this.borderStrokeColor.hex ? this.borderStrokeColor.hex : this.general.stroke;
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({stroke: borderColor});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
			this.$refs.opStrokeColor.toggle();
		},
		setStrokeColor() {
			this.general.stroke = this.strokeColor.hex;
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({stroke: this.general.stroke});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
			this.$refs.opStrokeColor.toggle();
		},
		// setStrokeType() {
		// 	let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
		// 	let elem = activeCanvas.getActiveObject();
		// 	elem.set({strokeDashArray: this.general.strokeDashArray});
		// 	activeCanvas.renderAll();
		// 	this.emitter.emit('updateHistory', true);
		// 	console.log(this.general.strokeDashArray)
		// },
		setStroke() {
			this.general.hasStroke = this.general.hasStroke ? false : true;
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			
			this.general.hasStroke = !!this.general.hasStroke;
			this.general.strokeWidth = 0;//this.general.hasStroke ? 1 : 0;
			elem.set({
				strokeDashArray: this.general.strokeDashArray,
				strokeWidth: this.general.strokeWidth,
				stroke: this.general.stroke,
				paintFirst: 'stroke'
			});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setStrokeWidth() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			if (this.general.strokeWidth > 0) {
				elem.set({
					strokeWidth: parseInt(this.general.strokeWidth),
					strokeLineJoin: 'round',//"bevel", "round", "miter"
					// strokeDashOffset: 0,
					// strokeMiterLimit: 1,
    				// strokeLineCap: 'square', //"butt", "round", "square"
					
				});
			}			
			// if (elem.type === 'border') {
			// 	this.emitter.emit('scaleBorder', elem);
			// }
			activeCanvas.renderAll();
		},
		addHistory() {
			this.emitter.emit('updateHistory', true);
		},
		setStrokeOffset() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({
				strokeOffset: parseInt(this.general.strokeOffset)
			});
			// if (elem.type === 'border') {
			// 	this.emitter.emit('scaleBorder', elem);
			// }
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		borderSelected() {
			this.currentElementType = 'border';
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			if (elem) {
				this.general.strokeWidth = elem.strokeWidth;
				this.general.scaleX = elem.scaleX;
				this.general.scaleY = elem.scaleY;
				this.general.stroke = elem.stroke;
				//this.general.strokeOffset = elem.strokeOffset || 0;
				//this.general.hasStroke = this.general.strokeWidth > 0;
				//this.general.stroke = elem.stroke;
				//activeCanvas.renderAll();
				// this.setDefaultShadow();
				this.emitter.emit('isActiveText', {isCurvedText: false, isText: false, text: ''});
			}
		},
		setPatternType() {
			//console.log(this.general.objectPattern.pattern,'*****pattern')
		},
		setPatternClipping() {
			// let selectedClip = this.general.objectPattern.clipping;
			// let clip = null;
			// let elem = this.$activeCanvas.canvas().getActiveObject();
			// switch(selectedClip) {
			// 	case 'circle':
			// 	clip = new fabric.Circle({
			// 		radius: elem.width / 2,
			// 		originX: 'center',
			// 		originY: 'center',
			// 	});
			// 	break;
			// 	case 'rectangle':
			// 	clip = new fabric.Rect({
			// 		width: elem.width,
			// 		height: elem.height,
			// 		originX: 'center',
			// 		originY: 'center',
			// 	});
			// 	break;
			// 	case 'ellipse':
			// 	clip = new fabric.Ellipse({
			// 		radius: elem.width / 10,
			// 		rx: elem.width / 3,
			// 		ry: elem.width / 2,
			// 		originX: 'center',
			// 		originY: 'center',
			// 	});
			// 	break;
			// };
			// elem.set({clipPath: clip});
			this.emmiter.emit('setClippingType', this.general.objectPattern.clipping);			
			
		},
		setPatternSize() {
			this.emmiter.emit('setPatternSizeValue', this.general.objectPattern.patternSize);	
		},
		setPatternGap() {
			this.emmiter.emit('setPatternGapValue', this.general.objectPattern.patternGap);			
		},
		setObjectPatternOptions(data) {
			this.isObjectPattern = data;			
		},
		flipCurvedText(status) {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			if (!elem) {
				return false;
			}
			this.text.isFlipped = status;
			elem.set({flipped: this.text.isFlipped});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setSize() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			let shapeParam = parseInt(this.general.width);
			let shapeNewParams = {//TODO for all shapes
				width: shapeParam,
				height: elem.type === 'ellipse' ? shapeParam * 0.7 : elem.type === 'line' ? elem.height : shapeParam,
				radius: shapeParam / 2,
				rx: elem.type === 'ellipse' ? shapeParam * 0.7 / 2 : 0,
				ry: elem.type === 'ellipse' ? shapeParam / 2 : 0
			};
			elem.set(shapeNewParams);
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setFontFamily() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let textElement = activeCanvas.getActiveObject();
			//if (this.text.fontFamilyName && this.text.fontFamilyName.name) {
				textElement.set({fontFamily: this.text.fontFamilyName.name});
				if ('styles' in activeCanvas.getActiveObject()) {
					activeCanvas.getActiveObject().styles.fontFamily = this.text.fontFamilyName.name;
				}
			// }
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setFontWeight() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let text = activeCanvas.getActiveObject();
			text.set({fontWeight: this.text.fontWeightName.value});
			this.text.bold = this.text.fontWeightName.value === 700;
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setFontSize() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({fontSize: this.text.fontSize});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setLetterHeight() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({letterHeight: this.text.letterHeight});
			activeCanvas.renderAll();
			
		},
		setLetterSpacing() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({charSpacing: this.text.charSpacing});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setLineHeight() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({lineHeight: this.text.lineHeight});
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setTextItalic() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let text = activeCanvas.getActiveObject();
			text.set({fontStyle: text.fontStyle === 'italic' ? 'normal' : 'italic'});
			this.text.italic = text.fontStyle === 'italic';
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setTextBold() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let text = activeCanvas.getActiveObject();
			text.set({fontWeight: 700});
			this.text.bold = true;
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setTextAlign(align) {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({textAlign: align});
			activeCanvas.renderAll();
			this.text.textAlign = align;
			this.emitter.emit('updateHistory', true);
		},
		setTextUnderline() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let text = activeCanvas.getActiveObject();
			text.set({textDecoration: text.textDecoration === 'underline' ? 'none' : 'underline'});
			text.set({undeline: true});
			text.underline = !text.underline;
			this.text.underline = text.underline;
			activeCanvas.renderAll();
			this.emitter.emit('updateHistory', true);
		},
		setDiameter() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			elem.set({diameter: parseInt(this.text.diameter)});
			activeCanvas.renderAll();
			// this.emitter.emit('updateHistory', true);
		},		
		convertCurvedText() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			let inputedText = elem.text;
			let fontFamily = elem.fontFamily;
			let fill = elem.fill;
			let fontWeight = elem.fontWeight;
			let fontSize = elem.fontSize;
			let lineHeight = elem.lineHeight;
			let align = elem.align;
			let fontStyle = elem.fontStyle;
			let textDecoration = elem.textDecoration;
			let opacity = elem.opacity;
			let diameter = parseInt(elem.diameter) || 360;
			let left = elem.left;
			let leftPercent = elem.leftPercent;
			let top = elem.top;
			let topPercent = elem.topPercent;
			let charSpacing = elem.charSpacing;
			let shadow = {
				affectStroke: elem.shadow.affectStroke,
				blur: elem.shadow.blur, 
				color: elem.shadow.color,
				offsetX: elem.shadow.offsetX,
				offsetY: elem.shadow.offsetY,
				opacity: elem.shadow.opacity
			};
			let stroke = elem.stroke;
			let strokeDashArray = elem.strokeDashArray;
			let strokeWidth = elem.strokeWidth;
			let paintFirst = elem.paintFirst;
			
			if (!elem) {
				return false;
			}
		
			let newElem = activeCanvas.getActiveObject();

			newElem.set({text: inputedText});
			newElem.set({fontFamily: fontFamily});
			newElem.set({fontSize: fontSize});
			newElem.set({fill: fill});
			newElem.set({fontWeight: fontWeight});
			newElem.set({lineHeight: lineHeight});
			newElem.set({align: align});
			newElem.set({fontStyle: fontStyle});
			newElem.set({textDecoration: textDecoration});
			newElem.set({opacity: opacity});
			newElem.set({diameter: diameter});
			newElem.set({left: left});
			newElem.set({leftPercent: leftPercent});
			newElem.set({top: top});
			newElem.set({topPercent: topPercent});
			newElem.set({charSpacing: charSpacing});
			newElem.set({shadow: shadow});
			newElem.set({stroke: stroke});
			newElem.set({strokeDashArray: strokeDashArray});
			newElem.set({strokeWidth: strokeWidth});
			newElem.set({paintFirst: paintFirst});
			newElem.set({selected: true});

			let textData = {
				// layer: Object.assign({},elem.layer),
				text: newElem.text,
				fill: newElem.fill,
				fontFamily: newElem.fontFamily,
				fontSize: newElem.fontSize,
				fontWeight: newElem.fontWeight,
				lineHeight: newElem.lineHeight,
				align: newElem.align,
				fontStyle: newElem.fontStyle,
				textDecoration: newElem.textDecoration,
				opacity: newElem.opacity,
				diameter: parseInt(newElem.diameter),
				charSpacing: newElem.charSpacing,
				shadow: newElem.shadow,
				stroke: newElem.stroke,
				strokeDashArray: newElem.strokeDashArray,
				strokeWidth: newElem.strokeWidth,
				paintFirst: newElem.paintFirst,
				left: newElem.left,
				leftPercent: newElem.leftPercent,
				top: newElem.top,
				topPercent: newElem.topPercent
			}
			this.emitter.emit('deleteObjects', {layer: elem});
			if (textData) {//TODO
				textData.isCreate = false;
				setTimeout(() => {					
					if (elem.type == 'i-text') {				
						this.emitter.emit('addCurvedText', textData);	
						//this.emitter.emit('updateHistory', true);	cloneLayer		addCurvedText
					} else {				
						this.emitter.emit('addIText', textData);				
					}
				}, 10);
			}			
			
			activeCanvas.renderAll();
			this.text.isCurved = !!this.text.isCurved;
		},
		clearBackground() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elems = activeCanvas.getObjects();
			for (let i in elems) {
				if (elems[i].type == 'i-text' || elems[i].type == 'text-curved') {
					elems[i].backgroundColor = '';
				}
			}
		},
		textSelected() {
			this.clearBackground();
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			if (elem) {
				elem.backgroundColor = '';//'rgba(240, 248, 255, 0.5)';
				this.currentElementType = elem.type;
				this.text.isCurved = elem.type === 'text-curved';
				this.text.type = elem.type === 'text-curved' ? 'text-curved' : 'i-text';
				this.text.isFlipped = elem.flipped || false;
				this.text.diameter = elem.diameter || 0;
				this.text.fontFamilyName = this.text.fontFamily.filter((item)=>item.value == elem.fontFamily)[0];//
				// this.text.fontFamilyName.name = elem.fontFamily;
				// this.text.fontFamilyName.value = elem.fontFamily;
				this.text.fontWeightName = this.text.fontWeight.filter((item)=>item.value == elem.fontWeight)[0];
				this.text.fontSize = elem.fontSize;
				this.text.bold = elem.fontWeight === 'bold';
				this.text.italic = elem.fontStyle === 'italic';
				this.text.underline = elem.underline;
				this.text.textAlign = elem.textAlign;
				this.text.textalign = 'left';
				this.text.lineHeight = elem.lineHeight;
				this.text.charSpacing = elem.charSpacing;
				this.general.opacity = elem.opacity * 100;
				this.general.fill = elem.fill;
				this.general.strokeWidth = elem.strokeWidth || 0;
				this.general.stroke = elem.stroke || '#000';
				this.general.hasShadow =  elem.shadow && elem.shadow.color && (elem.shadow.offsetX > 0 || elem.shadow.offsetY > 0) ? true : false;
				this.general.hasStroke = this.general.strokeWidth > 0;
				this.general.shadow.blur = elem && elem.shadow ? elem.shadow.blur : null;
				this.general.shadow.offsetX = elem.shadow ? elem.shadow.offsetX : null;
				this.general.shadow.offsetY = elem.shadow ? elem.shadow.offsetY : null;
				this.general.shadow.affectStroke = elem.shadow ? elem.shadow.affectStroke : null;
				this.general.shadow.color =  elem.shadow ? elem.shadow.color : null;
				this.general.shadow.opacity =  elem.shadow ? elem.shadow.opacity : null;

				this.emitter.emit('isActiveText', {isCurvedText: this.text.isCurved, isText: true, text: elem.text});
			}
		},
		shapeSelected() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			this.currentElementType = 'shape';
			this.general.type = elem.type;
			this.general.opacity = elem.opacity * 100;
			this.general.fill = elem.fill;
			this.general.strokeWidth = elem.strokeWidth;
			this.general.hasStroke = this.general.strokeWidth > 0;

			this.general.scaleX = elem.scaleX;
			this.general.scaleY = elem.scaleY;

			this.emitter.emit('isActiveText', {isCurvedText: false, isText: false, text: ''});
		},
		imageSelected() {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let elem = activeCanvas.getActiveObject();
			this.currentElementType = 'image';
			this.general.opacity = (elem && 'opacity' in elem) ? elem.opacity * 100 : 100;
			this.emitter.emit('isActiveText', {isCurvedText: false, isText: false, text: ''});
		},
		selectionCleared() {
			this.clearBackground();
			this.currentElementType = null;
		}
	},
	mounted() {
		// this.data.$canvasFront.emitter.emit('object:selected', this.elementSelected);
		// this.data.$canvasBack.on('object:selected', this.elementSelected);
		this.emitter.on('setActiveSide', (data) => this.setActiveSide(data));
		this.emitter.on('textSelected', () => this.textSelected());
		this.emitter.on('shapeSelected', () => this.shapeSelected());
		this.emitter.on('borderSelected', () => this.borderSelected());
		this.emitter.on('imageSelected', () => this.imageSelected());
		this.emitter.on('selectionCleared', () => this.selectionCleared());
		this.emitter.on('setDataShape', (data) => this.setDataShape(data));
		this.emitter.on('setObjectPatternOptions', (data) => this.setObjectPatternOptions(data));
		this.getShapeData();
	    this.setSizeParams();
		
	},
	unmounted() {
		this.emitter.all.clear();
	},
}
</script>

<style scopped>
/* @font-face {
  font-family: 'Libre Baskerville';
  src: url('../../../public/css/fonts/LibreBaskerville/LibreBaskerville-Regular.eot');
  src: url('../../../public/css/fonts/LibreBaskerville/LibreBaskerville-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../../public/css/fonts/LibreBaskerville/LibreBaskerville-Regular.woff2') format('woff2'),
      url('../../../public/css/fonts/LibreBaskerville/LibreBaskerville-Regular.woff') format('woff'),
      url('../../../public/css/fonts/LibreBaskerville/LibreBaskerville-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */
#control_panel>.p-component {
	font-size: 11px !important;
    font-weight: bold !important;
}
.p-component:hover{
	text-decoration: none !important;
}
.p-dropdown {
    width: 14rem;
}
#control_panel{
	width: 280px;
	height: auto;
	overflow: hidden;
	/* right: 55px !important; */
}
.p-checkbox{
	margin-left: 29px;
}
.open-block{
	font-size: 12px;
	margin-left: 2px;
}
.p-colorpicker{
	width: 34px;
}
.p-fluid .p-inputtext {
    border-radius: 50%;
}
.swatch-group{
	padding: 10px 0px;
	margin-bottom: -15px;
	border: none;
	display: flex;
}
.swatch-group>span, .swatch-group>.color-set>span{
	color: #6d6d6d;
	font-size: 13px;
	font-family: Roboto, sans-serif;
	margin: 7px 0 0 2px;
}
input.p-colorpicker-preview .p-inputtext{
	border-radius: 50% !important;
}
.customSize{
	margin: 5px 0 0 7px;
}
.sel-custom-size{
	margin-bottom: 8px;
}
.size-field{
	width: 60px;
	box-sizing: border-box;
	display: inline;
}
.p-accordion .p-accordion-content{
	padding: 10px !important;
}
.p-accordion-content>.p-dropdown{
	width: 182px;
}
.font-weight-block>.p-dropdown{
	width: 182px;
}
.font-size-block>.p-inputnumber>.p-inputtext{
	width: 40px !important;
}
#fontsize{
	border: 1px solid #ced4da !important;
	border-radius: 0 !important;
	height: 28px !important;
}
.font-style-block{
	width: 105px;
}
.p-inputnumber{
	margin-left: 8px;
}
.title-list-group{
	width: 115px;
}
button.text-decoration, button.text-align{
	width: 28px !important;
	height: 28px !important;
	margin: 0 3px;
}
.params-text>input{
	width: 30px !important;
	border: 1px solid #ced4da !important;
	border-radius: 0 !important;
	height: 28px !important;

}
.title-list-group>span{
	font-size: 14px;
}



.control-header{
	cursor: pointer;
	font-size: 13px;
	font-weight: bold;
	color: #000;
	padding: 10px;
	height: 25px;
	position: relative;
}
.control-header span{
	margin-left: 3px;
	font-size: 13px;
	font-weight: bold;
	color: #000;
}
.control-content{
	padding: 0 5px 8px 5px;
}
input[type="color"].form-control{
	border-radius: 25px;
	width: 25px;
	height: 25px;
	display: inline;
}

.color-set{
	width: 100px;
}
.size-list-group{
	border: 1px solid #d2d2d2;
	background: #e8e8e8;
	padding: 5px;
	margin-top: 10px;
	border-radius: 5px;
	font-size: 13px;
	margin-bottom: -10px;
}
.title-list-group{
	padding: 5px;
	margin-top: 2px;
	border-radius: 5px;
	font-size: 13px;
	margin-bottom: -5px;
	padding-left: 3px;
}
.title-list-group.title{
	width: 100%;
}
.border-block{
	border-top: 1px solid #cccccc;
}
#toolbox{
	padding-bottom: 15px;
}
.slider-group{
	display: inline-block;
	margin-bottom: 5px;
	font-size: 12px;
	color: #6d6d6d;
	
}
.slider-params{
	display: flex;
	justify-content: space-between;
}
.slider-value {
  display: inline-block;
  display: flex;
  width: 10px;
  height: 12px;
  font-size: 12px;
}
.ui-slider {
  width: 150px;
  display: flex;
  display: inline-block;
  height: 4px;
  margin-right: 5px;
}
.v-select{
	font-size: 13px;
}
.form-control[type="number"]{
	height: 34px;
	width: 65px;
}
.textbox-prop>.form-control[type="number"]{
	width: 40px;
	display: inline;
	padding: 0 2px;
	height: 30px;
	font-size: 13px;
}
.font-prop{
	/* background: #e8e8e8; */
	padding: 4px 3px 4px 2px;
	border-radius: 5px;
	display: flex;
	/* border: 1px solid #d2d2d2;	 */
	/* justify-content: center; */
}
.textbox-prop{
	margin: 0 2px;
	align-items: center;
	justify-content: space-between;
	-webkit-box-align: center;
	display: flex;
	width: 90px;
	-webkit-box-pack: justify;
}
.textbox-prop>span{
	margin-left: 2px;
}
.font-weight-block{
	width: 117px;
}
.font-size-block{
	width: 63px;
}
.config-block{
	display: flex;
}
.stroke-block{
	display: flex;
	padding: 10px 0px;

}
.stroke-block>.custom-checkbox{
	margin-left: 15px;
}

.btn-dark{
	fill: #ffffff;
}
.flip-text{
	justify-content: center;
	display: flex;
	width: 180px;
}
.flip-text>.btn-dark{
	background: #9c9c9c;
	color: #fff;
	fill: #fff;
	border: none;
}
.stroke-type{
	font-size: 13px;
	color: #6d6d6d;
}
.control-header{
	background-image: url('../../../public/images/icons/svg/chevron-up.svg');
	background-repeat: no-repeat;
	background-position: top 15px right 0px;
	background-size: 10px;
}
.collapsed{
	background-image: url('../../../public/images/icons/svg/chevron-down.svg');
}
.custom-checkbox,.stroke-block{
	background-image: none;
}
#label-type{
	display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
#label-type label{
    cursor: pointer;
}
#label-type div{
    display: inline-block;
    padding: 5px 8px 5px 5px;
    line-height: 1em;
    border-radius: 3px;
}
#label-type label img{  
    display: block;
    margin: 0 auto 5px auto;
    width: 16px;
    height: auto;
}
#label-type>label>div>span{  
   font-size: 13px;
}
.opacity-block{
	border: 1px solid #dee2e6;
    background: #ffffff;
}
.opacity-block>.slider-group{
	font-size: 16px;
	margin-top: 5px;
	font-weight: bold;
}
.opacity-block>.slider-group>.slider-params{
	padding-left: 15px;
}
.set-center{
	display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.background-button{
	border: 1px solid black !important;
	border-radius: 50% !important;
	height: 34px;
    width: 15px !important;
}
i.p-dropdown-clear-icon{
	display: none;
}
.button-height, .button-width{
	height: 28px !important;
}
.button-color-width{
	padding: 0 !important;
	width: 28px !important;
}
.p-inputtext{
	padding: 0 !important;
}
.p-button{
	/* padding: 0 2px !important; */
}
.p-dropdown-filter-icon{
	top: 85% !important;
}
.flip-class-size{
	width: 52px !important;
	height: 32px !important;
}
.block-params{
	margin-top: -20px !important;
}
.p-button.p-button-icon-only {
    width: 1.357rem !important;
}
.p-inputnumber {
    width: 60px !important;
}
</style>
