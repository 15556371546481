<template>
  <div v-if="loading" class="preloader">
    <ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
  </div>
	<div class="main" id="editor">
		<div class="header-block">
			<h1>FAQ</h1>
		</div>
    <div class="content-accordion p-0">
      <Accordion v-if="questions">
          <AccordionTab v-for="question in questions" :header="question.post_title">
              <p v-html="question.post_content"></p>
          </AccordionTab>
      </Accordion>
    </div>
	</div>
</template>  
<script>
import axios from 'axios';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
export default {
  name: 'FaqPage',
  components:{
    Accordion,
    AccordionTab
  },
  data() {
    return {
      titlePage: '',
      contentPage: '',
      questions: null,
      loading: false
    }
  },
  methods: {
      getDataPage() {
        this.loading = true;
        let form = new FormData;
        form.append('action', 'dm_get_page_by_slug_faq_blog');
        form.append('slug', 'faq');
        axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
        }).then(response => {
          this.loading = false;
          if (response.data && response.data.status == 'OK') {
              this.questions = response.data.posts;
          } else {
              this.$router.push('/404');
          }
        }).catch(error => {
            console.log(error);
        });
      }
  },
  created () {
    this.getDataPage();
  },
  beforeRouteUpdate (to, from, next) {
    this.getDataPage();
    next();
  }
}
</script>  
<style scoped>
  h1{
  margin-top: 20px;
  font-size: 26px;
  font-family: Oswald;
}
#editor{
display:block;
margin-top: 21px;
}
.conten-page-top{
  text-align: center;
}
.content-accordion{
  background-color: white;
  padding: 0 20px 20px 20px;
}
.heading-params{
  color: red;
}
</style>
  