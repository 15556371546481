<template>
    <div>
		<Dialog header="Change Avatar" v-model:visible="this.displayUploadImage" :breakpoints="{'960px': '60vw', '640px': '60vw'}" :style="{width: '50vw'}">
			<div class="card2">
				<Toast />
				<FileUpload name="images[]" :url="$wpAjaxUrl" @upload="onTemplatedUpload($event)" :multiple="false" accept="image/*" :maxFileSize="1000000" @select="onSelectedFiles">
					<template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
						<div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
							<div class="flex gap-2">
								<Button @click="chooseCallback()" ref="selectFiles" icon="pi pi-images" label="Select Avatar"></Button>
								<Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded outlined severity="success" :disabled="!files || files.length === 0" label="Upload Avatar"></Button>
								<Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0" label="Delete Avatar"></Button>
							</div>
							<ProgressBar :value="totalSizePercent" :showValue="false" :class="['md:w-10rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 10000 }]">
								<span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
							</ProgressBar>
						</div>
					</template>
					<template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
						<div v-if="files.length > 0">
							<h5>Pending</h5>
							<div class="flex flex-wrap p-0 sm:p-5 gap-5">
								<div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
									<div>
										<img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
									</div>
									<span class="font-semibold">{{ file.name }}</span>
									<div>{{ formatSize(file.size) }}</div>
									<Badge value="Pending" severity="warning" />
									<Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded  severity="danger" />
								</div>
							</div>
						</div>
						<div v-if="uploadedFiles.length > 0">
							<h5>Completed</h5>
							<div class="flex flex-wrap p-0 sm:p-5 gap-5">
								<div v-for="(file, index) of uploadedFiles" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
									<div>
										<img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
									</div>
									<span class="font-semibold">{{ file.name }}</span>
									<div>{{ formatSize(file.size) }}</div>
									<Badge value="Completed" class="mt-3" severity="success" />
									<Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded  severity="danger" />
								</div>
							</div>
						</div>
					</template>
					<template #empty>
						<div class="flex align-items-center justify-content-center flex-column" @click="callUploadFiles">
							<img alt="dropdown icon" :src="`${this.pathUrl}/images/icons/icon-add.svg`">
							<p class="mb-0 mt-2 text-under-button"><span>Select avatar to Uploads</span><br>
							or Drag and drop avatar to here to upload.</p>
						</div>
					</template>
				</FileUpload>
			</div>
        </Dialog>
	</div>
</template>
<script>
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Badge from 'primevue/badge';
import { isProxy, toRaw } from 'vue';
import axios from 'axios';
export default {
	name: "UploadImage",
	props: ['showPopUpAvatar'],
	components: {
		FileUpload: FileUpload,
		ProgressBar: ProgressBar,
		Badge: Badge
	},
	data() {
		return {
			displayUploadImage: this.showPopUpAvatar,
			action: 'dm_upload_image',
			files: [],
			totalSize: 0,
            totalSizePercent: 0,
			uploadedFiles: []
		}
	},
	methods: {
		openUploadImage() {
            this.displayUploadImage = true;
        },
		selectFiles(event){
			this.files.push(event.files);
		},
		onRemoveTemplatingFile(file, removeFileCallback, index) {
            removeFileCallback(index);
            this.totalSize -= parseInt(this.formatSize(file.size));
            this.totalSizePercent = this.totalSize / 10;
        },   
        onSelectedFiles(event) {
            this.files = toRaw(event.files);
            this.files.forEach((file) => {
                this.totalSize += parseInt(this.formatSize(file.size));
            });
        },
		callUploadFiles() {
			this.$refs.selectFiles.$el.click();
		},
		uploadEvent() {
            this.totalSizePercent = this.totalSize / 0.5;
			let countFiles = this.files;console.log(this.files)
			if (countFiles.length) {
				for (let i=0; i<countFiles.length; i++) {
					let data = new FormData();
					data.append('avatar', countFiles[i]);
					data.append('user_email', this.$store.state.profileModule.userEmail)
					data.append('action', 'dm_change_avatar');
					let headers = {'Content-Type': 'multipart/form-data'};
					axios.post(this.$wpAjaxUrl, data, {headers})
						.then(response => {
							this.displayUploadImage = false;
							if (response && response.data) {
								if (response.data.status == 'OK') {
									this.$toast.add({ severity: 'info', summary: 'Success', detail: response.data.message, life: 3000 });
									this.emitter.emit('getAvatar');
								}
								if (response.data.status == 'Error') {
									this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
								}
							}
					});
				}
			}
        },
        onTemplatedUpload() {
            this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
        },
        formatSize(bytes) {
            if (bytes === 0) {
                return '0 B';
            }
            let k = 1000,
                dm = 3,
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    }	
}
</script>

<style>
.text-under-button{
	width: 300px;
	text-align: center;
}
.text-under-button>span{
	font-size: 20px;
}
</style>