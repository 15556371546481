<template>
  <div v-if="loading" class="preloader">
		<ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
	</div>
	<div class="main" id="editor" v-if="titlePage" style="width: 985px">
		<div class="header-block">
		</div>
       <div class="grid ml-0 mr-0 mt-3">
          <div class="col-12" v-if="slides.length">
            <Carousel :value="slides" :numVisible="1" :numScroll="1" circular :autoplayInterval="+autoplayInterval"  :showIndicators="false">
              <template #item="slotProps">
                  <div class="surface-border border-round text-center slider-image" @click="$router.push(slotProps.data.slide_url.toString())">
                      <div class="mb-3">
                          <img :src="`${this.pathPluginImage}/sliders/${slotProps.data.slide_link}`" :alt="slotProps.data.slide_url" class="w-12 shadow-2" style="height: 200px"/>
                      </div>
                  </div>
              </template>
            </Carousel>
          </div>
      </div>
      <div class="card mt-2">
        <div class="flex">
            <div class="flex-1 flex align-items-center justify-content-center surface-300 font-bold text-gray-900 m-2 px-5 py-3 border-round">
              <div class="grid">
                <div class="col-12 text-center text-2xl font-light">Sign up for our newsletter to receive discounts and promotion</div>
                <div class="col-12 text-center">
                  <InputText placeholder="Enter Email Address" v-model="subscribeEmail" @blur="checkEmail" @focus="clearErrors"/>
                  <Button  @click="subscribe" icon="pi pi-send"/>
                  <span v-if="wrongEmail" class="errors-message">Please, input correct e-mail!</span>
                </div>
              </div>
            </div>
            <div v-for="fimage in frontImages" 
              class="flex-1 flex align-items-center justify-content-center border-round">
              <div class="flex-1 h-13rem flex align-items-center" style="cursor: pointer; overflow: hidden; width: 290px; height: 208px;">
                <img @click="$router.push(fimage.page_url ? fimage.page_url : '/')" 
                style="position: relative; width: 290px" :src="`${this.pathPluginImage}/homeimages/${fimage.image_name}`"
                :alt="`${fimage.image_title}`">
              </div>
            </div>

            <!-- <div class="flex-1 flex align-items-center justify-content-center m-2 bg-white border-round">
              <div class="flex-1 flex flex-column align-items-center">
                <div class="flex f-full">
                  <span
                    class="text-center text-lg line-height-4 text-900"
                    :style="{fontFamily: 'Libre Baskerville'}">
                      {{ text2 }}
                  </span>
                </div>
                <div class="flex">
                  <Button label="Click Here" class="cursor-pointer border-900 border-2" severity="warning" @click="$router.push(link2)"/>
                </div>
              </div>
              <div
                class="flex-1 h-13rem flex align-items-center"
                :style="{ backgroundImage: 'url(' + image2 + ')', backgroundSize: '100%' }">
              </div>
            </div> -->
        </div>
      </div>
		<div class="home-content" v-html="contentPage"></div> 
	</div>
  <Toast />
</template>
<script>
import Carousel from 'primevue/carousel';
  import axios from 'axios';
  export default {
    name: 'HomePage',
    components: {
      Carousel
    },
    data() {
      return {
        titlePage: '',
        contentPage: '',
        subscribeEmail: '',
        wrongEmail: false,
        userEmail: '',
        frontImages: [],
        // text1: null,
        // text2: null,
        // link1: null,
        // link2: null,
        // image1: null,
        // image2: null,
        loading: false,
        productCategories: [],
        slider: null,
        slides: [],
        autoplayInterval: 100000,
        subscribeNonce: null
      }
    },
    methods: {
      subscribe() {
        let emailRegExp = /^[a-zA-z0-9]+[a-zA-z0-9-_\.]+@[a-zA-z0-9]{1,10}\.[a-zA-z]{2,5}(\.[a-zA-Z]{2,3})?$/;
        if (this.userEmail && this.userEmail.length > 0) {
          if (emailRegExp.test(this.userEmail)) {
            let form = new FormData;
            form.append('email', this.userEmail);
            form.append('action', 'store_email');
            form.append('name', '');
            form.append('is_agreed', true);
            form.append('sec_string', this.subscribeNonce);
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
                if (response && response.status == 200) {
                  let mess = response.data;
                  let messageData = mess.split('|');
                  let status = 'warn';
                  if (messageData[0] == 'success' || messageData[0] == 'error') {
                    status = messageData[0];
                  }
                  this.$toast.add({severity: status, summary: 'Message', detail: messageData[1], life: 3000});
                  this.subscribeEmail = '';
                } else {
                  this.$toast.add({severity: 'warn', summary: 'Warn Message', detail: response.data.message, life: 3000});
                }
            }).catch(error => {
                console.log(error);
            });
          } else {
            this.wrongEmail = true;
          }
        }
      },
      getWPnonce() {
        let form = new FormData;
        form.append('action', 'dm_get_subscriber_nonce');
        form.append('slug', 'home');      
        axios({
          method: 'post',
          url: this.$wpAjaxUrl,
          data: form
        }).then(response => {
          if (response.data.status == 'OK') {
            this.subscribeNonce = response.data.nonce;
          }            
        }).catch(error => {
          console.log(error);
        });
      },
      clearErrors() {
        this.wrongEmail = false;
      },
      checkEmail() {
        this.userEmail = this.subscribeEmail;        
      },
      getDataPage() {
        this.loading = true;
        let form = new FormData;
        form.append('action', 'dm_get_page_by_slug');
        form.append('slug', 'home');
        axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
        }).then(response => {
          this.loading = false;
          if (response.data && response.data.status == 'OK') {
              this.titlePage = response.data.pageTitle;
              this.contentPage = response.data.pageContent;
              // this.text1 = response.data.text1[0];
              // this.text2 = response.data.text2[0];
              // this.link1 = response.data.link1[0];
              // this.link2 = response.data.link2[0];
              // this.image1 = response.data.image1[0];
              // this.image2 = response.data.image2[0];
          } else {
              this.$router.push('/404');
          }
        }).catch(error => {
            console.log(error);
        });
      },
      getCategoriesList() {
        let form = new FormData;
        form.append('action', 'dm_get_product_categories');
        axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
        }).then(response => {
          if (response.data && response.data.result) {
            let categories = response.data.result;
            for (var i in categories) {
              this.productCategories.push({
                label: categories[i].category_name,
                to: '/category/' + categories[i].category_slug,
                class: 'list-menu'
              });
            }
          }
        }).catch(error => {
            console.log(error);
        });
      },
      getCarusel() {
        let form = new FormData;
        form.append('action', 'dm_get_carusel');
        axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
        }).then(response => {
          if (response.data && response.data.slider) {
            this.slider = response.data.slider[0];
            this.slides = response.data.slides;  
            this.autoplayInterval = this.slides.length == 1 ? this.slider.autoplay_interval * 100000 : this.slider.autoplay_interval;        
          }
        }).catch(error => {
            console.log(error);
        });
      },
      getHomeImages() {
        let form = new FormData;
        form.append('action', 'dm_get_home_images');
        axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
        }).then(response => {
          if (response.data && response.data.home_images) {
            this.frontImages = response.data.home_images;
          }          
        }).catch(error => {
            console.log(error);
        });
      }
    },
    mounted() {
      this.getWPnonce();
      this.getCategoriesList();
      this.getCarusel();
      this.getHomeImages();
    },
    created () {
      this.getDataPage();
    },
    beforeRouteUpdate (to, from, next) {
      this.getDataPage();
      next();
    }
  }
</script>
<style scoped>
h1{
  margin-top: 20px;
  font-family: Oswald;
  font-size: 26px;
}
  #editor{
	display:block;
	margin-top: 21px;
  }
  .errors-message{
    color: red;
  }
  .home-content{
    background: #edf1f4;
    padding: 30px;
    width: 100%;
    text-align: center;
  }
  .subscribe-block{
    text-align: center;
    padding-bottom: 40px;
    padding-top: 30px;
    background: #edf1f4;
    border-bottom: 1px solid white;
  }
  .link-join{
    color: #ff9c00;
    text-transform: uppercase;
    cursor: pointer;
  }
  .link-join:hover{
    text-decoration: underline;
  }
  .subscribe-form{
    width: 350px;
    margin: 0 auto;
  }
  .slider-image{
    cursor: pointer;
  }
  /* .w-6 {
    width: 98px !important;
    height: 358px !important;
} */
.left-menu>li{
  list-style-type: none;
}
.left-menu{
  padding:0;
}
.list-menu>a{
  display: block;
  height: 35px;
  padding: 5px 5px;
  text-decoration: none;
  color:#2c3e50;
}
.list-menu:hover{
  background-color: #e9ecef;
}
.list-menu{
  border-bottom: 1px solid gray;
}
.p-inputtext{
  padding: 8px !important;
}
  </style>
  