<template>
  <div v-if="loading" class="preloader">
		<ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
	</div>
  <div class="main" id="editor">
    <div class="header-block">
      <h1>{{ productName }}</h1>
    </div>
    <!-- <div class="steps">
      <ul class="chouse-steps">      
        <li class="list-step">Step: <div class="step-num">1</div> Select a template</li>
        <li class="list-step step-link active">Step: <div class="step-num">2</div> Select color & size</li>
        <li class="list-step">Step: <div class="step-num">3</div> Customize your label</li>
      </ul>
    </div> -->
<div class="card">
			<DataView :value="products" :layout="layout" :paginator="false" :rows="9">
				<template #grid="slotProps">
					<div class="col-12 col-md-4" v-if="JSON.parse(slotProps.data.label).front.objects.length">
						<div class="product-grid-item">
							<img class="img-prod"
								:src="`${this.pathPluginImage}/products/${slotProps.data.image_front}`"
								:alt="slotProps.data.name"
								@click="$router.push('/editor/' + slotProps.data.product_slug)"
								/>
                <div class="product-grid-item-content">
                  <div class="product-name">{{slotProps.data.name}}</div>
                  <div class="product-name"><b>Size:</b> 
                    {{JSON.parse(slotProps.data.backgrounds_shape).front.width}}"&times;
                    {{JSON.parse(slotProps.data.backgrounds_shape).front.height}}"
                  </div>
							</div>
						</div>
					</div>
					<!-- <div class="col-12 col-md-4" v-if="JSON.parse(slotProps.data.label).back.length">
						<div class="product-grid-item">
							<img class="img-prod"
								:src="`${this.pathPluginImage}/products/${slotProps.data.image_back}`"
								:alt="slotProps.data.name"
								@click="$router.push('/editor/' + slotProps.data.product_slug)"
								/>
                <div class="product-grid-item-content">
                  <div class="product-name">{{slotProps.data.name}} (back label)</div>
                  <div class="product-name"><b>Size:</b> 
                    {{JSON.parse(slotProps.data.backgrounds_shape).back.width}}"&times;
                    {{JSON.parse(slotProps.data.backgrounds_shape).back.height}}"
                  </div>
							</div>
						</div>
					</div> -->
				</template>
			</DataView>
		</div>
  </div>
</template>
  
<script>
import axios from 'axios';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
  export default {
    name: 'ProductVariants',
    data() {
      return {
        productName: '',
        products: [],
        layout: 'grid',
        loading: false
      }
    },
    methods: {
      getProducts(slug) {
        this.loading = true;
        let form = new FormData;
        form.append('product_slug', slug);
        form.append('action', 'dm_get_variants_products');
        axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
        }).then(response => {
          this.loading = false;
          this.productName = response.data.product_name;
          this.products = response.data.result;
        }).catch(error => {
          console.log(error);
        });
      },
        ...mapMutations({
        setSide: 'canvas/setSide'
      }),
      ...mapActions({
        setSide: 'canvas/setSide'
      }),
   
  },
  created () {
		this.getProducts(this.$route.params.id);
	},
	beforeRouteUpdate (to, from, next) {
		this.getProducts(to.params.id);
		next();
	},
  computed: { 
    ...mapState({
			$side: state => state.canvas.$side
		}),
		...mapGetters({
			
		}),
    }
  }
  </script>
  
  <style scoped>
    h1{
	margin-left: 10px;
	font-size: 27px;
  font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  
  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
	display: flex;
}
.p-dropdown {
    width: 14rem;
    font-weight: normal;
}
.product-name {
	font-size: 12px;
	font-weight: 700;
	cursor:pointer;
}
.product-description {
	margin: 0 0 1rem 0;
	font-size: 12px;
}
.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}
.product-category {
	font-weight: 600;
	vertical-align: middle;
}
.product-grid-item{
	margin: .5rem;
	border: 1px solid var(--surface-border);
}
.img-prod {
	margin: 0 15%;
	width: 200px;
	cursor: pointer;
}
.product-price {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: .5rem;
	align-self: flex-end;
}
.product-list-action {
	display: flex;
	flex-direction: column;
}
.p-button {
	margin-bottom: .5rem;
}
.product-grid-item-top,
.product-grid-item-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.product-grid-item-content {
	text-align: center;
}
.product-price {
	font-size: 14px;
	font-weight: 600;
}
  </style>
  