<template>
	<div>
		<Dialog header="Shapes" v-model:visible="this.displayShapes" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
			<div @click="addRect" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/rectangle.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addCircle" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/circle.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addEllipse" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/circle.png`" style="width: 42px; height: 82px;" />
			</div>
			<div @click="addTriangle" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/triangle.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addLine" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/line.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('star')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/star.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('star2')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/star2.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('octagon')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/octagon.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('heart')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/heart.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('heart2')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/heart2.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('starburst')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/starburst.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('scallop')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/scallop.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('thought-bubble')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/thought-bubble.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('speech-bubble')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/speech-bubble.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('arrow')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/arrow.png`" style="width: 82px; height: 82px;" />
			</div>
			<div @click="addShapeFromSVG('arrow2')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/arrow2.png`" style="width: 82px; height: 82px;" />
			</div>

			<div @click="addShapeFromPattern('polkadots')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/polkadots.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>	
			<div @click="addShapeFromPattern('checkerboard')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/checkerboard.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>
			<div @click="addShapeFromPattern('squares')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/squares.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>
			<div @click="addShapeFromPattern('diamonds')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/diamonds.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>
			<div @click="addShapeFromPattern('hearts')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/hearts.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>
			<div @click="addShapeFromPattern('triangles2')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/triangles2.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>
			<div @click="addShapeFromPattern('triangles')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/triangles.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>
			<div @click="addShapeFromPattern('stripes')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/stripes.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>
			<div @click="addShapeFromPattern('paws')" class="shapeImg">
				<img :src="`${this.pathUrl}/images/shapes/paws.png`" style="width: 82px; height: 82px;" />
				<div class="info-message">Config</div>
			</div>
		</Dialog>
	</div>
</template>

<script>
export default {
	name: "ShapesList",
	data() {
		return {
			displayShapes: false
		}
	},
	methods: {
		openShapes() {
            this.displayShapes = true;
        },
		closeShapes() {
            this.displayShapes = false;
        },
		addRect() {
			this.emitter.emit('addRect', {});
			this.displayShapes = false;
		},
		addCircle() {
			this.emitter.emit('addCircle', {});
			this.displayShapes = false;
		},
		addEllipse() {
			this.emitter.emit('addEllipse', {});
			this.displayShapes = false;
		},
		addTriangle() {
			this.emitter.emit('addTriangle', {});
			this.displayShapes = false;
		},
		addLine() {
			this.emitter.emit('addLine', {});
			this.displayShapes = false;
		},
		addImage() {
			this.emitter.emit('addImage', {});
			this.displayShapes = false;
		},
		addShapeFromSVG(name) {
			this.emitter.emit('addShapeFromSVG', {name: name});
			this.displayShapes = false;
		},
		addShapeFromPattern(name) {
			this.emitter.emit('addShapeFromPattern', {name: name});
			this.displayShapes = false;
		}
	}
}
</script>
<style scoped>
	.shapeImg{
		display: inline; 
		margin-right:10px;
		width: 90px;
		height: 90px;
		cursor: pointer;
		float: left;
	}
	.info-message{
		width: 35px;
		height: 15px;
		background-color: red;
		color: white;
		position: relative;
		top: -15px;
		font-size: 10px;
		font-weight: bold;
	}
</style>