<template>
	<div v-if="loading" class="preloader">
		<ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
	</div>
     <div class="main" id="editor" v-if="categoryName">
		<div class="header-block">
			<h1>{{ categoryName }}</h1>
		</div>
		<div class="content-page" v-if="loading">
			<!-- <Skeleton width="100%" class="mb-2"></Skeleton> -->
			<Skeleton width="100%" height="300px"></Skeleton>
			<!-- <Skeleton width="100%" class ="mt-2" height="150px"></Skeleton> -->
		</div>
		<p class="category_description">{{ categoryDescription }}</p>
		<div class="card">
			<DataView :value="products" :layout="layout" :paginator="false" :rows="9">
				<template #grid="slotProps">
					<div class="col-12 col-md-4" v-if="JSON.parse(slotProps.data.label).front.objects.length && (slotProps.data.is_master == 1 || slotProps.data.is_master == 0 && slotProps.data.count_product >= 1)">
						<div class="product-grid-item">
							<img class="img-prod"
								:src="`${this.pathPluginImage}/products/${slotProps.data.image_front}`"
								:alt="slotProps.data.name"
								@click="$router.push(slotProps.data.count_product >= 1 ? '/product-variants/' + this.$route.params.id + '/' + slotProps.data.product_slug : '/editor/' + slotProps.data.product_slug)"
								/>
							<div class="product-grid-item-content">
								<div class="product-name">{{slotProps.data.name}}</div>
								<div class="product-name"><b>Size:</b> 
									{{JSON.parse(slotProps.data.backgrounds_shape).front.width}}"&times;
									{{JSON.parse(slotProps.data.backgrounds_shape).front.height}}"
								</div>
								<!-- <div class="other-variant-button">
									<Button
										v-if="slotProps.data.count_product > 0"
										class="button-variant"
										@click="$router.push('/product-variants/' + this.$route.params.id + '/' + slotProps.data.product_slug)"
										label="Other sizes & colors"/>	
								</div>								 -->
							</div>
						</div>
					</div>
					<!-- <div class="col-12 col-md-4" v-if="JSON.parse(slotProps.data.label).back.length && (slotProps.data.is_master == 1 || slotProps.data.is_master == 0 && slotProps.data.count_product == 1)">
						<div class="product-grid-item">
							<img class="img-prod"
								:src="`${this.pathPluginImage}/products/${slotProps.data.image_back}`"
								:alt="slotProps.data.name"
								@click="$router.push(slotProps.data.count_product > 0 ? '/product-variants/' + this.$route.params.id + '/' + slotProps.data.product_slug : '/editor/' + slotProps.data.product_slug)"
								/>
							<div class="product-grid-item-content">
								<div class="product-name">{{slotProps.data.name}} (back label)</div>
								<div class="product-name"><b>Size:</b> 
									{{JSON.parse(slotProps.data.backgrounds_shape).back.width}}"&times;
									{{JSON.parse(slotProps.data.backgrounds_shape).back.height}}"
								</div>
								<div class="other-variant-button">
									<Button
										v-if="slotProps.data.count_product > 0"
										class="button-variant"
										@click="$router.push('/product-variants/' + this.$route.params.id + '/' + slotProps.data.product_slug)"
										label="Other sizes & colors"/>	
								</div>								
							</div>
						</div>
					</div> -->
				</template>
			</DataView>			
			<div v-if="!products.length">
				<h3 class="text-center">Category is empty!</h3>
			</div>
		</div>
    </div>
  </template>
<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import axios from 'axios';
  export default {
    name: 'CategoryPage',
    data() {
      return {
		categoryName: '',
		categoryDescription: '',
		categoryID: 0,
		products: [],
		layout: 'grid',
		loading: false
      }
    },
    methods: {
		getCategory(uid) {
			this.loading = true;
			let form = new FormData;
			form.append('slug', uid);
            form.append('action', 'dm_get_category');
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
				if (response.data && response.data.result && response.data.result[0]) {
					this.categoryName = response.data.result[0].category_name;
					this.categoryID = response.data.result[0].id;
					this.categoryDescription =  response.data.result[0].category_description;
				}
				this.getProducts();
            }).catch(error => {
                console.log(error);
            });
		},
		getProducts() {
			let form = new FormData;
			form.append('categoryID', this.categoryID);
            form.append('action', 'dm_get_category_products');
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
				this.loading = false;
				this.products = response.data.products;
            }).catch(error => {
                console.log(error);
            });
		},
      ...mapMutations({
			setSide: 'canvas/setSide'
		}),
		...mapActions({
			setSide: 'canvas/setSide'
		}),
	},
	created () {
		this.getCategory(this.$route.params.id);
	},
	beforeRouteUpdate (to, from, next) {
		this.getCategory(to.params.id);
		next();
	},
	computed: {
		...mapState({
			$side: state => state.canvas.$side
		})
    }
  }
  </script>
  
  <style scoped>
  h1{
	margin-left: 10px;
	font-size: 27px; 
	font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  .category_description{
	text-align: justify;
	/* padding: 20px; */
	width: 985px;
    margin: 0 auto !important;
  }

  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
	display: flex;
}
.p-dropdown {
    width: 14rem;
    font-weight: normal;
}
.product-name {
	font-size: 12px;
	font-weight: 700;
	cursor:pointer;
}
.product-description {
	margin: 0 0 1rem 0;
	font-size: 12px;
}
.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}
.product-category {
	font-weight: 600;
	vertical-align: middle;
}
.product-grid-item{
	margin: .5rem;
	border: 1px solid var(--surface-border);
}
.img-prod {
	margin: 0 15%;
	width: 200px;
	cursor: pointer;
}
.product-price {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: .5rem;
	align-self: flex-end;
}
.product-list-action {
	display: flex;
	flex-direction: column;
}
.p-button {
	margin-bottom: .5rem;
}
.product-grid-item-top,
.product-grid-item-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.product-grid-item-content {
	text-align: center;
}
.product-price {
	font-size: 14px;
	font-weight: 600;
}
.other-variant-button{
	width: 100%;
	height: 40px;
}
.button-variant{
	height: 30px;
}
  </style>
  