<template>
	<div>
        <Dialog header="Customer's Labels" v-model:visible="this.displayCustomersLabel" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
			<template v-if="labels.length > 0">
				<table class="list-users-labels">
					<tr>
						<th>#</th>
						<th>Name</th>
						<th></th>
					</tr>
					<tr v-for="(label, index) in labels" class="list-label" v-bind:key="index">
						<td>{{ index+1 }}.</td>
						<td :key="index">
							<router-link :to="`/editor/${label.label_slug}/personal`">
								{{label.name}}
							</router-link>
						</td> 
						<td @click="confirmDelete(label)">
							<font-awesome-icon :icon="icons.fatrash" />
						</td>
					</tr>
				</table>
			</template>
			<template v-else>
				<h6 class="empty-list">The list is empty!</h6>
			</template>
		</Dialog>
		<Toast />
        <ConfirmDialog></ConfirmDialog>
        <ConfirmDialog group="templating">
                <template #message="slotProps">
                    <div class="flex p-4">
                        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                        <p class="pl-2">{{slotProps.message.message}}</p>
                    </div>
                </template>
        </ConfirmDialog>
        <ConfirmDialog group="positionDialog"></ConfirmDialog>
	</div>	
</template>

<script>
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
export default {
	name: "LabelsComponent",
	data(){
		return {
			displayCustomersLabel: false,
			icons: {
				fatrash: faTrash,
			},
			labels: []
		}
	},
	methods: {
		closeLabelsListPopUp() {
			this.displayCustomersLabel = false;
		},
		loadLabelsList() {
			let form = new FormData;
			form.append('action', 'dm_list_labels');
			form.append('customer_email', this.$store.state.profileModule.userEmail);
			axios({
				method: 'post',
				url: this.$wpAjaxUrl,
				data: form
			}).then(response => {
				this.labels = response.data.labels;
				if (this.labels.length == 0 && this.$route.params.param && this.$route.params.param == 'personal') {
					this.$router.push('/editor/');
				}
				this.displayCustomersLabel = true;
			}).catch(error => {
				console.log(error);
			});
		},
		confirmDelete(label) {
            this.$confirm.require({
                message: 'Do you want to delete this label?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
					if (label && label.id) {
						let form = new FormData;
						form.append('action', 'dm_delete_label');
						form.append('customer_email', this.$store.state.profileModule.userEmail);
						form.append('label_id', label.id);
						axios({
							method: 'post',
							url: this.$wpAjaxUrl,
							data: form
						}).then(response => {
							this.$toast.add({severity:'info', summary:'Confirmed', detail: response.data.message, life: 3000});
							this.loadLabelsList();
						}).catch(error => {
							console.log(error);
						});
					}
                },
                reject: () => {
                    this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        }
	},
	mounted() {
		this.emitter.on('loadLabelsList', () => this.loadLabelsList());
	}
}
</script>
<style scoped>
.list-label{
	cursor: pointer;
}
.list-label:hover{
	text-decoration: underline;
}
.empty-list{
	text-align: center;
}
.list-users-labels{
	width: 100%;
}
</style>