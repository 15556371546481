<template>
    <div>
		<div v-if="loading" class="preloader">
			<ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
		</div>
		<Dialog 
			header="Upload Images"
			v-model:visible="this.displayUploadImage"
			:breakpoints="{'960px': '60vw', '640px': '60vw'}"
			:style="{width: '50vw'}">
			<span class="text-xs font-bold">After selected file(s), click button "upload file(s)"</span>
			<div class="card2">
				<Toast />
				<FileUpload name="images[]" :url="$wpAjaxUrl" @upload="onTemplatedUpload($event)" :multiple="true" accept="image/*"  @select="onSelectedFiles">
					<template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
						<div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
							<div class="flex gap-2">
								<Button @click="chooseCallback()" ref="selectFiles" icon="pi pi-images" label="select file(s)"></Button>
								<Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" severity="success" :disabled="!files || files.length === 0" label="upload file(s)"></Button>
								<Button @click="clearCallback()" icon="pi pi-times" severity="danger" :disabled="!files || files.length === 0" label="delete file"></Button>
							</div>
							<ProgressBar
								:value="totalSizePercent"
								:showValue="false"
								:class="['md:w-10rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 10000 }]">
								<span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
							</ProgressBar>
						</div>
					</template>
					<template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
						<div v-if="files.length > 0">
							<h5>Pending</h5>
							<div class="flex flex-wrap p-0 sm:p-5 gap-5">
								<div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
									<div>
										<img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
									</div>
									<span class="font-semibold">{{ file.name }}</span>
									<div>{{ formatSize(file.size) }}</div>
									<Badge value="Pending" severity="warning" />
									<Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded  severity="danger" />
								</div>
							</div>
						</div>
						<div v-if="uploadedFiles.length > 0">
							<h5>Completed</h5>
							<div class="flex flex-wrap p-0 sm:p-5 gap-5">
								<div v-for="(file, index) of uploadedFiles" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
									<div>
										<img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
									</div>
									<span class="font-semibold">{{ file.name }}</span>
									<div>{{ formatSize(file.size) }}</div>
									<Badge value="Completed" class="mt-3" severity="success" />
									<Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded  severity="danger" />
								</div>
							</div>
						</div>
					</template>
					<template #empty>
						<div class="flex align-items-center justify-content-center flex-column" @click="callUploadFiles">
							<img alt="dropdown icon" :src="`${this.pathUrl}/images/icons/icon-add.svg`">
							<!-- <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" /> -->
							<p class="mb-0 mt-2 text-under-button"><span>Select Files to Uploads</span><br>
							or Drag and drop files to here to upload.</p>
						</div>
					</template>
				</FileUpload>
			</div>
        </Dialog>
	</div>
</template>
<script>
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Badge from 'primevue/badge';
import { isProxy, toRaw } from 'vue';
import axios from 'axios';
export default {
	name: "UploadImage",
	components: {
		FileUpload: FileUpload,
		ProgressBar: ProgressBar,
		Badge: Badge
	},
	data() {
		return {
			displayUploadImage: false,
			action: 'dm_upload_image',
			files: [],
			totalSize: 0,
      		totalSizePercent: 0,
			uploadedFiles: [],
			loading: false
		}
	},
	methods: {
		openUploadImage() {
            this.displayUploadImage = true;
        },
		selectFiles(event){
			this.files.push(event.files);
		},
		onRemoveTemplatingFile(file, removeFileCallback, index) {
            removeFileCallback(index);
            this.totalSize -= parseInt(this.formatSize(file.size));
            this.totalSizePercent = this.totalSize / 10;
        },   
        onSelectedFiles(event) {
            this.files = toRaw(event.files);
            this.files.forEach((file) => {
                this.totalSize += parseInt(this.formatSize(file.size));
            });
        },
		callUploadFiles() {
			this.$refs.selectFiles.$el.click();
		},
		getMeta(url, callback) {
			const img = new Image();
			img.src = url;
			img.onload = function() {
				callback(this.width, this.height);
			}
		},
		checkTypeImage(type) {
			let allowExt = ['image/svg+xml', 'image/jpeg', 'image/gif', 'image/png', 'image/webp', 'image/bmp'];
			if (allowExt.includes(type)) {
				return true;
			}
			this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Extension not allowed. You can only upload jpg, jpeg, jfif, png, svg, webp, gif, bmp and svg files', life: 3000 });
			return false;
		},
		getBase64(file) {
			let self = this;
			let reader = new FileReader();
			reader.onload = function (e) {
				let img = new Image();
				img.onload = function() {
					self.getMeta(reader.result,	(width, height) => {
						self.emitter.emit('addImage', {
							src: reader.result,
							width: img.width || width,
							height: img.height || height,
							isUploaded: true,
							isCustom: true
						});
						self.loading = false;
					});
				};
				img.src = reader.result;
			};
          	reader.readAsDataURL(file);
		},		
		uploadEvent() {
			this.loading = true;
      		this.totalSizePercent = this.totalSize / 0.5;
			let countFiles = this.files;
			if (countFiles.length) {
				for (let i=0; i<countFiles.length; i++) {
					if (!this.checkTypeImage(countFiles[i].type)) {
						continue;
					}
					this.getBase64(countFiles[i]);
					
					this.displayUploadImage = false;
					this.$toast.add({ severity: 'info', summary: 'Success', detail: 'Image(s) uploades succefully!', life: 3000 });
				}
			}
        },
		// uploadEvent() {
		// 	this.loading = true;
      	// 	this.totalSizePercent = this.totalSize / 0.5;
		// 	let countFiles = this.files;
		// 	if (countFiles.length) {
		// 		for (let i=0; i<countFiles.length; i++) {
		// 			if (!this.checkTypeImage(countFiles[i].type)) {
		// 				continue;
		// 			}
		// 			let data = new FormData();
		// 			data.append('images', countFiles[i]);
		// 			data.append('action', 'dm_upload_image');
		// 			let headers = {
		// 				'Content-Type': 'multipart/form-data',
		// 				// 'Access-Control-Allow-Origin': '*',
		// 				// 'Access-Control-Allow-Methods':'POST, OPTIONS',
		// 				// "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
		// 			};
		// 			axios.post(this.$wpAjaxUrl, data, {headers})
		// 				.then(response => {
		// 					if (response && response.data) {
		// 						if (response.data.status == 'OK') {
		// 							this.displayUploadImage = false;
		// 							this.$toast.add({ severity: 'info', summary: 'Success', detail: response.data.message, life: 3000 });
		// 							if (response.data.src) {
		// 								this.getMeta(response.data.src,	(width, height) => {
		// 									this.emitter.emit('addImage', {
		// 										src: response.data.src,
		// 										width: response.data.width || width,
		// 										height: response.data.height || height,
		// 									});
		// 									this.loading = false;
		// 								});
		// 							}
		// 						}
		// 						if (response.data.status == 'Error') {
		// 							this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
		// 						}
		// 					}
		// 			});
		// 		}
		// 	}
        // },
        onTemplatedUpload() {
            this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
        },
        formatSize(bytes) {
            if (bytes === 0) {
                return '0 B';
            }
            let k = 1000,
                dm = 3,
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    }	
}
</script>

<style>
.text-under-button{
	width: 300px;
	text-align: center;
}
.text-under-button>span{
	font-size: 20px;
}
</style>