export const canvasModule = {
    state: () => ({
        $side: 'front',
        $showBackCanvas: false
    }),
    getters: {
    },
    mutations: {
        setSide(state, side) {
            state.$side = side;
        },
        setShowBackCanvas(state, isShow) {
            state.$showBackCanvas = isShow;
        }
    },
    actions: {
        setSide(context, payload) {
            context.commit('setSide', payload);
        },
        setShowBackCanvas(context, payload) {
            context.commit('setShowBackCanvas', payload);
        }
    },
    namespaced: true
}
