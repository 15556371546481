<template>
  <div v-if="loading" class="preloader">
		<ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
	</div>
	<div class="main" id="editor" v-if="titlePage">
		<div class="header-block">
			<h1>{{ titlePage }}</h1>
		</div>
          <div class="grid content-page">
            <div class="col-8">
                <div class="grid">
                    <div class="col-12">TELL US WHAT'S ON YOUR MIND</div>
                    <div class="col-6">
                      <InputText
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Your name*"
                        :class="{ 'p-invalid' : wrongName }"                        
                        v-model="userName"/>
                    </div>
                    <div class="col-6">
                      <InputText
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Your e-mail*"
                        :class="{ 'p-invalid' : wrongEmail }" 
                        v-model="userEmail"/>
                    </div>
                    <div class="col-12">
                      <InputMask
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Your Phone Number (optional)"
                        date="phone"
                        mask="(999) 999-9999"
                        v-model="userPhone"/>
                    </div>
                    <div class="col-12">
                      <Textarea
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        autoResize
                        placeholder="Enter message"
                        rows="5"
                        v-model="userMessage"/>
                    </div>
                    <div class="col-12">
                      <Button class="left-0" :style="{background:'#ed640d'}" label="Submit" @click="sendMessage"/>
                    </div>
                </div>
            </div>
          <div class="address-block col-4">
              <h3>Address</h3>
              <p>{{ nameData }}</p>
              <p>{{ addressData }}</p>
              <p><a :href="`mailto:${emailData}`" :style="{color: '#ed640d'}">{{ emailData }}</a></p>
              <p>{{ phoneData }}</p>
          </div>
      </div>
		<div class="grid content-page text-block" v-html="contentPage"></div>
	</div>
  <Toast />
</template>
  
  <script>
   import axios from 'axios';
   import InputMask from 'primevue/inputmask';
   import Textarea from 'primevue/textarea';
  export default {
    name: 'ContactPage',
    components: {
      Textarea,
      InputMask
    },
    data() {
      return {
        titlePage: '',
        contentPage: '',
        addressData: '',
        nameData: '',
        emailData: '',
        phoneData: '',
        userName: null,
        userEmail: null,
        userPhone: null,
        userMessage: null,
        wrongName: false,
        wrongEmail: false,
        loading: false
      }
    },
    methods: {
        getDataPage() {
          this.loading = true;
          let form = new FormData;
          form.append('action', 'dm_get_page_by_slug');
          form.append('slug', 'contact');
          axios({
              method: 'post',
              url: this.$wpAjaxUrl,
              data: form
          }).then(response => {
              this.loading = false;
              if (response.data && response.data.status == 'OK') {
                  this.titlePage = response.data.pageTitle;
                  this.contentPage = response.data.pageContent;
                  this.nameData = response.data.nameField.length ? response.data.nameField[0] : '';
                  this.addressData = response.data.addressField.length ? response.data.addressField[0] : '';
                  this.emailData = response.data.emailField.length ? response.data.emailField[0] : '';
                  this.phoneData = response.data.phoneField.length ? response.data.phoneField[0] : '';
              } else {
                  this.$router.push('/404');
              }
          }).catch(error => {
              console.log(error);
          });
        },
        sendMessage() {         
          if (this.userName && this.userEmail) {
            let form = new FormData;
            form.append('action', 'dm_send_message');
            form.append('name', this.userName);
            form.append('email', this.userEmail);
            form.append('phone', this.userPhone);
            form.append('message', this.userMessage);
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
                if (response.data && response.data.status == 'OK') {
                  this.$toast.add({severity:'success', summary: 'Success Message', detail: response.data.message, life: 3000});
                  this.userName = null;
                  this.userEmail = null;
                  this.userPhone = null;
                  this.userMessage = null;
                  
                  this.wrongName = false;
                  this.wrongEmail = false;
                } else {
                  this.$toast.add({severity:'warn', summary: 'Warn Message', detail: response.data.message, life: 3000});
                }
            }).catch(error => {
                console.log(error)
          });
        } else {
          this.$toast.add({severity:'warn', summary: 'Warn Message', detail: 'All required fields must be filled!', life: 3000});
          if (!this.userName) {
            this.wrongName = true;
          }
          if (!this.userEmail) {
            this.wrongEmail = true;        
          }
        }
      }
    },
    created () {
		this.getDataPage();
	},
	beforeRouteUpdate (to, from, next) {
		this.getDataPage();
		next();
	}
  }
  </script>
  
  <style scoped>
   h1{
	margin-left: 10px;
	font-size: 27px;
  font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  .text-block{
    text-align: justify;
    padding: 20px;
  }
  .address-block{
    text-align: left;
  }
  .content-page{
    background-color: white;
  }
  .p-invalid{
    border: 1px solid red !important;
  }
  input:focus{
    border: 1px solid #c3b8b8 !important;
  }
  .p-inputtext{
    padding: 8px !important;
  }
  </style>
  