<template>
  <section>
	<div class="main mt-3" id="">
		<div class="header-block">
			<h1>Customer Sign in</h1>
		</div>
		<div class="grid content-page">
    <div class="loginCon">
			<div class="loginBox">
				<div class="returningCustomer">
					<div class="retCustHd">
						<i class="returingCutIcon"></i>
						<strong>Returning Customer?</strong>
						<span>Sign in to your customer account</span>
					</div>																			
					<div class="loginbxLeft">
            <InputText
              v-model.trim="username"
              class="inputBox required email w-full"
              placeholder="Enter Email Address" />
            <label class="error email_validate"></label>
            <InputText
              type="password"
              class="inputBox required w-full"
              v-model.trim="password"
              placeholder="Enter Password" />            
                 <!-- <input type="checkbox" class="checkbox" id="remember_me" name="remember_me"><span id="rem_text">Remember Me</span>-->
            <span @click="$router.push('/password-forgot')" class="forgot mt-2 mb-2 cursor-pointer">Forgot your password?</span>
						<div class="clearfix"></div>
            <Button label="submit" @click="login" class="submitBtn mt-3 loginsubmit"/>
						<div class="rightLine"></div>
					</div>
				</div>	
				<div class="newCusromer">
					<div class="newCustHd">
						<i class="newCusticon"></i>
						<strong>Are you a New Customer?</strong>
						<span>Please click below to register</span>
					</div>
					<div class="loginbxRight">
            <Button label="Click Here To Register" @click="$router.push('/register')" class="btn register"/>
              <span>or</span>
            <Button label="Checkout As Guest" @click="selectLogin" class="btn Guest"/>
					</div>
				</div>
				<div class="clearfix"></div>
			</div>
		</div>
        </div>
	</div>
  <Toast />
  <Dialog v-model:visible="visibleSelectLogin" modal header="Registered User" :style="{ width: '35vw' }">
    <div class="grid">
      <div class="col-5">
        <Button class="w-full bg-orange-500"  @click="visibleSelectLogin = false" label="Login" />
      </div>
      <div class="col-2 text-center">OR</div>
      <div class="col-5">
        <Button class="w-full bg-orange-500" @click="showGuestPoUp" label="Checkout as Guest" />
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="visibleGuestLogin" modal header="Guest User" :style="{ width: '35vw' }">
    <Message v-if="isMessage" :severity="typeMessage" sticky>{{ message }}</Message>
    <div class="grid">
      <div class="col-12">
        <InputText
          type="text"
          class="text-base text-color surface-overlay border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
          placeholder="Enter e-mail"
          v-model="guestEmail"/>
      </div>      
      <div class="col-12">
        <Button class="w-full bg-orange-500" @click="submitGuest" label="Submit" />
      </div>
    </div>
  </Dialog>
  </section>
</template>
<script>
import Message from 'primevue/message';
import {mapState, mapActions, mapMutations} from 'vuex';
export default {
  name: 'LoginPage',
  components: {
    Message
  },
  data() {
      return {
        username: '',
        password: '',
        visibleSelectLogin: false,
        visibleGuestLogin: false,
        guestEmail: null,
        isMessage: false,
        typeMessage: null,
        message: null
      }
    },
    methods: {
      ...mapMutations({
        setAdminEmail: 'order/setAdminEmail',
        setGuest: 'order/setGuest'
      }),
      ...mapActions({
        setAdminEmail: 'order/setAdminEmail',
        setGuest: 'order/setGuest'
      }),
      selectLogin() {
        this.visibleSelectLogin = true;
      },
      showGuestPoUp() {
        this.visibleSelectLogin = false;
        this.visibleGuestLogin = true;
      },
      async submitGuest() {
        let response = await this.$store.dispatch('checkUser', {
          userEmail: this.guestEmail
        });
        if (response && response.status == 200) {
          this.isMessage = true;
          this.typeMessage = 'warn';
          this.message = 'User Already exist please login first!!!'
        }
        if (response && response.status == 400) {
          this.isMessage = true;
          this.typeMessage = 'error';
          this.message = 'You entered incorrect e-mail!!!'
        }

        this.visibleGuestLogin = false;
      },
       login() {
        try {
          this.$store.dispatch('login', {
            username: this.username,
            password: this.password
          }); 

          this.$router.push({ name: 'home' });
        } catch (error) {
          this.$toast.add({ severity: 'error', summary: 'Error Message', detail: 'Email or password is wrong!', life: 3000 });
        }
      },
      async checkLogIn(){
        let isValid = await this.$store.dispatch('checkLoginState');
        if (isValid) {
        this.$router.push({
          name: 'home'
        });
      }
      }
    },
    mounted(){
      this.checkLogIn();
    },
		async checkAdmin() {
			await this.$store.dispatch('isAdmin', {
				'user_email': this.$store.state.profileModule.userEmail 
			});
		},
    async created() {
      if (this.$route.meta.noAuthRequired) {
        return;
      }
      let isGuest = localStorage.getItem('isGuest');
      let isValid = await this.$store.dispatch('checkLoginState');
      if (isValid || isGuest) {
        this.$router.push({
          name: 'home'
        });
      }
    }
}
</script>
<style scoped>
  h1{
  margin-top: 20px;
  font-family: Oswald;
  font-size: 26px;
}
.loginHd {
  color: #272727;
  font-family: oswaldbook;
  font-size: 26px;
  text-align: center;
  padding: 30px 0;
}
.loginBox {
  background: #fff;
  width: 101%;
  max-width: 734px;
  padding: 20px;
  min-height: 200px;
  margin: auto;
  padding-bottom: 50px;
}
.returningCustomer {
  width: 50%;
  float: left;
  padding-right: 40px;
}
.retCustHd {
  font-family: open_sanssemibold;
  position: relative;
  padding-left: 36px;
}
.returingCutIcon {
  width: 28px;
  height: 32px;
  text-align: left;
  background: url('../../../public/images/template/returning_customer_icon.png') no-repeat center center;
  display: block;
  position: absolute;
  left: 0;
  top: 6px;
}
i {
  font-style: italic;
}
.loginBox strong {
  color: #000;
  text-transform: uppercase;
  font-size: 18px;
  font-family: open_sanssemibold;
  display: block;
}
b, strong {
  font-weight: 700;
}
strong {
  font-weight: bold;
}
.loginBox span {
  color: #64676b;
  text-transform: inherit;
  font-size: 14px;
  display: block;
  font-family: open_sanssemibold;
}
.loginbxLeft {
  margin-top: 20px;
  position: relative;
}
form {
  display: block;
  margin-top: 0em;
}
ol, ul {
  margin-top: 0;
  margin-bottom: 10px;
}
ul {
  padding: 0;
  margin: 0;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.loginbxLeft ul li {
  margin-top: 15px;
}
li {
  list-style: none;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
ul {
  list-style-type: disc;
}
.loginbxLeft ul li {
  margin-top: 15px;
}
.checkLi {
  margin-top: 8px!important;
}
button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input {
  line-height: normal;
}
button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
input {
  outline: none;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  outline: none;
}
input[type="hidden" i] {
  appearance: none;
  background-color: initial;
  cursor: default;
  display: none !important;
  padding: initial;
  border: initial;
}
input {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: field;
  margin: 0em;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.inputBox {
  border: 1px solid #c3b8b8 !important;
  color: #9a9aba;
  display: block;
  height: 35px;
  font-family: open_sansregular;
  font-size: 13px;
  font-weight: normal;
  padding: 8px;
  width: 100%;
}
label.error {
  color: red;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
label {
  cursor: default;
}
input[type=checkbox], input[type=radio] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}
input[type=checkbox], input[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.checkbox, .radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox {
  float: left;
}
.checkLi > span {
  float: left;
  font-size: 12px;
  margin: 3px 3px;
  color: #ababab;
}
.checkLi a {
  color: #ed640d;
  font-size: 13px;
  float: right;
  font-family: open_sanssemibold;
  margin-top: -2px;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a {
  background-color: transparent;
}
a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  outline: none;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}
.clearfix {
  clear: both;
}
button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}
.submitBtn {
  background: #ed640d;
  color: #fff;
  font-size: 14px;
  font-family: open_sanssemibold;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  box-shadow: 0 0 3px #ddd inset;
  width: 100%;
  padding: 6px 0;
  border-radius: 3px;
  border: 1px solid #dc6b00;
}
.rightLine {
  background: #dde2e5 none repeat scroll 0 0;
  height: 100%;
  position: absolute;
  right: -50px;
  top: 0;
  width: 1px;
}
.newCusromer {
  width: 50%;
  float: right;
  padding-left: 15px;
}
.newCustHd {
  position: relative;
  padding-left: 39px;
}
.newCusticon {
  width: 35px;
  height: 35px;
  text-align: left;
  background: url('../../../public/images/template/new_custome.png') no-repeat center center;
  display: block;
  position: absolute;
  left: 0;
  top: 4px;
}
.loginbxRight .btn {
  background: url('../../../public/images/template/loginBtnsback.png') repeat-x;
  width: 240px;
  border: 1px solid #737b82;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  display: block;
  margin: 20px auto;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a {
  background-color: transparent;
}
a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  outline: none;
}
.loginbxRight > span {
  border: 1px solid #c8c8c8;
  border-radius: 100%;
  display: block;
  font-size: 12px;
  height: 30px;
  line-height: 26px;
  margin: 22px auto;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  width: 30px;
}
.loginCon{
  margin: 0 auto !important;
}
.forgot{
  color: #ed640d !important;
}
.p-inputtext{
  padding: 8px !important;
}
</style>
  
