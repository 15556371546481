<template>
  <section>
    <div v-if="loading" class="preloader">
        <ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
    </div>
    <div class="main" id="editor">
      <div class="header-block">
        <Toast />
        <h1>Checkout</h1>
      </div>
      <div class="card grid">
        <div class="col-12 text-center" v-if="succesMessage">
          <h3 class="text-center">Thank you!!!</h3>
          <h3 class="text-center">{{ succesMessage }}</h3>
          <div class="buttons-block text-center">
            <router-link :to="'/'">
                <Button class="cart-link">Home</Button>
            </router-link>
            <!-- <router-link :to="'/editor'">
                <Button class="cart-link">Create new Label</Button>
            </router-link> -->
            <router-link :to="'/categories'">
                <Button class="cart-link">Continue shoping</Button>
            </router-link>
          </div>
        </div>
        <div class="col-12 grid" v-else>
            <div class="col-8 border-bottom-1 border-400">
                <div class="grid p-2">
                    <div class="col-12">SHIPPING ADDRESS</div>
                    <div class="col-6">
                      <label for="username" class="text-sm">First Name*</label>
                      <InputText
                        id="username"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter first name"
                        :class="{ 'p-invalid' : wrongName }"
                        v-model="shipping.first_name"/>
                    </div>
                    <div class="col-6">
                      <label for="lastname" class="text-sm">Last Name*</label>
                      <InputText
                        id="lastname"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter last name"
                        :class="{ 'p-invalid' : wrongName }"
                        v-model="shipping.last_name"/>
                    </div>
                    <div class="col-6">
                      <label for="address1" class="text-sm">Address Line 1*</label>
                      <InputText
                        id="address1"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter address 1"
                        :class="{ 'p-invalid' : wrongName }"
                        v-model="shipping.address1"/>
                    </div>
                    <div class="col-6">
                      <label for="address2" class="text-sm">Address Line 2</label>
                      <InputText
                        id="address2"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter address 2"
                        v-model="shipping.address2"/>
                    </div>
                    <div class="col-6 grid ml-1">
                      <label for="country" class="text-sm col-12 p-1 m-0">Country*</label>
                      <div class="col-12 p-0">
                        <InputText
                        id="country"
                        disabled
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        v-model="shipping.country"/>
                        <!-- <Dropdown
                          datatID="country"
                          v-model="shipping.country"
                          :options="countries"
                          optionLabel="name"
                          @change="setShippingCountry($event, 'shipping')"
                          placeholder="Select country"
                          class="w-full p-2 m-0" /> -->
                      </div>
                    </div>
                    <div class="col-6 grid ml-1">
                      <label for="state" class="text-sm col-12 p-1 m-0">State*</label>
                      <Dropdown
                          datatID="state"
                          v-model="shipping.state"
                          :options="shippingZoon"
                          optionLabel="name"
                          optionValue="code"
                          @change="setShippingZoon($event)"
                          placeholder="Select state"
                          class="w-full p-2 m-0" />
                    </div>
                    <div class="col-6">
                      <label for="zip" class="text-sm">Zip Code*</label>
                      <InputText
                        id="zip"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter zip code"
                        v-model="shipping.postal_code"/>
                    </div>
                    <div class="col-6">
                      <label for="phone" class="text-sm">Phone Number*</label>
                      <InputText
                        id="phone"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter Mobile Number"
                        date="phone"
                        mask="(999) 999-9999"
                        v-model="shipping.phone"/>
                    </div>
                    <div class="col-6">
                      <label for="town" class="text-sm">Town/City*</label>
                      <InputText
                        id="town"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter Town/City"
                        v-model="shipping.city"/>
                    </div>      
                </div>
            </div>
          <div class="address-block col-4 surface-100 mt-2 p-2">
            <h6 class="text-center mb-4 font-semibold">Cart total</h6>
            <div class="result-block grid">
                <div class="total col-6 m-0">Total items:</div><div class="col-6">{{ totalItems }}</div>
            </div>
            <div class="result-block grid">
                <div class="total col-6 m-0">Sub-Total:</div><div class="col-6">${{ subTotal.toFixed(2) }} (USD)</div>
            </div>
            <div class="result-block grid">
                <div class="total col-6 m-0">Shipping fee:</div><div class="col-6">${{ fee }} (USD)</div>
            </div>
            <div class="result-block mt-3 grid border-top-1">
                <div class="total col-6 m-0 font-semibold">Total:</div><div class="font-semibold col-6 text-red-500">${{ totalPrice.toFixed(2) }} (USD)</div>
            </div>
            <div class="result-block">
                <router-link class="total mb-1" :to="'/categories'">
                  <Button class="w-full bg-orange-500">Continue shoping</Button>
                </router-link>
           </div>
           <div class="result-block">
                <div class="total mb-1">
                    <Button @click="placeOrder" class="w-full bg-orange-500">Proced to checkout</Button>
                </div>
            </div>
          </div>
          <div class="col-12">
            <div class="col-8">
                <div class="grid p-2">
                    <div class="col-12">BILLING ADDRESS</div>
                    <div class="col-12 ml-0 p-0">
                        <Checkbox
                            inputId="isthesame"
                            class="ml-1"
                            v-model="isthesame"
                            @change="useShippingAddress"
                            :binary="true"/>
                        <!-- <input type="checkbox" id="isthesame" value="true" @change="useShippingAddress($event)" /> -->
                        <label for="isthesame" class="ml-2 font-semibold text-sm m-0"> Billing Address Is Same As Shipping </label>                         
                    </div>
                    <div class="col-6">
                      <label for="username" class="text-sm">First Name*</label>
                      <InputText
                        id="username"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter first name"
                        :class="{ 'p-invalid' : wrongName }"
                        v-model="billing.first_name"/>
                    </div>
                    <div class="col-6">
                      <label for="lastname" class="text-sm">Last Name*</label>
                      <InputText
                        id="lastname"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter last name"
                        :class="{ 'p-invalid' : wrongName }"
                        v-model="billing.last_name"/>
                    </div>
                    <div class="col-6">
                      <label for="email" class="text-sm">E-mail*</label>
                      <InputText
                        id="email"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter e-mail"
                        :class="{ 'p-invalid' : wrongName }"
                        v-model="email"/>
                    </div>
                    <div class="col-6">
                      <label for="confirmEmail" class="text-sm">Confirm e-mail*</label>
                      <InputText
                        id="confirmEmail"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Confirm e-mail"
                        :class="{ 'p-invalid' : wrongName }"
                        v-model="confirmEmail"/>
                    </div>
                    <div class="col-6">
                      <label for="address1" class="text-sm">Address Line 1*</label>
                      <InputText
                        id="address1"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter address 1"
                        :class="{ 'p-invalid' : wrongName }"
                        v-model="billing.address1"/>
                    </div>
                    <div class="col-6">
                      <label for="address2" class="text-sm">Address Line 2</label>
                      <InputText
                        id="address2"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter address 2"
                        v-model="billing.address2"/>
                    </div>
                    <div class="col-6 grid ml-1">
                      <label for="country2" class="text-sm col-12 p-1 m-0">Country*</label>
                      <div class="col-12 p-0">
                        <InputText
                        id="country2"
                        disabled
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        v-model="billing.country"/>
                        <!-- <Dropdown
                          datatID="country2"
                          v-model="billing.country"
                          :options="countries"
                          optionLabel="name"
                          @change="setBillingCountry($event, 'billing')"
                          placeholder="Select country"
                          class="w-full p-2 m-0" /> -->
                      </div>
                    </div>
                    <div class="col-6 grid ml-1">
                      <label for="state" class="text-sm col-12 p-1 m-0">State*</label>
                      <Dropdown
                          datatID="state"
                          v-model="billing.state"
                          :options="billingZoon"
                          optionLabel="name"
                          optionValue="code"
                          @change="setBillingZoon($event)"
                          placeholder="Select state"
                          class="w-full p-2 m-0" />
                    </div>
                    <div class="col-6">
                      <label for="zip" class="text-sm">Zip Code*</label>
                      <InputText
                        id="zip"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter zip code"
                        v-model="billing.postal_code"/>
                    </div>
                    <div class="col-6">
                      <label for="phone" class="text-sm">Phone Number*</label>
                      <InputText
                        id="phone"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter Mobile Number"
                        date="phone"
                        mask="(999) 999-9999"
                        v-model="billing.phone"/>
                    </div>
                    <div class="col-6">
                      <label for="town" class="text-sm">Town/City*</label>
                      <InputText
                        id="town"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        placeholder="Enter Town/City"
                        v-model="billing.city"/>
                    </div>      
                </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog></ConfirmDialog>
    </div>
    </section>
  </template>  
<script>
import DataView from 'primevue/dataview';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import 'primeflex/primeflex.css';
import axios from 'axios';
  export default {
    name: 'CheckoutPage',
    components:{
        DataView,
        RadioButton,
        Checkbox,
        Dropdown
    },
    data() {
      return {
        subTotal: 0,
        fee: 0,
        wrongName: true,
        isthesame: false,
          shipping: {
            first_name: '',
            last_name: '',
            country: '',
            state: '',
            city: '',
            postal_code: '',
            address1: '',
            address2: '',
            phone: ''
          },
          billing: {
            first_name: '',
            last_name: '',
            country: '',
            state: '',
            city: '',
            email: this.$store.state.profileModule.userEmail ||  localStorage.getItem('email') || localStorage.getItem('tmp_email'),
            confirmEmail: this.$store.state.profileModule.userEmail ||  localStorage.getItem('email') || localStorage.getItem('tmp_email'),
            postal_code: '',
            address1: '',
            address2: '',
            phone: ''
          },                  
          tmpFirstName: '',
          tmpLastName: '',
          tmpCountry: '',
          tmpState: '',
          tmpCity: '',
          tmpPostalCode: '',
          tmpAddress1: '',
          tmpAddress2: '',
          tmpPhone: '',
        countries: [],
        shippingZoon: [],
        billingZoon: [],
        orderID: 0,
        succesMessage: null,
        totalPrice: 0,
        totalItems: 0,
        loading: false,
        shapes: null,
        email: this.$store.state.profileModule.userEmail ||  localStorage.getItem('email') || localStorage.getItem('tmp_email'),
        confirmEmail: this.$store.state.profileModule.userEmail ||  localStorage.getItem('email') || localStorage.getItem('tmp_email'),
        billingAddress: {},
        shippingAddress: {}
      }
    },
    methods: {
      getShippingFee() {
        let form = new FormData;      
        form.append('action', 'dm_get_shipping_fee');
        axios({
          method: 'post',
          url: this.$wpAjaxUrl,
          data: form
        }).then(response => {
          if (response && response.data.status == 'Ok') {
            this.fee = response.data.fee;
          }
        }).catch(error => {
          console.log(error);
        });
      },
        getCountries() {
          let form = new FormData;
          form.append('action', 'dm_get_countries');
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            if (response.data.contries.length) {
              this.shipping.country = response.data.contries[0].name;
              this.billing.country = response.data.contries[0].name;
              // this.countries = Array.from(response.data.contries);
            }
          }).catch(error => {
            console.log(error);
          });
        },
        getZoon(/*countryID, param*/) {
          let form = new FormData;
          form.append('action' , 'dm_get_zoon');
          // form.append('countryID', countryID);
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            if (response.data.zoon.length) {
              // if (param == 'shipping') {
                this.shippingZoon = Array.from(response.data.zoon);
              // }
              // if (param == 'billing') {
                this.billingZoon = Array.from(response.data.zoon);
              // }
            }
          }).catch(error => {
            console.log(error);
          });
        },
        setShippingCountry(event, param) {
          this.getZoon(event.value.id, param);
        },
        setBillingCountry(event, param) {
          this.getZoon(event.value.id, param);
        },
        setShippingZoon(event){
          this.shipping.state = event.value;
        },
        setBillingZoon(event){
          this.billing.state = event.value;
        },
        getOrder() {
           //TODO need check email and only for this email get data  
          // let orderID = localStorage.getItem('order') || 0;
          // form.append('orderID', orderID);
          let email = this.$store.state.profileModule.userEmail || localStorage.getItem('email') || localStorage.getItem('tmp_email');
          if (!email){
              this.$router.push({
                    name: 'home'
                });
            } 
          this.loading = true;
          let form = new FormData;
          form.append('action' , 'dm_get_order');
          form.append('customer_email', email);
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            this.loading = false;
            if (!response.data.result || (response.data.result && response.data.result.length == 0)){
              this.$router.push({
                    name: 'home'
                });
            } else if (response.data.result.length && response.data.result[0].order_id) {
              this.getCountries();
              this.orderID = response.data.result[0].order_id;
              this.products = response.data.result;             
              this.getTotalSum(this.products);
              this.getTotalItems(this.products);
            }                     
          }).catch(error => {
            console.log(error);
          });
        },
        useShippingAddress() {
            if (this.isthesame) {
                this.billing.first_name = this.shipping.first_name;
                this.billing.last_name = this.shipping.last_name;
                // this.billing.country = this.shipping.country;
                
                this.billing.city = this.shipping.city;
                this.billing.postal_code = this.shipping.postal_code;
                this.billing.address1 = this.shipping.address1;
                this.billing.address2 = this.shipping.address2;
                this.billing.phone = this.shipping.phone;

                this.getZoon(this.billing.country.id, 'billing');
                if(this.billingZoon){
                  this.billing.state = this.shipping.state;
                }
            } else {
                this.billing.first_name = this.tmpFirstName;
                this.billing.last_name = this.tmpLastName;
                this.billing.country = this.tmpCountry;
                this.billing.state = this.tmpState;
                this.billing.city = this.tmpCity;
                this.billing.email = this.tmpEmail;
                this.billing.confirmEmail = this.tmpConfirmEmail;
                this.billing.postal_code = this.tmpPostalCode;
                this.billing.address1 = this.tmpAddress1;
                this.billing.address2 = this.tmpAddress2;
                this.billing.phone = this.tmpPhone;
            }
        },
        async getPersonalInfo() {
            let personalData = await this.$store.dispatch('getPersonalInfo', {
              'user_email': this.$store.state.profileModule.userEmail ||  localStorage.getItem('email') || localStorage.getItem('tmp_email')
            });
            //TODO
            // let isGuest = localStorage.getItem('isGuest');
            // let isLoggedIn = this.$store.state.profileModule.isLoggedIn;
            
            // if (personalData.status.status != 200 && !isGuest && !isLoggedIn) {
            //     this.$router.push({
            //         name: 'login'
            //     });
            // }
            if (personalData && personalData.status && personalData.status.data) {//TODO
              this.tmpFirstName = personalData.status.data.first_name || '';
              this.tmpLastName = personalData.status.data.last_name || '';
              this.tmpCountry = personalData.status.data.country || '';
              this.tmpState = personalData.status.data.state || '';
              this.tmpCity = personalData.status.data.city || '';
              this.tmpEmail = personalData.status.data.email || this.$store.state.profileModule.userEmail ||  localStorage.getItem('email')  || localStorage.getItem('tmp_email');
              this.tmpConfirmEmail = personalData.status.data.email || this.$store.state.profileModule.userEmail ||  localStorage.getItem('email') || localStorage.getItem('tmp_email');
              this.tmpPostalCode = personalData.status.data.postal_code || '';
              this.tmpAddress1 = personalData.status.data.address || '';
              this.tmpAddress2 = '';
              this.tmpPhone = personalData.status.data.phone || '';
              
              this.billing.first_name = personalData.status.data.first_name || '';
              this.billing.last_name = personalData.status.data.last_name || '';
              this.billing.country = personalData.status.data.country || '';
              this.billing.state = personalData.status.data.state || '';
              this.billing.email = personalData.status.data.email || this.$store.state.profileModule.userEmail ||  localStorage.getItem('email') || localStorage.getItem('tmp_email') ;
              this.billing.confirmEmail = personalData.status.data.email || this.$store.state.profileModule.userEmail ||  localStorage.getItem('email') || localStorage.getItem('tmp_email');
              this.billing.city = personalData.status.data.city || '';
              this.billing.postal_code = personalData.status.data.postal_code || '';
              this.billing.address1 = personalData.status.data.address || '';
              this.billing.address2 = '';
              this.billing.phone = personalData.status.data.phone || '';

              this.shipping.first_name = personalData.status.data.first_name || '';
              this.shipping.last_name = personalData.status.data.last_name || '';
              this.shipping.country = personalData.status.data.country || '';
              this.shipping.state = personalData.status.data.state || '';
              this.shipping.city = personalData.status.data.city || '';
              this.shipping.postal_code = personalData.status.data.postal_code || '';
              this.shipping.address1 = personalData.status.data.address || '';
              this.shipping.address2 = '';
              this.shipping.phone = personalData.status.data.phone || '';
            }
             //TODO            
            this.email = this.$store.state.profileModule.userEmail || localStorage.getItem('email') || localStorage.getItem('tmp_email') ;
            this.confirmEmail = this.email;
        },
        priceForProducts() {
          let form = new FormData;
          form.append('action', 'dm_get_shape_data');
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then(response => {
            if (response && response.data) {
              this.shapes = response.data.shapes;
            }
          }).catch(error => {
            console.log(error);
          });
        },
        checkFields(fields, address) {
          let errors = false
          let formsFields = {
            'first_name': 'First Name',
            'last_name': 'Last Name',
            'country': 'Country',
            'state': 'State',
            'city': 'Town/City',
            'postal_code': 'Zip Code',
            'address1': 'Address Line 1',
            'phone': 'Phone',
            'confirmEmail': 'Confirm email',
            'email': 'Email'
          };
          for (let i in fields) {
            if (!fields[i] || fields[i].length == 0) {
              if (i == 'address2') {
                continue;
              }
              let message = `Field ${formsFields[i]} in ${address} address cannot be empty!`;
              this.$toast.add({severity:'error', summary: 'Error Message', detail: message, life: 4000});
              errors = true;
            }
          }
          return errors;
        },
        placeOrder() {
          let isIncorectShipping = this.checkFields(this.shipping, 'shipping');
          let isIncorectBolling = this.checkFields(this.billing, 'billing');
          if (isIncorectShipping || isIncorectBolling) {
            return false;
          }

          let form = new FormData;
          form.append('action', 'dm_check_order');
          form.append('order_id', this.orderID);
          form.append('email', this.email);
          form.append('fee', this.fee);
          form.append('shippingAddress', JSON.stringify(this.shipping));
          form.append('billingAddress', JSON.stringify(this.billing));
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            this.succesMessage = response.data.message;
            localStorage.removeItem('tmp_email');
          }).catch(error => {
            console.log(error);
          });
        },
        showSuccess(data) {
            if (data.status == 'OK'){
              this.$toast.add({severity:'success', summary: 'Success Message', detail: data.message, life: 3000});
            }
            if (data.status == 'Error') {
              this.$toast.add({severity:'warn', summary: 'Warn Message', detail: data.message, life: 3000});
            }
        },
        showError(data) {
            this.$toast.add({severity:'error', summary: 'Error Message', detail: data.message, life: 3000});
        },
        getTotalSum(products) {
          if (products.length) {
            this.subTotal = 0;
            let Summ = parseFloat(this.fee);
            for(var i=0; i<products.length; i++) {
              let pb = parseFloat(JSON.parse(products[i].price).back) >= 0 ? parseFloat(JSON.parse(products[i].price).back) : 0;
              this.subTotal += ((parseFloat(JSON.parse(products[i].price).front) + pb)).toFixed(2) * products[i].count;
            }
            this.totalPrice = Summ.toFixed(2)*1 + this.subTotal.toFixed(2)*1;
          }
        },
        getTotalItems(products) {
          if (products.length) {
            let Count = 0;
            for(var i=0; i<products.length; i++) {
              Count += (1*products[i].count);
            }
            this.totalItems = Math.floor(Count);
          }
        },
        // setCountProduct(event, id) {
        //   let form = new FormData;
        //   form.append('action' , 'dm_update_order_product');
        //   form.append('order_id', this.orderID);
        //   form.append('product_id', id);
        //   form.append('count', event.target.value);
        //   axios({
        //     method: 'post',
        //     url: this.$wpAjaxUrl,
        //     data: form
        //   }).then((response) => {
        //     if (response.data.status == 'OK') {
        //       this.getOrder();
        //       this.showSuccess(response.data);
        //     }
        //   }).catch(error => {
        //     console.log(error);
        //   });
        // },
    },
    async created () {
      //TODO
      let tmp_email = localStorage.getItem('tmp_email');
      if (tmp_email && this.$store.state.profileModule.userEmail) {
				let form = new FormData;
				form.append('customer_email', this.$store.state.profileModule.userEmail);
				form.append('tmp_email', tmp_email);
				form.append('action' , 'dm_set_order_email');
				axios({
					method: 'post',
					url: this.$wpAjaxUrl,
					data: form
				}).then(response => {
					if (response && response.data && response.data.status == 'OK') {
						localStorage.removeItem('tmp_email');
					}
				}).catch(error => {
					console.log(error);
				});
			}
      // let isGuest = localStorage.getItem('isGuest');
      // let isLoggedIn = this.$store.state.profileModule.isLoggedIn;
      
      // if (this.$route.meta.noAuthRequired && !isGuest && !isLoggedIn) {
      //   return;
      // }
      // let isValid = await this.$store.dispatch('checkLoginState');
      // if (!isValid && !isGuest && !isLoggedIn) {
      //   this.$router.push({
      //     name: 'login'
      //   });
      // }
    },
    mounted() {
      this.getShippingFee();
        this.getCountries();
        this.getZoon();
        this.getPersonalInfo();
        this.getOrder();
        this.priceForProducts();
        
    }
  }
  </script>
  
  <style scoped>
   h1{
	margin-left: 10px;
	font-size: 27px;
  font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  .params-text{
    width: 75px;
    font-size: 16px;
  }
  .delete-button{
    cursor: pointer;
  }
  button.cart-link{
    color: white;
  }
 .button-position{
    text-align: right;
  }
  .total{
    width: 200px;
    height: 40px;
    margin: 10px 0;
  } 
  .left-block{
    text-align: left;
    flex: 3;
    margin-left: auto;
    padding-left: 30px;
  } 
  .left-block>a{
    margin-right: 20px;
  }
  .left-block>a>button,.left-block>button,.result-block>button{
    background-color: #d65d06;
    text-transform: uppercase;
  }
  .left-block>span{
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 20px;
  }
  .result-block{
    text-align: left;
    flex: 1;
    margin-right: auto;
  }
  .result-block{
    display:flex;
  }
  .tottal-result{    
    text-align: left;
    display:flex;
    margin-left: auto;
  }
  .coupon-block{
    width: 200px;
    height: 40px;
    text-align: left;
    display:flex;
    margin-right: auto;
    justify-content: space-between;
  }
  .result-block>span{
    color: red;
    font-weight: bold;
  }
  .buttons-block,h2,h3{
    text-align: center;
  }
  h3{
    margin-top: 70px;
    margin-bottom: 50px;
  }
  h2{
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .buttons-block{
    margin-bottom: 50px;
  }
  .buttons-block>a{
    margin: 0 30px;
  }
  .buttons-block>a>button.p-button{
    background-color: #ed640d;
  }
  input,input:focus{
    border: 1px solid #c3b8b8 !important;
  }
  .describe-params{
    text-align: left;
  }
  .p-inputtext{
    padding: 8px !important;
  }
  </style>
  