<template>
     <div v-if="loading" class="preloader">
        <ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
    </div>
	<div class="main" id="editor" v-if="titlePage">
		<div class="header-block">
			<h1>{{ titlePage }}</h1>
		</div>
    <div class="content-accordion">
		<div class="conten-page-top" v-html="contentPage"></div>
    </div>
	</div>
</template>  
<script>
import axios from 'axios';


export default {
  name: 'PostPage',
  components:{

  },
  data() {
    return {
      titlePage: '',
      contentPage: '',
      loading: false
    }
  },
  methods: {
      getDataPage(id) {
          this.loading = true;
          let form = new FormData;
          form.append('action', 'dm_get_post');
          form.append('post_id', id);
          axios({
              method: 'post',
              url: this.$wpAjaxUrl,
              data: form
          }).then(response => {
              this.loading = false;
              if (response.data && response.data.status == 'OK') {
                  this.titlePage = response.data.post.post_title;
                  this.contentPage = response.data.post.post_content;
              } else {
                  this.$router.push('/404');
              }
          }).catch(error => {
              console.log(error);
          });
      }
  },
  created () {
    this.getDataPage(this.$route.params.id);
  },
  beforeRouteUpdate (to, from, next) {
    this.getDataPage(to.params.id);
    next();
  }
}
</script>  
<style scoped>
  h1{
  margin-top: 20px;
  font-family: Oswald;
  font-size: 26px;
}
#editor{
display:block;
margin-top: 21px;
}
.conten-page-top{

  margin-bottom: 20px;
}
.content-accordion{
  background-color: white;
  padding: 0 20px 20px 20px;
}
.heading-params{
  color: red;
}
.content-accordion{
    padding-top: 10px;
}
.p-card .p-card-body{
    margin-bottom: 10px !important;
}

</style>
  