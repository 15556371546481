<template>
    	<div class="main" id="editor"></div>
    	<div class="header-block">
			<h1>Registration</h1>
		</div>
		<div class="cards">
            <div class="loginBox">
                <div class="formBox">
                    <strong>Create Your Noontime Account</strong>
                    <div class="msg"></div>
                    <span>Fields marked with <label class="redStar">*</label> are required</span>
                    <InputText class="mr-1 w-5" v-model="first_name" placeholder="First Name *" />
                    <InputText class="ml-1 w-5" v-model="last_name" placeholder="Last Name *"/>
                    <InputText class="mr-1 mt-1 w-5" v-model="user_email" placeholder="Email *" />
                    <InputText class="ml-1 mt-1 w-5" v-model="user_email_confirm" placeholder="Confirm Email Address *"/>
                    <InputText type="password" class="mr-1 mt-1 w-5" v-model="user_pass" placeholder="Password *" />
                    <InputText type="password" class="ml-1 mt-1 w-5" v-model="user_pass_confirm" placeholder="Confirm Password *"/>
                    <Button class="mt-2 submitBtn w-5"  label="Submit" @click="register"/>					
                </div>
                <div class="alreadyaccount mt-2">				
                    If you already have an account <a @click="$router.push('/login')" class="">click here</a>
                    <p>IMPORTANT: Once you click submit, we will send you a confirmation email. You just need to click the link provided in that email to open your account. Thank you.</p>
                </div>
            </div>
        </div>
        <Toast />
</template>
<script>
export default {
  name: 'PasswordRegisterPage',
  data() {
    return {
        first_name: '',
        last_name: '',
        user_email: '',
        user_email_confirm: '',
        user_pass: '',
        user_pass_confirm: ''
    }    
  },
  methods: {
    async register(){
        const response = await this.$store.dispatch('register', {
            first_name: this.first_name,
            last_name: this.last_name,
            user_email: this.user_email,
            user_email_confirm: this.user_email_confirm,
            user_pass: this.user_pass,
            user_pass_confirm: this.user_pass_confirm,
            timestamp: new Date().getTime(),
        });
        if (response && response.status && response.status.type) {
            if (response.status.type == 'Success') {
                this.$toast.add({severity: 'success', summary: 'Success Message', detail: `${response.status.message}`, life: 5000 });
                this.$router.push({
                    name: 'login'
                });
            }
            if (response.status.type == 'Error') {
                this.$toast.add({severity: 'error', summary: 'Error Message', detail: `${response.status.message}`, life: 3000 });
            }
        }
},
// async getRegisterNonce() {
//     const response = await this.$store.dispatch('registerNonce');
//     return response.data;
//   },

// Backup any filled data so it's not empty if you refresh the page
backupFilledData() {
  sessionStorage.setItem('registerForm', JSON.stringify(fields.value));
}
  }
}
</script>
<style >
.redStar{
    color: red;
}
h1{
    margin-top: 20px;
    font-family: Oswald;
    font-size: 26px;
}
.loginHd {
    color: #272727;
    font-family: oswaldbook;
    font-size: 26px;
    text-align: center;
    padding: 30px 0;
}
.loginBox {
    background: #fff;
    width: 100%;
    max-width: 734px;
    padding: 20px;
    min-height: 200px;
    margin: auto;
    padding-bottom: 50px;
}
.returningCustomer {
    width: 50%;
    float: left;
    padding-right: 50px;
}
.retCustHd {
    position: relative;
    padding-left: 36px;
}
.returingCutIcon {
    width: 28px;
    height: 32px;
    text-align: left;
    background: url('../../../public/images/template/returning_customer_icon.png') no-repeat center center;
    display: block;
    position: absolute;
    left: 0;
    top: 6px;
}
i {
    font-style: italic;
}
.loginBox strong {
    color: #000;
    text-transform: uppercase;
    font-size: 18px;
    font-family: open_sanssemibold;
    display: block;
}
b, strong {
    font-weight: 700;
}
strong {
    font-weight: bold;
}
.loginBox span {
    color: #64676b;
    text-transform: inherit;
    font-size: 14px;
    display: block;
}
.loginbxLeft {
    margin-top: 20px;
    position: relative;
}
form {
    display: block;
    margin-top: 0em;
}
ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
}
ul {
    padding: 0;
    margin: 0;
}
ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.loginbxLeft ul li {
    margin-top: 15px;
}
li {
    list-style: none;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
ul {
    list-style-type: disc;
}
.loginbxLeft ul li {
    margin-top: 15px;
}
.checkLi {
    margin-top: 8px!important;
}
button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
input {
    line-height: normal;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font: inherit;
    color: inherit;
}
input {
    outline: none;
    transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    outline: none;
}
input[type="hidden" i] {
    appearance: none;
    background-color: initial;
    cursor: default;
    display: none !important;
    padding: initial;
    border: initial;
}
input {
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}
.inputBox {
    border: 1px solid #c3b8b8 !important;
    color: #9a9aba;
    display: block;
    font-family: open_sansregular;
    font-size: 13px;
    font-weight: normal;
    padding: 8px;
    width: 45%;
}
label.error {
    color: red;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}
label {
    cursor: default;
}
input[type=checkbox], input[type=radio] {
    margin: 4px 0 0;
    margin-top: 1px\9;
    line-height: normal;
}
input[type=checkbox], input[type=radio] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
.checkbox, .radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}
.checkbox {
    float: left;
}
.checkLi > span {
    float: left;
    font-size: 12px;
    margin: 3px 3px;
    color: #ababab;
}
.checkLi a {
    color: #ed640d;
    font-size: 13px;
    float: right;
    font-family: open_sanssemibold;
    margin-top: -2px;
}
a {
    color: #337ab7;
    text-decoration: none;
}
a {
    background-color: transparent;
}
a {
    text-decoration: none;
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    outline: none;
}
a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}
.clearfix {
    clear: both;
}
button, html input[type=button], input[type=reset], input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
}
.submitBtn {
    background: #ed640d;
    color: #fff;
    font-size: 14px;
    font-family: open_sanssemibold;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    box-shadow: 0 0 3px #ddd inset;
    width: 100%;
    padding: 6px 0;
    border-radius: 3px;
    border: 1px solid #dc6b00;
}
.rightLine {
    background: #dde2e5 none repeat scroll 0 0;
    height: 100%;
    position: absolute;
    right: -50px;
    top: 0;
    width: 1px;
}
.newCusromer {
    width: 50%;
    float: right;
    padding-left: 35px;
}
.newCustHd {
    position: relative;
    padding-left: 39px;
}
.newCusticon {
    width: 35px;
    height: 35px;
    text-align: left;
    background: url('../../../public/images/template/new_custome.png') no-repeat center center;
    display: block;
    position: absolute;
    left: 0;
    top: 4px;
}
.loginbxRight .btn {
    background: url('../../../public/images/template/loginBtnsback.png') repeat-x;
    width: 240px;
    border: 1px solid #737b82;
    border-radius: 3px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    display: block;
    margin: 20px auto;
}
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
a {
    color: #337ab7;
    text-decoration: none;
}
a {
    background-color: transparent;
}
a {
    text-decoration: none;
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    outline: none;
  }
.loginbxRight > span {
    border: 1px solid #c8c8c8;
    border-radius: 100%;
    display: block;
    font-size: 12px;
    height: 30px;
    line-height: 26px;
    margin: 22px auto;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
}
input{
    border: 1px solid black !important;
    /* width: 45% !important; */
}
.submitBtn{
    /* width: 45% !important; */
    background: #ed640d !important;
}
.submitBtn>span{
    color: #fff !important;
}
.alreadyaccount{
    color: #ababab;
    font-size: 14px;
    font-style: italic;
    text-align: center;
    text-transform: inherit;
}
.alreadyaccount a {
    color: #ed640d;
    /* font-family: open_sanssemibold; */
}
.p-inputtext{
  padding: 8px !important;
}
</style>