<template>
	<div class="main" id="editor">
		<div class="header-block">
			<h1>My saved Labels</h1>
		</div>
		<div class="card">
      <!-- <Sketch v-model="colors"></Sketch> -->
<!-- <ColorPicker></ColorPicker> -->

    </div>
	</div>
</template>
  
  <script>
  //   import 'vue-color-kit/dist/vue-color-kit.css'
  // import { ColorPicker } from 'vue-color-kit'
  import { Sketch } from '@ckpack/vue-color';
  export default {
    name: 'LabelsPage',
    components: {
      Sketch
      // ColorPicker
    },
    data() {
      return {
        colors: '#fafafa'
      }
    },
    methods: {
    
    }
  }
  </script>
  
  <style scoped>
   h1{
	margin-left: 10px;
	font-size: 27px;
  font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  </style>
  