<template>
	<div>
        <Dialog header="Images" v-model:visible="this.displayPopUp" :breakpoints="{'960px': '75vw', '640px': '90vw'}" style="text-align: center;" :style="{width: '30vw'}">
			<Button label="Success" class="p-button-rounded p-button-succes mr-20"  @click="showUploadImages">Upload Image(s)</Button>
			<span>OR</span>
			<Button label="Info" class="p-button-rounded p-button-info ml-20" @click="showImagesGallery">Browse Gallery</Button>
		</Dialog>
	</div>
</template>

<script>
export default {
	name: "ImagesCollection",
	data() {
		return {
			displayPopUp: false
		}
	},
	methods: {
		openPopUp() {
            this.displayPopUp = true;
        },
		showUploadImages() {
			this.emitter.emit('showUploadImages');
			this.displayPopUp = false;
		},
		showImagesGallery() {
			this.emitter.emit('showImagesGallery');
			this.displayPopUp = false;
		}
	}
}
</script>
<style scoped>
.ml-20{
	margin-left: 20px;
}
.mr-20{
	margin-right: 20px;
}
span{
	line-height: 2.5;
}
</style>