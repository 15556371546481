<template>
    <!-- <div
        class="actions-buttons"
        :style="{ left: (this.choosedLabel.x) + 'px', top: (this.choosedLabel.y - (this.choosedLabel.is_text ? 30 : 0) ) + 'px', display: this.ativeLabel.show || 'none' }"> -->
        <div
        class="actions-buttons"
        :style="{ left: this.choosedLabel.left, top: this.choosedLabel.top, display: this.ativeLabel.show || 'none' }">
           
        <div v-if="this.choosedLabel.is_text">
            <div
                v-if="isCurevedText"
                class="popup-field"
                :style="{display: this.isVisible ? 'block' : 'none'}"
                >
                <InputText
                    type="text"
                    class="curved-text-field"
                    v-model="curvedText"
                    @keyup="setNewText"/>
            </div>
            <Button id="edit-button" @click="editText">Edit text</Button>
        </div>
        <Button @click="showDeleteInfo" id="dell-button"><font-awesome-icon :icon="icons.trash"  v-tooltip.bottom="'Delete selected'" /></Button>
        <Button @click="setCenterPosition" id="center-button"><img :src="`${this.pathUrl}/images/icons/center.svg`" style="width: 17px; height: 18px;" /></Button>
    </div>
</template>
<script>
import { faTrashAlt, faAlignCenter } from '@fortawesome/free-solid-svg-icons';
export default {
    name: "layerActions",
    props: ['choosedLabel'],
    data() {
        return {
            ativeLabel: this.choosedLabel,
            placements: ['bottom'],
            icons: {
                trash: faTrashAlt,
                center: faAlignCenter
            },
            isVisible: false,
            isText: false,
            isCurevedText: false,
            curvedText: '',
            additionalWidth: 0,
            addX: 20,
            addY: 50
        }
    },
    methods: {
        showDeleteInfo() {
            let side = this.$store.state.canvas.$side;
            let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
            const selectedLayer = canvas.getActiveObject();
            if (selectedLayer) { 
                this.$confirm.require({
                    message: 'Do you want to delete this label?',
                    header: 'Delete Confirmation',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.emitter.emit('deleteObjects', {layer: selectedLayer});
                        this.$toast.add({severity:'info', summary:'Confirmed', detail:'Layer deleted', life: 3000});
                        this.$emit('renderPreview');
                    },
                    reject: () => {
                        this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                    }
                });
            }
		},
        setCenterPosition() {
            let side = this.$store.state.canvas.$side;
            let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
            const selectedLayer = canvas.getActiveObject();
            if (selectedLayer) {
                canvas.centerObjectH(selectedLayer);                
                let params = this.getObjectParams(selectedLayer);
                if (params) {
                    this.emitter.emit('updateHistory', true);
                    canvas.renderAll();
                }
                //this.ativeLabel.show = 'none';
                this.emitter.emit('showActionBlock');
            }
        },
        isActiveText(data) {
            this.isText = data.isText || false;
            this.isCurevedText =  data.isCurvedText || false;
            this.curvedText = data.text || '';
        },
        editText() {
            let side = this.$store.state.canvas.$side;
            let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
            let elem = canvas.getActiveObject();
            if (elem) {
                if (elem.type == 'text-curved') {
                    this.isVisible = !this.isVisible;
                } else {
                    elem.enterEditing();
                    elem.hiddenTextarea.focus();
                }
            }
        },
        setNewText() {
            let side = this.$store.state.canvas.$side;
            let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
            let elem = canvas.getActiveObject();
			if (elem) {
                let text = this.curvedText || '.';
                elem.set({text: text});
                canvas.renderAll();
                this.emitter.emit('updateHistory');
                this.emitter.emit('layersRender');
            }
        }
    },
    mounted() {
       this.emitter.on('isActiveText', (data) => this.isActiveText(data));
       this.emitter.on('showDeleteInfo', () => this.showDeleteInfo());
    }
}
</script>
<style scoped>
    .actions-buttons {
        position: absolute;
        /* top: 10px;
        left: 10px; */
        z-index: 999;
        pointer-events: auto;
        background: #4b4b4b;
        padding: 3px 5px;
        border-radius: 5px;
        width: 105px;
    }
    .popup-field{
        position: absolute;
        z-index: 101;
        background: #4b4b4b;
        top: -42px;
        left: -50px;
        border: 4px solid black;
        }
    #edit-field,#edit-button,#center-button,#dell-button{
        background: none;
        border: none;
    }
    .curved-text-field{
        height: 35px;
    }
</style>