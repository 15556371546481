<template>
<section>
    <div v-if="loading" class="preloader">
        <ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
    </div>
    <div class="main" id="editor">
      <div class="header-block">
        <Toast />
        <h1>Cart</h1>
      </div>
      <div class="card">
        <div v-if="succesMessage">
          <h3>Thank you!!!</h3>
          <h3>{{ succesMessage }}</h3>
          <div class="buttons-block">
            <router-link :to="'/'">
                <Button class="cart-link">Home</Button>
            </router-link>
            <!-- <router-link :to="'/editor'">
                <Button class="cart-link">Create new Label</Button>
            </router-link> -->
            <router-link :to="'/categories'">
                <Button class="cart-link">Continue shoping</Button>
            </router-link>
          </div>
        </div>
        <div v-else-if="products.length == 0">
          <h2>Your cart is empty!</h2>
          <div class="buttons-block">
            <!-- <router-link :to="'/editor'">
                <Button class="cart-link">Create new Label</Button>
            </router-link> -->
            <router-link :to="'/categories'">
                <Button class="cart-link">Continue shoping</Button>
            </router-link>
        </div>
        </div>
        <div v-else>
          <DataView :value="products">
              <template #list="slotProps">
                  <div class="col-12">
                      <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                          <div class="flex flex-column">
                          <div v-if="slotProps.data.product_image_front" class="flex align-items-center justify-content-center h-10rem m-2">
                            <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" :src="`${this.pathPluginImage}/orders/${slotProps.data.product_image_front}`" :alt="slotProps.data.product_name" />
                          </div>
                          <div v-if="slotProps.data.product_image_back" class="flex align-items-center justify-content-center h-10rem m-2">
                            <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" :src="`${this.pathPluginImage}/orders/${slotProps.data.product_image_back}`" :alt="slotProps.data.product_name" />
                          </div>
                        </div>
                          <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                              <div class="flex flex-column align-items-center sm:align-items-start gap-3">
                                  <div class="text-2xl font-bold text-900">{{ slotProps.data.product_name }}</div>
                                  <div class="flex align-items-center gap-5">
                                      <div class="flex align-items-center gap-3">
                                        <div class="flex flex-column">
                                        <div v-if="slotProps.data.product_image_front" class="flex align-items-center justify-content-center h-10rem m-2">
                                          <div class="describe-params mr-1"><b>Label size:</b> {{ JSON.parse(slotProps.data.size).front.width }}"&times;{{ JSON.parse(slotProps.data.size).front.height }}" inches</div>
                                          <div class="describe-params ml-1"><b>Label form:</b> "{{ JSON.parse(slotProps.data.preset).front.shape }}"</div>
                                        </div>
                                        <div v-if="slotProps.data.product_image_back" class="flex align-items-center justify-content-center h-10rem m-2">
                                          <div class="describe-params mr-1"><b>Label size:</b> {{ JSON.parse(slotProps.data.size).back.width }}"&times;{{ JSON.parse(slotProps.data.size).back.height }}" inches</div>
                                          <div class="describe-params ml-1"><b>Label form:</b> "{{ JSON.parse(slotProps.data.preset).back.shape }}"</div>
                                        </div>
                                      </div>
                                          </div>    
                                          <!-- <div class="flex flex-wrap gap-2">
                                          <div class="flex align-items-center">
                                              <input
                                                type="radio"
                                                @click="setGlossType(slotProps.data.id)"
                                                :id="'glossy' + slotProps.data.id"
                                                :name="`label_type${slotProps.data.id}`"
                                                :checked="slotProps.data.material_type == 'glossy'"/>
                                              <label :for="'glossy' + slotProps.data.id" class="ml-2"><b>Glossy</b> - Laminated</label>
                                          </div>
                                          <div class="flex align-items-center">
                                              <input type="radio"
                                                @click="setMatteType(slotProps.data.id)"
                                                :id="'matte' + slotProps.data.id"
                                                :name="`label_type${slotProps.data.id}`"
                                                :checked="slotProps.data.material_type == 'matte'"/>
                                              <label :for="'matte' + slotProps.data.id" class="ml-2"><b>Matte</b> - Laminated</label>
                                          </div>
                                          </div> -->
                                  </div>
                              </div>
                              <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                              </div>
                              </div>
                              <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                  <span class="text-2xl font-semibold">
                                    <input 
                                      type="number"
                                      class="params-text"
                                      @change="setCountProduct($event, slotProps.data.id)"
                                      v-model="slotProps.data.count"/>
                                  </span>
                                  <span>
                                    <span v-if="JSON.parse(slotProps.data.price).front && !JSON.parse(slotProps.data.price).back">${{ JSON.parse(slotProps.data.price).front }}</span>
                                    <span v-if="JSON.parse(slotProps.data.price).front && JSON.parse(slotProps.data.price).back">${{ JSON.parse(slotProps.data.price).front }} + {{ JSON.parse(slotProps.data.price).back }}</span>
                                    <span v-if="JSON.parse(slotProps.data.price).back && !JSON.parse(slotProps.data.price).front">${{ JSON.parse(slotProps.data.price).back }}</span>
                                    <span v-if="!JSON.parse(slotProps.data.price).front && !JSON.parse(slotProps.data.price).back">$0</span> / Label</span>
                              </div>
                              <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                  <span class="text-2xl font-semibold">${{ ((parseFloat(JSON.parse(slotProps.data.price).front) + parseFloat(JSON.parse(slotProps.data.price).back)) * slotProps.data.count).toFixed(2) }}</span>
                              </div>
                               <!-- <i class="pi pi-pencil delete-button" style="font-size: 1rem"  @click="$router.push('/editor/' + slotProps.data.product_slug + '/order-product-edit/' + orderID)"></i> -->
                              <i class="pi pi-trash delete-button" style="font-size: 1rem" @click="confirmDelete(slotProps.data.id)"></i>
                          </div>
                     </div>
              </template>
          </DataView>
            <div class="grid">
              <div class="col-8 grid">
                <!-- <div class="col-12">              
                  <span>HAVE A COUPON?</span>
                  <InputText placeholder="Enter Your Coupon Code" /><Button label="Apply" />
                </div>      -->
                <div class="col-12 ml-3 mt-3">
                  <label for="comment">Comment</label>
                  <Textarea id="comment" class="ml-2" v-model="comment" rows="3" cols="70"/>
                </div>
                <div class="col-12 ml-3">
                  <!-- <router-link :to="'/editor'">
                    <Button class="cart-link bg-orange-500 mr-1">Create new Label</Button>
                  </router-link> -->
                  <router-link :to="'/categories'">
                    <Button class="cart-link bg-orange-500 ml-1">Continue shoping</Button>
                  </router-link>
                </div>
              </div>
              <div class="col-4 mb-2">
                <div class="total result-block">
                  Total items: {{ totalItems }}
                </div>
                <div class="total result-block">
                  Sub-Total: ${{ subTotal.toFixed(2) }} (USD)
                </div>
                <div class="total result-block">
                  Shipping fee: ${{ fee }} (USD)
                </div>
                <div class="total result-block">
                  <b>Total:</b>&nbsp;<span>${{ parseFloat(fee) + parseFloat(totalPrice)  }} (USD)</span>
                </div>
                <Button class="bg-orange-500" @click="checkout">
                  Place order
                </Button>
              </div>
            </div>
          </div>
      </div>
      <ConfirmDialog></ConfirmDialog>
    </div>
     <Dialog
        v-model:visible="showPopUp"
        modal        
        :style="{ width: '40vw' }">
        <div class="grid">
      <div class="col-5">
        <Button class="w-full bg-orange-500"  @click="loginForm" label="Login" />
      </div>
      <div class="col-2 text-center">OR</div>
      <div class="col-5">
        <Button class="w-full bg-orange-500" @click="loginGuest" label="Checkout as Guest" />
      </div>
    </div>
    </Dialog>
    <Dialog
        header="Guest User"
        v-model:visible="loginAsGuest"
        modal        
        :style="{ width: '35vw' }">
        <div class="grid">
      <div class="col-12">
        <Message v-if="isMessage" :severity="typeMessage" sticky>{{ message }}</Message>
        <InputText
          type="text"
          class="guest-email text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
          placeholder="Enter e-mail"
          v-model="guestEmail"/>
      </div>      
      <div class="col-12">
        <Button class="w-full bg-orange-500" @click="guestLogin" label="Submit" />
      </div>
      </div>
   
    </Dialog>
    </section>
  </template>  
<script>
import DataView from 'primevue/dataview';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Message from 'primevue/message'
import 'primeflex/primeflex.css';
import axios from 'axios';
  export default {
    name: 'CartPage',
    components:{
        DataView,
        RadioButton,
        Textarea,
        Message
    },
    data() {
      return {
        isMessage: null,
        showPopUp: false,
        loginAsGuest: false,
        message: '',
        products: [],
        orderID: 0,
        succesMessage: null,
        totalPrice: 0,
        totalItems: 0,
        loading: false,
        shapes: null,
        comment: '',
        shapeSize: {},
        guestEmail: null,
        fee: 0,
        subTotal: 0
      }
    },
    methods: {
      getShippingFee() {
        let form = new FormData;      
        form.append('action', 'dm_get_shipping_fee');
        axios({
          method: 'post',
          url: this.$wpAjaxUrl,
          data: form
        }).then(response => {
          if (response && response.data.status == 'Ok') {
            this.fee = response.data.fee;
          }
        }).catch(error => {
          console.log(error);
        });
      },
        loginForm() {
          this.$router.push('/login');
          localStorage.setItem('activeCart', 1)
        },
        loginGuest() {
          this.showPopUp = false,
          this.loginAsGuest = true;
        },
        async guestLogin() {
          let response = await this.$store.dispatch('checkUser', {
            userEmail: this.guestEmail
          });
          if (response && response.status == 200) {
            this.isMessage = true;
            this.typeMessage = 'warn';
            this.message = 'User Already exist please login first!!!'
          }
          if (response && response.status == 400) {
            this.isMessage = true;
            this.typeMessage = 'error';
            this.message = 'You entered incorrect e-mail!!!'
          }
          this.updateEmail();
          this.loginAsGuest = false;
        },
        updateEmail() {
          let tmp_email = localStorage.getItem('tmp_email');
          if (tmp_email && this.$store.state.profileModule.userEmail) {
            let form = new FormData;
            form.append('customer_email', this.$store.state.profileModule.userEmail);
            form.append('tmp_email', tmp_email);
            form.append('action' , 'dm_set_order_email');
            axios({
              method: 'post',
              url: this.$wpAjaxUrl,
              data: form
            }).then(response => {
              localStorage.removeItem('tmp_email');
              if (response && response.data.status == 'OK') {
                localStorage.removeItem('tmp_email');
              }
            }).catch(error => {
              console.log(error);
            });
          }
        },
        getOrder() {
          this.updateEmail();     
          this.loading = true;
          let form = new FormData;
          form.append('action' , 'dm_get_order');
          //TODO need check email and only for this email get data
          // let isGuest = localStorage.getItem('isGuest');
          let guestEmail = localStorage.getItem('tmp_email');
          // let orderID = localStorage.getItem('order') || 0;
          // form.append('orderID', orderID);
          form.append('customer_email', this.$store.state.profileModule.userEmail ? this.$store.state.profileModule.userEmail : guestEmail);
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            this.loading = false;
            if (response && response.data && response.data.result && response.data.result.length) {
              this.orderID = response.data.orderID;
              this.comment = response.data.comment ? response.data.comment : '';
              this.products = response.data.result;
              this.getTotalSum(this.products);
              this.getTotalItems(this.products);
            } else {
              this.products = [];
            }
          }).catch(error => {
            console.log(error);
          });
        },
        priceForProducts() {
          let form = new FormData;
          form.append('action', 'dm_get_shape_data');
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then(response => {
            if (response && response.data) {
                this.shapes = response.data.shapes;               
            }
          }).catch(error => {
            console.log(error);
          });
        },
        checkout() {
          if (!this.$store.state.profileModule.userEmail && !localStorage.getItem('email')) {
              this.showPopUp = true;
          } else {
           //TODO need check email and only for this email get data
          let guestEmail = localStorage.getItem('tmp_email') ||  localStorage.getItem('email');
          let form = new FormData;
          form.append('action' , 'dm_update_order_comment');
          form.append('orderID', this.orderID);
          form.append('orderComment', this.comment);
          form.append('customer_email', this.$store.state.profileModule.userEmail || guestEmail);
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            if (response.data.status && response.data.status == 'OK') {
              this.$router.push('/shoping-cart/checkout');
            } else {
              this.$toast.add({severity:'warn', summary: 'Warn Message', detail: 'Something went wrong! Try againe later!', life: 3000});
            }              
          }).catch(error => {
            console.log(error);
          });
        }
        },       
        showSuccess(data) {
            if (data.status == 'OK'){
                this.$toast.add({severity:'success', summary: 'Success Message', detail: data.message, life: 3000});
            }
            if (data.status == 'Error') {
                this.$toast.add({severity:'warn', summary: 'Warn Message', detail: data.message, life: 3000});
            }
        },
        showError(data) {
            this.$toast.add({severity:'error', summary: 'Error Message', detail: data.message, life: 3000});
        },
        getTotalSum(products) {
          if (products.length) {
            this.subTotal = 0;
            // let Summ = parseFloat(this.fee);
            for(var i=0; i<products.length; i++) {
              let pb = parseFloat(JSON.parse(products[i].price).back) >= 0 ? parseFloat(JSON.parse(products[i].price).back) : 0;
              this.subTotal += ((parseFloat(JSON.parse(products[i].price).front) + pb)).toFixed(2) * products[i].count;
            }
            this.totalPrice = parseFloat(this.subTotal.toFixed(2));
          }
        },
        getTotalItems(products) {
          if (products.length) {
            let Count = 0;
            for(var i=0; i<products.length; i++) {
              Count += (1*products[i].count);
            }
            this.totalItems = Math.floor(Count);
          }
        },
        setCountProduct(event, id) {
          let form = new FormData;
          form.append('action' , 'dm_update_order_product');
          form.append('order_id', this.orderID);
          form.append('product_id', id);
          /**
           * Need to add the token 
           */
          let guestEmail = localStorage.getItem('tmp_email');
          let email = this.$store.state.profileModule.userEmail ? this.$store.state.profileModule.userEmail : guestEmail
          form.append('customer_email', email);
          form.append('count', event.target.value);

          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            if (response.data.status == 'OK') {
              this.getOrder();
              this.showSuccess(response.data);
            }
          }).catch(error => {
            console.log(error);
          });
        },
        confirmDelete(data) {
            this.$confirm.require({
                message: 'Do you want to delete this product from cart?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
					          this.deleteProduct(data);
                    // this.$toast.add({severity:'info', summary:'Confirmed', detail:'Te product deleted', life: 3000});
                    this.getOrder();
                },
                reject: () => {
                    this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        deleteProduct(productID) {
          let form = new FormData;
          form.append('action' , 'dm_delete_order_product');
          form.append('order_id', this.orderID);
          form.append('product_id', productID);
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            if (response.data.status == 'OK') {             
              this.getOrder();
            }
            this.showSuccess(response.data);
          }).catch(error => {
            console.log(error);
          });
        }
    },
    async created () {
     
        // if (this.$route.meta.noAuthRequired) {
        //   return;
        // }
        // let isValid = await this.$store.dispatch('checkLoginState');
        // let isGuest = localStorage.getItem('isGuest');
       
        // if (!isValid && !isGuest) {
        //   this.$router.push({
        //     name: 'login'
        //   });
        // }
          },
    mounted() {
      this.getShippingFee();
      this.getOrder();
      this.priceForProducts();
    },
    computed: { 
       
    }
  }
  </script>
  
  <style scoped>
   h1{
	margin-left: 10px;
	font-size: 27px;
  font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  .params-text{
    width: 75px;
    font-size: 16px;
  }
  .delete-button{
    cursor: pointer;
  }
  button.cart-link{
    color: white;
  }
 .button-position{
    text-align: right;
  }
  .total{
    width: 200px;
    height: 40px;
    margin: 10px 0;
  } 
  .left-block{
    text-align: left;
    flex: 3;
    margin-left: auto;
    padding-left: 30px;
  } 
  .left-block>a{
    margin-right: 20px;
  }
  .left-block>a>button,.left-block>button,.result-block>button{
    background-color: #d65d06;
    text-transform: uppercase;
  }
  .left-block>span{
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 20px;
  }
  .result-block{
    text-align: left;
    flex: 1;
    margin-right: auto;
  }
  .result-block{
    display:flex;
  }
  .tottal-result{    
    text-align: left;
    display:flex;
    margin-left: auto;
  }
  .coupon-block{
    width: 200px;
    height: 40px;
    text-align: left;
    display:flex;
    margin-right: auto;
    justify-content: space-between;
  }
  .result-block>span{
    color: red;
    font-weight: bold;
  }
  .buttons-block,h2,h3{
    text-align: center;
  }
  h3{
    margin-top: 70px;
    margin-bottom: 50px;
  }
  h2{
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .buttons-block{
    margin-bottom: 50px;
  }
  .buttons-block>a{
    margin: 0 30px;
  }
  .buttons-block>a>button.p-button{
    background-color: #ed640d;
  }
  input,input:focus{
    border: 1px solid #c3b8b8 !important;
  }
  .describe-params{
    text-align: left;
  }
  input.guest-email{
    padding: 7px !important;
  }
  </style>
  