<template>
    <section>
    <div class="container-frame ">
        <div class="grid">
            <div class="col-7">
                <div class="text-left font-bold">                
                    <span class="text-pink-700 text-2xl">{{ this.$store.state.order.productName || 'Custom label' }}</span>                
                </div>
            </div>
            <div class="col-5">
                <div class="text-right font-bold">
                    <dl class="price-holder" data-csymbol="$">
                        <dt>Price:</dt>
                        <dd>
                            <span class="numbers">
                                <span class="price-value" v-if="this.$store.state.order.statusIncludeBackLabel">${{ frontPriceProduct }} + ${{ backPriceProduct }} = ${{ (parseFloat(frontPriceProduct)+parseFloat(backPriceProduct)).toFixed(2) }}</span>
                                <span class="price-value" v-if="!this.$store.state.order.statusIncludeBackLabel">${{ frontPriceProduct }} </span>
                                <!-- <span class="price-value" v-if="showBackShapePrice && !showFrontShapePrice">${{ backPriceProduct }} </span>
                                <span class="price-value" v-if="!showBackShapePrice && !showFrontShapePrice">$0</span> -->
                            </span>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="grid p-0">
            <div class="col-8 p-0">
                <div class="font-bold">
                    <Button
                        v-if="this.$store.state.profileModule.isLoggedIn && !this.$store.state.profileModule.isAdmin && !this.is_admin_edit"
                        class="control mr-1"
                        variant="light"
                        @click="loadLabelsList"
                        severity="secondary"
                        text
                        raised
                        rounded>
                            <span class=" text-color"><font-awesome-icon :icon="icons.upload"/>&nbsp;&nbsp;My Saved Labels</span>
                    </Button>
                    <Button
                        v-if="this.$store.state.profileModule.isLoggedIn && !this.$store.state.profileModule.isAdmin && !this.is_admin_edit"
                        class="control ml-1"
                        variant="light"
                        @click="saveLabelModal"
                        severity="secondary"
                        text
                        raised
                        rounded>
                        <span class="text-color"><font-awesome-icon :icon="icons.faSave"/>&nbsp;&nbsp;Save My label</span>
                    </Button>
                </div>
            </div>
            <div class="col-4 grid pt-1">
                <div class="col-5 font-bold p-0">
                    <InputNumber 
                        :min="+minValueCount"
                        v-model="countProduct"
                        @click="setCountProduct"
                        inputId="count-buttons"
                        showButtons/>&nbsp;
                </div>
                <div class="col-6 p-0">
                    <button type="button"                        
                        @mousedown="addToCartButton()"
                        v-bind:disabled='isDisabled'
                        class="submit submit-button"
                        id="add-to-cart"
                        data-tippy-content="Save your current design to your shopping cart."
                        data-tippy-placement="top">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                        Add To Cart
                    </button>
                </div>
            </div>
        </div>
    </div>
    <Dialog
        v-model:visible="addShapePopUp"
        modal header="Add shape"
        :style="{ width: '25vw' }"
        >
        <h6><i class="pi pi-info-circle" style="font-size: 1rem"></i> Your design is empty. Please add some shapes before adding to your cart.</h6>
        <template #footer>
            <Button label="Ok" type="button" @click="addShapePopUp = false"/>
        </template>
    </Dialog>
    </section>
</template>
<script>
import InputNumber from 'primevue/inputnumber';
import {toRaw, isProxy} from 'vue';
import axios from 'axios';
import {mapActions, mapMutations} from 'vuex';
import {
	faUpload,
	faSave
} from "@fortawesome/free-solid-svg-icons";

export default {
name: "CartBar",
components: {
    InputNumber: InputNumber
},
data() {
    return {
        icons: {				
				upload: faUpload,
				faSave: faSave
			},
        countProduct: 0,
        minCountProductFront: 0,
        minCountProductBack: 0,
        minValueCount: 0,
        frontPriceProduct: 0,
        backPriceProduct: 0,
        setMaterialPopUp: false,
        materialType: null,
        addShapePopUp: false,
        shapes: null,
        isDisabled: false
    }
},
props: ['is_admin_edit', 'shape'],
methods: {
    ...mapMutations({
        setProductCount: 'order/setProductCount',
        setProductPrice: 'order/setProductPrice'
    }),
    ...mapActions({
        setProductCount: 'order/setProductCount',
        setProductPrice: 'order/setProductPrice'
    }),
    /**
     * Get all shapes with setted prices for different sizes
     */
    getPrices() {
       let form = new FormData;
          form.append('action', 'dm_get_shape_data');
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then(response => {
            if (response && response.data) {
                this.shapes = response.data.shapes;
                this.setTotalPrice();
            }
          }).catch(error => {
            console.log(error);
          });
    },
    /**
     * Get sizes for current shape
     * @param {Object} shape 
     */
    getShapeForPrice(shape) {
        let shapes = this.shapes;
        if (shapes && shapes.length) {
            for (let i = 0; i < shapes.length; i++) {
                if (shapes[i].backgrounds_shape == shape.backgrounds_shape) {
                    if (shapes[i].sizes.length) {
                        return shapes[i].sizes;
                    }
                }
            }
        }
        return [];
    },
    /**
     * Get sorted prices for shape with selected width and height
     * @param {Object} sizes 
     * @param {Number} width 
     * @param {Number} height 
     */
    getPriceForSize(sizes, width, height) {
        if (sizes && sizes.length) {
            for (let j = 0; j < sizes.length; j++) {
                if (sizes[j].width == width && sizes[j].height == height) {
                    if (sizes[j].prices && sizes[j].prices.length) {
                        return sizes[j].prices.sort(function(a,b){ return a.min_quantity - b.min_quantity; });
                    }
                }
            }
        }
        return [];
    },
    /**
     * Get price for sected shape and setted count of products
     * @param {Number} countProduct 
     * @param {Object} prices 
     * @param {String} side 
     */
    getPriceForProduct(countProduct, prices, side) {
       if (prices && prices.length) {
            for (let k = 0; k < prices.length; k++) {
                if(countProduct >= prices[k].min_quantity && countProduct <= prices[k].max_quantity) {
                    if (side == 'front') {
                        return parseFloat(prices[k].front_label_price);
                    }
                    if (side == 'back') {
                        return parseFloat(prices[k].back_label_price);
                    }
                } else if (countProduct < prices[0].min_quantity) {
                    if (side == 'front') {
                        return parseFloat(prices[0].front_label_price);
                    }
                    if (side == 'back') {
                        return parseFloat(prices[0].back_label_price);
                    }
                } else if (countProduct > prices[prices.length - 1].max_quantity) {
                    if (side == 'front') {
                        return parseFloat(prices[prices.length - 1].front_label_price);
                    }
                    if (side == 'back') {
                        return parseFloat(prices[prices.length - 1].back_label_price);
                    }
                }
            }
        }
        return 0;
    },
    /**
     * Set price and min count for shape on frontend
     */
    setPriceForSide() {
        this.frontPriceProduct = 0;
        this.backPriceProduct = 0;      
        let shape = this.shape;
        let fsizes = [];
        let bsizes = [];
        let pricesFront = [];
        let pricesBack = [];
    
        fsizes = this.getShapeForPrice(this.shape.front);     
        bsizes = this.getShapeForPrice(this.shape.back);
        if (fsizes && fsizes.length) {
            pricesFront = this.getPriceForSize(fsizes, shape.front.width, shape.front.height);
            this.minCountProductFront = pricesFront.length ? pricesFront[0].min_quantity : 0;
        }
        if (bsizes && bsizes.length) {
            pricesBack = this.getPriceForSize(bsizes, shape.back.width, shape.back.height);
            this.minCountProductFront = (this.minCountProductFront == 0) && pricesBack.length ? pricesBack[0].min_quantity : this.minCountProductFront;
        }
        this.minValueCount = parseInt(this.minCountProductFront);
        this.countProduct = (this.countProduct == 0 || this.countProduct < this.minValueCount) ? this.minValueCount : this.countProduct;
       
        if (pricesFront && pricesFront.length) {
            this.frontPriceProduct = this.getPriceForProduct(this.countProduct, pricesFront, 'front');
        } 

        if (pricesBack && pricesBack.length) {
            this.backPriceProduct = this.getPriceForProduct(this.countProduct, pricesBack, 'back');
        }
        const price = {
            front: this.frontPriceProduct,
            back: this.$store.state.order.statusIncludeBackLabel ? this.backPriceProduct : 0
        }

        this.setProductCount(this.countProduct);
        this.setProductPrice(price);
    },
    setTotalPrice() {    
        //this.countProduct = 0;
        this.showFrontShapePrice = true;
        this.setPriceForSide();
    },
    loadLabelsList() {
        if (!this.$store.state.profileModule.isLoggedIn && this.$router.currentRoute.value.fullPath.indexOf('/editor') > -1) {
            this.emitter.emit('popUpLogin');
        } else {
            this.emitter.emit('loadLabelsList')
        } 
    },
    saveLabelModal() {
        this.emitter.emit('showSaveLabelPopUp');
    },
    setCountProduct() {
        this.setProductCount(this.countProduct);
        this.setTotalPrice();
    },
    emptyShape() {
        this.addShapePopUp = true;
    },
    addToCartButton() {
        // let isGuest = localStorage.getItem('isGuest');

        // if (!isGuest && !this.$store.state.profileModule.isLoggedIn && this.$router.currentRoute.value.fullPath.indexOf('/editor') > -1) {
        //     this.emitter.emit('popUpLogin');
        // } else {
            this.isDisabled = true;
            this.emitter.emit('addToCart');
        // }
    }
    // ,
    // setPriceForProduct() {
    //     let price = this.$store.state.order.productPrice ? this.$store.state.order.productPrice : this.frontPriceProduct;
    //     //this.setProductPrice(price);
    // }
},
mounted() {
    //this.setPriceForProduct();
    //this.setCountProduct();
    this.getPrices();
    //this.setTotalPrice();
}
}
</script>
<style >

.title-holder{
    display: flex;
    justify-content: space-between;}
.label-title{
    flex: 1 1 auto;
}
.text-title{
    color: #b93028;
    font: 24px/26px 'proxima-nova', 'Montserrat', Helvetica, Arial, sans-serif;
    margin-top: 30px;
    margin-left: 12px;
    font-weight: bold;
}
.box-side{
    text-align: right;
}	
.container-frame{
    height: 100px;
    padding: 0 17px 0px 15px;
    background-color: #dee2e6;
    margin-top: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.price-holder{
    margin: 0;
    color: #706652;
    font: 14px/16px Georgia, "Times New Roman", Times, serif;
    vertical-align: baseline;
    padding: 0;
}
.price-holder dt{
    margin: 0 5px 0 0;
    display: inline;
    vertical-align: baseline;
}
.price-holder dd{
    margin: 0;
    vertical-align: baseline;
    display: inline;
}
.number{
    font-size: 18px;
    line-height: 20px;
}
.price-value{
    color: #000;
    font-size: 24px;
    line-height: 26px;
    font-style: italic;
}
.submit-button{
    text-transform: none;
    font: 16px/37px Roboto, Helvetica, Arial, sans-serif;
    line-height: 37px;
    padding: 0 0 3px;
    margin: 0 0 0 7px;
    width: 145px;
    height: 2.5em;
    line-height: 2.5em;
    cursor: pointer;
    display: inline-block;
    max-height: none;
}
.text-quantity{
    font-size: 18px;
    border: 1px solid #9e9e9e;
    width: 60px;
    margin: 0;
    background: #fff;
    font: 18px/20px 'proxima-nova', 'Montserrat', Helvetica, Arial, sans-serif;
    text-align: center;
    height: 36px;
    margin-top: -3px;
}
.add-box{
    display: block;
}
.form-holder{
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex-wrap: wrap;
}
span.p-inputnumber>#count-buttons{
    width: 55px !important;
}
.p-button {
  color: #ffffff;
  background: #7cbefb;
  border: 1px solid #7cbefb;
}
.p-button:enabled:hover {
    background: #7cbefb;
    color: #ffffff;
    border-color: #7cbefb;
}
.label-type>div>img{
    width: 25px;
}
.label-type>input, .label-type>label{
    cursor: pointer;
}
#block-type>label{
    margin: 0 20px;
}
#block-type{
    padding: 0 70px;
    padding-top: 10px;
}
#count-buttons{
	height: 40px !important;
}
</style>