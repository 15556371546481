export const orderModule = {
    state: () => ({
        productName: null,
        productImages: null,
        productPrice: {
            front: 0,
            back: 0
        },
        productCount: 0,
        productPreset: null,
        productMaterialType: null,
        productSizes: null,
        productSlug: null,
        isAdminAddEdit: false,
        adminEmail: null,
        isProduct: null,
        isGuest: false,
        statusIncludeBackLabel: false
    }),
    getters: {
        getProductName(state) {
            return state.productName;
        },
        getProductImages(state) {
            return state.productImages;
        },
        getProductPrice(state) {
            return state.productPrice;
        },
        getProductCount(state) {
            return state.productCount;
        },
        getProductPreset(state) {
            return state.productPreset;
        },
        getProductMaterialType(state) {
            return state.productMaterialType;
        },
        getProductSizes(state) {
            return state.productSizes;
        },
        getProductSlug(state) {
            return state.productSlug;
        },
        getAdminActions(state) {
            return state.isAdminAddEdit;
        },
        getAdminEmail(state) {
            return state.adminEmail;
        },
        getProductStatus(state) {
            return state.isProduct;
        },
        getGuest(state) {
            return state.isGuest;
        },
        getStatusIncludeBackLabel(state) {
            return state.statusIncludeBackLabel;
        }
    },
    mutations: {
        setProductName(state, productName) {
            state.productName = productName;
        },
        setProductImages(state, productImages) {
            state.productImages = productImages;
        },
        setProductPrice(state, productPrice) {
            state.productPrice = productPrice;
        },
        setProductCount(state, productCount) {
            state.productCount = productCount;
        },
        setProductPreset(state, productPreset) {
            state.productPreset = productPreset;
        },
        setProductMaterialType(state, productMaterialType) {
            state.productMaterialType = productMaterialType;
        },
        setProductSizes(state, productSizes) {
            state.productSizes = productSizes;
        },
        setProductSlug(state, productSlug) {
            state.productSlug = productSlug;
        },
        setAdminActions(state, isAdminAddEdit) {
            state.isAdminAddEdit = isAdminAddEdit;
        },
        setAdminEmail(state, adminEmail) {
            state.adminEmail = adminEmail;
        },
        setProductStatus(state, isProduct) {
            state.isProduct = isProduct;
        },
        setGuest(state, isGuest) {
            state.isGuest = isGuest;
        },
        setStatusIncludeBackLabel(state, statusIncludeBackLabel) {
           state.statusIncludeBackLabel = statusIncludeBackLabel;
        }
    },
    actions: {
        setProductName(context, payload) {
            context.commit('setProductName', payload);
        },
        setProductImages(context, payload) {
            context.commit('setProductImages', payload);
        },
        setProductPrice(context, payload) {
            context.commit('setProductPrice', payload);
        },
        setProductCount(context, payload) {
            context.commit('setProductCount', payload);
        },
        setProductPreset(context, payload) {
            context.commit('setProductPreset', payload);
        },
        setProductMaterialType(context, payload) {
            context.commit('setProductMaterialType', payload);
        },
        setProductSizes(context, payload) {
            context.commit('setProductSizes', payload);
        },
        setProductSlug(context, payload) {
            context.commit('setProductSlug', payload);
        },
        setAdminActions(context, payload) {
            context.commit('setAdminActions', payload);
        },
        setAdminEmail(context, payload) {
            context.commit('setAdminEmail', payload);
        },
        setProductStatus(context, payload) {
            context.commit('setProductStatus', payload);
        },
        setGuest(context, payload) {
            context.commit('setGuest', payload);
        },
        setStatusIncludeBackLabel(context, payload) {
            context.commit('setStatusIncludeBackLabel', payload);
        }
    },
    namespaced: true
}
