<template>
    <div v-if="loading" class="preloader">
        <ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
    </div>
	<div class="main" id="editor">
		<div class="header-block">
			<h1>Pricing</h1>
		</div>
    <div class="content-accordion">
		<div class="conten-page-top"></div>
        <table style="width: 100%">
            <tr>
                <th>Shape</th>
                <th></th>
            </tr>
            <tr v-for="shape in prices">
                <td>{{ shape.backgrounds_shape }}</td>
                <td>
                    <table style="width: 100%">
                        <tr>
                            <th>Size</th>
                            <th></th>
                        </tr>
                        <tr v-for="size in shape.sizes">
                            <td>{{ size.value }}</td>
                            <td>
                                <table style="width: 100%">
                                    <tr>
                                        <th>Min quantity</th>
                                        <th>Max quantity</th>
                                        <th>Front label price</th>
                                        <th>Back label price</th>
                                    </tr>
                                   <tr v-for="price in size.prices.sort(function(a,b){return a.min_quantity - b.min_quantity;})"> <!-- //TODO sort correctly -->
                                        <td>{{price.min_quantity}}</td>
                                        <td>{{price.max_quantity}}</td>
                                        <td>${{price.front_label_price}}</td>
                                        <td>${{price.back_label_price}}</td>
                                    </tr>
                                </table>    
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
	</div>
</template>  
<script>
import axios from 'axios';
export default {
  name: 'PricingPage',
  data() {
    return {
      loading: false,
      prices: null
    }
  },
  methods: {
    priceForProducts() {
          let form = new FormData;
          form.append('action', 'dm_get_shape_data');
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then(response => {
            if (response && response.data) {
                this.prices = response.data.shapes;
            }
          }).catch(error => {
            console.log(error);
          });
        },
  },
  created () {
    this.priceForProducts();
  },
  beforeRouteUpdate (to, from, next) {
    this.priceForProducts();
    next();
  }
}
</script>  
<style scoped>
  h1{
  margin-top: 20px;
  font-family: Oswald;
  font-size: 26px;
}
#editor{
display:block;
margin-top: 21px;
}
.conten-page-top{
  text-align: center;
  margin-bottom: 20px;
}
.content-accordion{
  background-color: white;
  padding: 0 20px 20px 20px;
}
.heading-params{
  color: red;
}
.content-accordion{
    padding-top: 10px;
}
.p-card .p-card-body{
    margin-bottom: 10px !important;
}
.publish{

}
</style>
  