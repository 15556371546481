<template>
    <div class="flex-1 md:flex-none flex px-3 py-3">
        <div class="card-container">
            <div class="block surface-200 p-2">
                <Image :src="`${avatar}`" alt="Image" width="250" />
                <div
                    class="static bottom-0 p-1 text-center left-0 bg-orange-500 cursor-pointer"
                    @click="showAvatarLoadImage">
                    <i class="pi pi-user-edit" /> Edit avatar
                </div>
            </div>
            <div class="block">
                <Menu :model="items" class="w-full p-link p-0 text-color border-0 hover:surface-200">
                    <template #start>
                        <div class="surface-200 p-2 font-bold">
                            {{ this.$store.state.profileModule.userDisplayName }}
                        </div>
                    </template>
                    <template #end>
                        <button
                            @click="logout"
                            class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround">
                            <i class="pi pi-sign-out" />
                            <span class="ml-2">Log Out</span>
                        </button>
                    </template>
                </Menu>
            </div>
        </div>
    </div>
    <UploadImage :ref="'showPopUpAvatar'"></UploadImage>
</template>
<script>
import axios from 'axios';
import Image from 'primevue/image';
import Menu from 'primevue/menu';
import UploadImage from './UploadAvatar.vue'

export default {
    name: 'LeftSidebar',
    components: {
        Image: Image,
        Menu: Menu,
        UploadImage: UploadImage
    },
    data() {
        return { 
            items: [
                { separator: true },
                { label: 'Profile', icon: 'pi pi-fw pi-user', to: '/user'},
                { label: 'My Orders', icon: 'pi pi-list', to: '/user/orders'},
                { label: 'My Labels', icon: 'pi pi-heart', to: '/user/mylabels'},
                // { label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/user/settings'},
                { separator: true }
            ],
            avatar: this.pathUrl + '/images/template/user.webp'
        }
    },
    methods: {
        async logout() {
            await this.$store.dispatch('logout');
            this.$router.push({ name: 'login' });
        },
        showAvatarLoadImage() {
            this.$refs.showPopUpAvatar.openUploadImage();
        },
        getAvatar() {
            let form = new FormData;
            form.append('action', 'dm_get_user_avatar');
            form.append('user_email', this.$store.state.profileModule.userEmail)
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
                let avatar = response.data.avatar;
                this.avatar = avatar ? this.pathPluginImage + '/avatars/' + avatar : this.avatar;
            }).catch(error => {
                console.log(error);
            });
        }
    },
    async created () {
        if (this.$route.meta.noAuthRequired) {
            return;
        }
        let isValid = await this.$store.dispatch('checkLoginState');
        if (!isValid) {
            this.$router.push({
            name: 'login'
            });
        }
    },
    beforeRouteUpdate (to, from, next) {
        
    },
    mounted(){
        this.getAvatar();
        this.emitter.on('getAvatar', () => this.getAvatar());
    },
    unmounted() {
		this.emitter.all.clear();
	},
}
</script>
<style scoped>
</style>  