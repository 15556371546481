import {createRouter, createWebHistory} from "vue-router";
import { userRoutes, checkUserAuth } from './user-router';
import EditorPage from "@/pages/EditorPage";
import CategoryPage from "@/pages/CategoryPage";
import ProductVariants from "@/pages/ProductVariants";
import CategoriesPage from "@/pages/CategoriesPage";
import HomePage from '@/pages/HomePage';
import CartPage from '@/pages/ordering/CartPage';
import CheckoutPage from '@/pages/ordering/CheckoutPage';

import LabelsPage from '@/pages/LabelsPage';
import ShopPage from '@/pages/ShopPage';
import ContactsPage from '@/pages/ContactsPage';
import HelpInfoPage from '@/pages/HelpInfoPage';
import NotFoundPage from '@/pages/NotFoundPage';
import FaqPage from '@/pages/FaqPage';
// import LoginPage from '@/pages/LoginPage';
import BlogPage from '@/pages/BlogPage';
import PostPage from '@/pages/PostPage';
import PricingPage from '@/pages/PricingPage';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/editor/:id?/:param?/:data?',
        name: 'editor',
        component: EditorPage,
        options: {
            hotReload: false // disables Hot Reload
        }
    },
    {
        path: '/category/:id',
        component: CategoryPage
    },
    {
        path: '/shop/:id?',
        component: ShopPage
    },
    {
        path: '/categories',
        component: CategoriesPage
    },
    {
        path: '/product-variants/:category/:id',
        component: ProductVariants
    },
    {
        path: '/shoping-cart',
        component: CartPage,
        // meta: {
        //     title: 'shoping-cart',
        //     // allowGuest: false,
        //   },
    }, 
    {
        path: '/shoping-cart/checkout',
        component: CheckoutPage
    },
    {
        path: '/labels',
        component: LabelsPage
    },
    {
        path: '/help-info/',
        name: 'help-info',
        component: HelpInfoPage
    },
    {
        path: '/help-info/faq',
        component: FaqPage
    },
    {
        path: '/help-info/label-pricing',
        component: PricingPage
    },
    {
        path: '/help-info/:page?/:slug?',
        name: 'help-info',
        component: HelpInfoPage
    },
    {
        path: '/contact',
        component: ContactsPage
    },
    {
        path: '/blog',
        component: BlogPage
    },
    {
        path: '/blog/:id',
        component: PostPage
    },
    {
        path: '/404',
        name: 'NotFoundPage',
        component: NotFoundPage
    },
    {
        path: '/:catchAll(.*)',
        redirect:'404'
    },
    ...userRoutes
];

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
});

export default router;
