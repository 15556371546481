import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
// import { defineStore } from 'pinia';
// import {createStore} from "vuex";
import { authApi } from '@/helpers/helpers-user';

export const useAuthStore =  () => {
  const router = useRouter();

  const token = ref(localStorage.getItem('userToken'));
  const email = ref(localStorage.getItem('userEmail'));
  const displayName = ref(localStorage.getItem('userDisplayName'));

  const isLoggedIn = computed(() => !!token.value);

  /**
   * Login user and cache the profile data
   */
  async function login(username, password) {
    try {
      const response = await authApi.post('/token', {
        username,
        password,
      });

      // cache the data into store
      token.value = response.data.token;
      email.value = response.data.user_email;
      displayName.value = response.data.user_display_name;

      // cache the data into localStorage
      localStorage.setItem('userToken', response.data.token);
      localStorage.setItem('userEmail', response.data.user_email);
      localStorage.setItem('userDisplayName', response.data.user_display_name);

      return {
        status: response.status,
        message: 'Login success, redirecting back…',
      };
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data
          ? error.response.data.message
          : 'Connection Error. If your internet is fine, then there is a server issue.',
      };
    }
  }

  /**
   * Logout user and clear all cache
   */
  function logout() {
    token.value = '';
    localStorage.removeItem('userToken');

    router.push({
      name: 'login',
      query: { message: 'You have successfully logged out' },
    });
  }

  /**
   * Check whether the token is still valid or need to login again
   */
  async function validateToken() {
    if (!token.value) {
      return;
    }

    try {
      const headers = {
        Authorization: `Bearer ${token.value}`,
      };

      await authApi.post('/token/validate', {}, { headers });
      // If token is valid, do nothing
    } catch (error) {
      // If invalid, push back to login
      token.value = '';
      localStorage.removeItem('userToken');

      router.push({
        name: 'login',
        query: { message: 'Your session has expired. Please login again.' },
      });
    }
  }

  return {
    token,
    email,
    displayName,
    isLoggedIn,

    login,
    logout,
    validateToken,
  };
};

export default useAuthStore;




// // import Vue from 'vue';
// // import Vuex from 'vuex';
// import axios from 'axios';

// // Vue.use(Vuex);

// const mutations = {
//   cacheUser(state, { token, email, displayName }) {
//     state.isLoggedIn = true;
//     state.userToken = token;
//     state.userEmail = email;
//     state.userDisplayName = displayName;
//   },

//   deleteUserCache(state) {
//     state.isLoggedIn = false;
//     state.userToken = '';
//     state.userEmail = '';
//     state.userDisplayName = '';
//   },
// };

// const actions = {
//   async login({ commit }, payload) {
//     const response = await axios.post(`http://localhost/noontimelablesvue2/wp-json/jwt-auth/v1/token`, {
//       username: payload.username,
//       password: payload.password,
//     });

//     const data = response.data;

//     localStorage.setItem('isLoggedIn', true);
//     localStorage.setItem('token', data.token);
//     localStorage.setItem('displayName', data.user_display_name);
//     localStorage.setItem('email', data.user_email);

//     // call cacheUser() from mutations
//     await commit('cacheUser', {
//       token: data.token,
//       email: data.user_email,
//       displayName: data.user_display_name,
//     });
//   },

//   async logout({ commit }) {
//     localStorage.removeItem('isLoggedIn');
//     localStorage.removeItem('token');
//     localStorage.removeItem('email');
//     localStorage.removeItem('displayName');

//     commit('deleteUserCache');
//   },

//   /**
//    * Check if user if logged in
//    */
//   async checkLoginState({ commit }) {
//     const token = localStorage.getItem('token');

//     // if no token, empty the loggedIn cache
//     if (!token) {
//       await commit('deleteUserCache');
//       return false;
//     }

//     // if has token, check if it's still valid
//     try {
//       await axios.post(
//         `${process.env.VUE_APP_API_URL}/jwt-auth/v1/token/validate`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         },
//       );
      
//       // if still valid, cache it again
//       await commit('cacheUser', {
//         token,
//         email: localStorage.getItem('email'),
//         displayName: localStorage.getItem('displayName'),
//       });

//       return true;
//     } catch (error) {
//       localStorage.setItem('token', '');
//       return false;
//     }
//   },
// }


// const store = {
//   // This is global data, use mutations and actions to change this value.
//   state: {
//     isAdmin: false,
//     isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
//     userToken: localStorage.getItem('token') || '',
//     userEmail: localStorage.getItem('email') || '',
//     userDisplayName: localStorage.getItem('displayName') || '',
//   },

//   mutations,
//   actions,
//   modules: {
//   },
// };

// export default store;