<template>
<div>
        <Dialog
            header="Save Labels as Product"
            v-model:visible="this.displayDialog"
            :breakpoints="{'960px': '750vw', '640px': '90vw'}" 
            :style="{width: '31vw'}"
            >
            <div class="grid">
                <div class="col-12">
                    <TabView>
                        <TabPanel>
                            <template #header>
                                <span @click="setTypeForSave(true)">
                                    <i class="pi pi-save mr-2"></i>
                                    <span @click="setTypeForSave">Save product</span>
                                </span>
                            </template>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span @click="setTypeForSave(false)">
                                    <i class="pi pi-server mr-2"></i>
                                    <span>Save product as a variation</span>
                                </span>
                            </template>
                        </TabPanel>
                    </TabView>
                </div>
                    <div class="col-12">
                        <div class="p-inputgroup flex-1 mb-3">
                            <InputText
                                class="input-field mb-1"
                                v-model="productName"
                                placeholder="Input product name"
                            /> 
                        </div>
                        <div class="p-inputgroup flex-1 mb-2 mt-2">
                            <select
                                class="drop-field mt-2 mb-2"
                                v-model="categoryProduct"
                                >
                                <option
                                    v-for="(category) in productCategories"
                                    :value="category.id"
                                    >
                                        {{ category.category_name }}
                                </option>
                            </select>
                            <!-- <Dropdown v-model="productCategory" :options="productCategories" optionLabel="category_name" placeholder="Select a category" class="drop-field mt-2 mb-2 w-full md:w-14rem">
                                <template #value="slotProps">
                                    <div v-if="slotProps.value" class="flex align-items-center">                                      
                                        <div>{{ slotProps.value.category_name }}</div>
                                    </div>
                                    <span v-else>
                                        {{ slotProps.placeholder }}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <div class="flex align-items-center">
                                        <div>{{ slotProps.option.category_name }}</div>
                                    </div>
                                </template>
                            </Dropdown> -->
                        </div>
                        <div class="p-inputgroup flex-1 mb-3 mt-5">
                        <!-- <InputNumber
                            class="input-field"
                            v-model="productPrice"
                            mode="decimal"
                            :minFractionDigits="0"
                            :step="0.1"
                            currency="USD"
                            locale="en-US"
                            showButtons /> -->
                        </div>
                        <div class="col-12">
                            <Button label="Save product" :ref="'saveProd'" class="" @click="saveProduct(false)"/>
                        </div>
                    </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import axios from 'axios';
import Dropdown from 'primevue/dropdown';
import {isProxy, toRaw} from 'vue';
export default {
	name: "SaveProduct",
    components: {
        Dropdown: Dropdown
    },
    props: ['product_data'],
    data() {        
        return {
            displayDialog: false,
            productName: '',
            product: this.product_data,
            productID: 0,
            // productPrice: 0,
            categoryProduct: this.product_data.category_id || 0,
            productCategory: null,
            parentProductID: 0,
            general: {
                onlySave: true
            },
            productCategories: [],
        }
    },
	methods: {
        getCategoriesList() {
            let form = new FormData;
            form.append('action', 'dm_get_product_categories');
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
                this.productCategories = response.data.result;
            }).catch(error => {
                console.log(error);
            });
        },
        setTypeForSave(param) {
            this.general.onlySave = param;
        },
        selectCategory(event) {
            this.categoryProduct = event.target.value;
        },
        openSaveProductPopUp() {
            this.displayDialog = true;
            this.productName = this.product_data.name || '';
            this.productID = this.product_data.id || 0;
            this.categoryProduct = this.product_data.category_id || 0;
            this.parentProductID = this.product_data.product_id || 0;
        },
		saveProduct() {
            this.emitter.emit('saveProduct',{
                productName: this.productName,
                categoryID: this.categoryProduct,
                productID: this.productID,
                onlySave: this.general.onlySave,
                parentProductID: this.parentProductID
            });
            this.displayDialog = false;
		}
	},
    mounted() {
        this.getCategoriesList();
    }
}
</script>

<style scoped>
.list-label{
	cursor: pointer;
}
.input-field{
    height: 40px !important;
    border: 1px solid #ced4da !important;
}
.drop-field{
    height: 40px !important;
    width: 100%;
    border: 1px solid #ced4da;
}
.drop-field:focus{
    border: 1px solid #ced4da !important;
}
</style>