<template>
  <div class="main mt-3" >
    <div class="header-block">
      <h1>My Settings</h1>
    </div>
    <div class="card">
      <div>
        <div class="flex">
          <left-sidebar />
          <div class="flex-auto flex text-gray-900">
            <div class="card-container w-full">
              <TabView class="tabview-custom">
                <!-- <TabPanel>
                  <template #header>
                    <i class="pi pi-dollar mr-2"></i>
                    <span>MY TRANSACTION</span>
                  </template>
                    <div class="table-responsive">
                      <table class="table w-full text-sm">
                      <thead>
                        <tr>
                          <td scope="col">S.NO</td>
                          <td scope="col">LABEL</td>
                          <td scope="col">ITEM</td>
                          <td scope="col">ID</td>
                          <td scope="col">AMOUNT</td>
                          <td scope="col">DATE</td>
                          <td scope="col">PAYMENT</td>
                          <td scope="col">STATUS</td>
                        </tr>
                      </thead>
                      <tbody v-if="!orders">
                        <tr>
                          <td colspan="8" class="text-center">No Orders Made By You!</td>
                        </tr>
                      </tbody>
                      <tbody v-else-if="orders">
                        
                      </tbody>
                    </table>
                  </div>
                </TabPanel> -->
                <TabPanel>
                  <template #header>
                    <i class="pi pi-key mr-2"></i>
                    <span>CHANGE PASSWORD</span>            
                  </template>
                  <div class="card-container">
                    <div class="block">
                      <div class="grid">
                        <div class="col-10 mt-1">
                          <label class="col-10 p-0 font-normal">Old password*</label>
                          <InputText type="password" class="col-10" v-model="current_pass" placeholder="Enter your old password" />                          
                        </div>                        
                        <div class="col-10 mt-1">
                          <label class="col-10 p-0 font-normal">New password*</label>
                          <InputText type="password" class="col-10" v-model="new_pass" placeholder="Enter your new password" />                          
                        </div>
                        <div class="col-10 mt-1">
                          <label class="col-10 p-0 font-normal">Confirm new password*</label>
                          <InputText type="password" class="col-10" v-model="confirm_new_pass" placeholder="Confirm your new password" />                          
                        </div>
                        <div class="col-10 mt-2">
                          <Button class="bg-orange-500" @click="changelogin" label="Change password" />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <!-- <TabPanel>
                  <template #header>
                      <i class="pi pi-cart-plus mr-2"></i>
                      <span>MY ORDERS</span>
                  </template>
                  <div class="table-responsive w-full">
                    <table class="table w-full text-sm">
                      <thead>
                        <tr>
                          <td scope="col">S.NO</td>
                          <td scope="col">ORDER ID</td>
                          <td scope="col">ORDER DATE</td>
                          <td scope="col">PRICE</td>
                        </tr>
                      </thead>
                      <tbody v-if="!orders">
                        <tr>
                          <td colspan="8" class="text-center">No Orders Made By You!</td>
                        </tr>
                      </tbody>
                      <tbody v-else-if="orders">
                        <tr v-for="(order, index) of orders">
                          <td scope="col">{{ index+1 }}</td>
                          <td scope="col">{{ order.id }}</td>
                          <td scope="col">{{ order.created_at }}</td>
                          <td scope="col">${{ order.total }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                </TabPanel> -->
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
</template>
<script>
import axios from 'axios';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import LeftSidebar from '@/components/profile/LeftSidebar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
export default {
  name: 'SettingsPage',
  components: {
    LeftSidebar: LeftSidebar,
    TabView: TabView,
    TabPanel: TabPanel
  },
  data() {
    return {      
      orders: null,
      current_pass: null,
      new_pass: null,
      confirm_new_pass: null
    }
  },
  methods: {
    async changelogin() {
      let userEmail = this.$store.state.profileModule.userEmail;
      let response = await this.$store.dispatch('changePassword',{
        current_password: this.current_pass,
        new_password: this.new_pass,
        new_password_confirm: this.confirm_new_pass,
        user_email: userEmail
      });
      let user = response.status && response.status.userData ? response.status.userData : response.status;
      if (user.data && 'ID' in user.data && user.data.ID && user.data.user_email == userEmail) {
        this.current_pass = null;
        this.new_pass = null;
        this.confirm_new_pass = null;
        this.$toast.add({severity:'success', summary: 'Success Message', detail: `The password has been change succefuly`, life: 3000 });
      } else if (user && user.message) {
        this.$toast.add({ severity: 'error', summary: 'Error Message', detail: `${user.message}`, life: 3000 });
      }
    },
    getUserOrders() {
      // let userEmail = this.$store.state.profileModule.userEmail;
      // let form = new FormData;
      // form.append('action', 'dm_get_orders_by_user');
      // form.append('user_email', userEmail);
      // axios({
      //     method: 'post',
      //     url: this.$wpAjaxUrl,
      //     data: form
      // }).then(response => {
      //   this.orders = response.data.result;
      // }).catch(error => {
      //     console.log(error);
      // });
    }
  },
  async created () {
    if (this.$route.meta.noAuthRequired) {
      return;
    }
    let isValid = await this.$store.dispatch('checkLoginState');
    if (!isValid) {
      this.$router.push({
        name: 'login'
      });
    }
  },  
  beforeRouteUpdate (to, from, next) {
      
  },
  mounted() {
    this.getUserOrders();
  }
}
</script>      
<style scoped>
  h1{
    margin-left: 10px;
    font-size: 27px;
    font-family: Oswald;
  }    
  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
    display: flex;
  }
  .loginHd {
    color: #272727;
    font-family: oswaldbook;
    font-size: 26px;
    text-align: center;
    padding: 30px 0;
  }
  .loginBox {
    background: #fff;
    width: 100%;
    max-width: 734px;
    padding: 20px;
    min-height: 200px;
    margin: auto;
    padding-bottom: 50px;
  }
  .returningCustomer {
    width: 50%;
    float: left;
    padding-right: 50px;
  }
  .retCustHd {
    position: relative;
    padding-left: 36px;
  }
  input{
    padding: 7px !important;
  }
  </style>  