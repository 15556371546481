<template>
  <section>
    <top-bar :ref="'topbar'"></top-bar>
    <div class="app">
      <router-view :key="$route.path"></router-view>
    </div>
    <div class="footer">
      <div class="flex flex-column card-container green-container">
        <div class="flex align-items-center justify-content-center h-4rem text-white text-xs mt-7">
          <a @click="$router.push('/')" class="ml-1 mr-1 text-white">HOME</a> /
          <a @click="$router.push('/help-info/photo-gallery/')" class="ml-1 mr-1 text-white">PHOTO GALLERY</a> /
          <a @click="$router.push('/help-info/video/')" class="ml-1 mr-1 text-white">TUTORIAL</a> /
          <a @click="$router.push('/help-info/about-us/')" class="ml-1 mr-1 text-white">ABOUT US</a> /
          <a @click="$router.push('/help-info/label-pricing/')" class="ml-1 mr-1 text-white">PRICING</a> /
          <a @click="$router.push('/contact/')" class="ml-1 mr-1 text-white">CONTACT US</a>
        </div>
      </div>
      <div class="grid footer-copy-soc mt-2">
        <div class="col-6 text-white text-xs">© COPYRIGHT {{ new Date().getFullYear() }} NOONTIME LABELS, INC.</div>
        <div class="col-6 text-white text-xs social text-right">
          <a href="https://twitter.com/NoontimeLabels" target="_blank" class="twitter mr-1"></a>
          <a href="https://www.facebook.com/NoontimeLabels/" target="_blank" class="facebook mr-1"></a>
          <a href="mailto:info@noontimelabels.com" class="mail"></a>
        </div>
      </div>
    </div>
    </section>
</template>

<script>
import TopBar from "@/components/navigation/TopBar";
export default {
  components: {
    TopBar
  },
  data(){
    return {
    }
  },
  methods: {   
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.footer{
  background-color: #1e1d1d;
}
[v-cloak] {
  display: none;
}
.footer-copy-soc{
  width: 985px;
  margin: 0 auto;
}
.social a {
    height: 34px;
    width: 34px;
    display: inline-block;
    background: url('../public/images/template/socIcon.png') no-repeat;
}
.social a.twitter {
  background-position: 2px 0;
}
.social a.twitter:hover {
  background-position: -38px 0; 
}
.social a.facebook {
  background-position: -158px 0;
}
.social a.facebook:hover {
  background-position:  -198px 0;
}
.social a.mail {
  background-position: -79px 0;
}
.social a.mail:hover {
  background-position: -119px 0;
}
</style>
