<template>
    <div>
        <Toast />
    </div>
</template>
<script>
export default  {
    name: "AlertMessage",
    props: ['message'],
    data() {
        return {
            messages: []
        }
    },
    methods: {
        showSuccess(data) {
            if (data.status == 'OK'){
                this.$toast.add({severity:'success', summary: 'Success Message', detail: data.message, life: 3000});
            }
            if (data.status == 'Error') {
                this.$toast.add({severity:'warn', summary: 'Warn Message', detail: data.message, life: 3000});
            }
        },
        showError(data) {
            this.$toast.add({severity:'error', summary: 'Error Message', detail: data.message, life: 3000});
        },
    }
}
</script>