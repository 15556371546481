<template>
    <div class="main mt-3" >
        <div class="header-block">
            <h1>My Labels</h1>
        </div>
        <div class="card">
            <div>
                <div class="flex">
                    <left-sidebar />
                    <div class="flex-auto flex text-gray-900">
                    <div class="card-container w-full">
                        <div class="table-responsive">
                            <table class="table w-full">
                            <thead>
                            <tr>
                                <td scope="col">S.NO</td>
                                <td scope="col">IMAGE</td>
                                <td scope="col">LABEL NAME</td>
                                <td scope="col" colspan="2">ACTION</td>
                            </tr>
                            </thead>                       
                            <tbody v-if="labels && labels.length">
                                <tr v-for="(label, index) of labels">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <img @click="$router.push(`/editor/${label.label_slug}/personal`)" 
                                            style="position: relative; width: 70px; margin-right:5px"
                                            class="cursor-pointer"
                                            :src="`${this.pathPluginImage}/labels/${label.image_front}`"
                                            :alt="`${label.name}`">
                                        <img @click="$router.push(`/editor/${label.label_slug}/personal`)" 
                                            style="position: relative; width: 70px"
                                            class="cursor-pointer"
                                            :src="`${this.pathPluginImage}/labels/${label.image_back}`"
                                            :alt="`${label.name}`">
                                    </td>
                                    <td>
                                        <router-link :to="`/editor/${label.label_slug}/personal`">
                                            {{label.name}}
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link :to="`/editor/${label.label_slug}/personal`">
                                            <i class="pi pi-file-edit"></i>
                                        </router-link>
                                    </td>
                                    <td>
                                        <i class="pi pi-trash cursor-pointer" @click="confirmDelete(label)"></i>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="5">No Labels!</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <Toast />
    <ConfirmDialog></ConfirmDialog>
    </div>    
</template>        
<script>
import axios from 'axios';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import LeftSidebar from '@/components/profile/LeftSidebar';
export default {
    name: 'OrderPage',
    components: {
        LeftSidebar: LeftSidebar
    },
    data() {
        return {
            labels: null
        }
    },
    methods: {
        loadLabelsList() {
            let form = new FormData;
            form.append('action', 'dm_list_labels');
            form.append('customer_email', this.$store.state.profileModule.userEmail);
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
                if (response.data && response.data.labels) {
                    this.labels = response.data.labels.reverse();
                }                            
            }).catch(error => {
                console.log(error);
            });
        },
        confirmDelete(label) {
            this.$confirm.require({
                message: 'Do you want to delete this label?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
					if (label && label.id) {
						let form = new FormData;
						form.append('action', 'dm_delete_label');
						form.append('label_id', label.id);
                        form.append('customer_email', this.$store.state.profileModule.userEmail);
						axios({
							method: 'post',
							url: this.$wpAjaxUrl,
							data: form
						}).then(response => {
							this.$toast.add({severity:'info', summary:'Confirmed', detail: response.data.message, life: 3000});
							this.loadLabelsList();
						}).catch(error => {
							console.log(error);
						});
					}
                },
                reject: () => {
                    this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        }
    },
    async created () {
        if (this.$route.meta.noAuthRequired) {
            return;
        }
        let isValid = await this.$store.dispatch('checkLoginState');
        if (!isValid) {
            this.$router.push({
                name: 'login'
            });
        }
    },
    beforeRouteUpdate (to, from, next) {
        
    },
    mounted() {
		this.loadLabelsList();
	}
}
</script>        
<style scoped>
h1{
    margin-left: 10px;
    font-size: 27px;
    font-family: Oswald;
}    
.card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
    display: flex;
}
.loginHd {
    color: #272727;
    font-family: oswaldbook;
    font-size: 26px;
    text-align: center;
    padding: 30px 0;
}
.loginBox {
    background: #fff;
    width: 100%;
    max-width: 734px;
    padding: 20px;
    min-height: 200px;
    margin: auto;
    padding-bottom: 50px;
}
.returningCustomer {
    width: 50%;
    float: left;
    padding-right: 50px;
}
.retCustHd {
    position: relative;
    padding-left: 36px;
}
</style>  