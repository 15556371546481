export default {
	computed: {
		$canvasFront: {
			get: function () { return this.data.$canvasFront; },
			set: function (newValue) { this.data.$canvasFront = newValue; }
		},

		$canvasBack: {
			get: function () { return this.data.$canvasBack; },
			set: function (newValue) { this.data.$canvasBack = newValue; }
		},

		$editorFront: {
			get: function () { return this.data.$editorFront; },
			set: function (newValue) { this.data.$editorFront = newValue; }
		},

		$editorBack: {
			get: function () { return this.data.$editorBack },
			set: function (newValue) { this.data.$editorBack = newValue; }
		},

		$shapeFront: {
			get: function () { return this.data.$shapeFront; },
			set: function (newValue) { this.data.$shapeFront = newValue; }
		},

		$shapeBack: {
			get: function () { return this.data.$shapeBack; },
			set: function (newValue) { this.data.$shapeBack = newValue; }
		},

		$cuttingLineFront: {
			get: function () { return this.data.$cuttingLineFront; },
			set: function (newValue) { this.data.$cuttingLineFront = newValue; }
		},

		$cuttingLineBack: {
			get: function () { return this.data.$cuttingLineBack; },
			set: function (newValue) { this.data.$cuttingLineBack = newValue; }
		},

		$scaleRatioFront: {
			get: function () { return this.data.$scaleRatioFront; },
			set: function (newValue) { this.data.$scaleRatioFront = newValue; }
		},
		
		$scaleRatioBack: {
			get: function () { return this.data.$scaleRatioBack; },
			set: function (newValue) { this.data.$scaleRatioBack = newValue; }
		},

		$rendering: {
			get: function () { return this.data.$rendering; },
			set: function (newValue) { this.data.$rendering = newValue; }
		}
	},
    methods: {
		checkObjects(canvas) {
			canvas.getObjects().map(function(o) {
				return [o.type, o.top, o.left];
			});
		},
		getCoords(object) {
			let coords = {
				top: null,
				left: null,
				topPercent: null,
				leftPercent: null
			};
			let editor = this.$store.state.canvas.$side == 'front' ? this.data.$editorFront : this.data.$editorBack;

			if (!object) {
				return null;
			}

			if (!object.top) {
				let height = object.type === 'image' ? parseFloat(object.height * object.scaleX) : object.height;
				coords.top = (editor.clientHeight - height) / 2;
			} else {
				coords.top = object.top;
			}
			if (!object.left) {
				let width = object.type === 'image' ? parseFloat(object.width * object.scaleY) : object.width;
				coords.left = (editor.clientWidth - width) / 2;
			} else {
				coords.left = object.left;
			}
			coords['leftPercent'] = parseFloat(coords.left / editor.clientWidth * 100);
			coords['topPercent'] = parseFloat(coords.top / editor.clientHeight * 100);

			return coords;
		},
		getObjectParams(object) {
			if (object === null) {
				return null;
			}
			let data = {
				type: object.type,
				opacity: object.opacity || 0,
				top: object.top,
				left: object.left,
				topPercent: object.topPercent,
				leftPercent: object.leftPercent,
				width: object.width,
				height: object.height,
				scaleX: object.scaleX,
				scaleY: object.scaleY,
				fill: object.fill || null,
				strokeWidth: object.strokeWidth || null,
				stroke: object.stroke || null,
				strokeDashArray: object.strokeDashArray || [0, 0],
				strokeOffset: object.strokeOffset || 0,
				shadow: {
					color: object.shadow ? object.shadow.color : null,
					offsetX: object.shadow ? object.shadow.offsetX : null,
					offsetY: object.shadow ? object.shadow.offsetY : null,
					opacity: object.shadow ? object.shadow.opacity : null,
					blur: object.shadow ? object.shadow.blur : null
				} 
			};
			switch (object.type) {
				case 'image':
					data['src'] = object.getSrc();
					break;
				case 'i-text':
				case 'text-curved':
					data['text'] = object.text;
					data['textAlign'] = object.textAlign || null;
					data['fontSize'] = object.fontSize || null;
					data['fontFamily'] = object.fontFamily || null;
					data['fontStyle'] = object.fontStyle || null;
					data['fontWeight'] = object.fontWeight || null;
					data['lineHeight'] = object.lineHeight || null;
					data['charSpacing'] = object.charSpacing || null;
					data['underline'] = object.underline || null;
					data['diameter'] = object.diameter || null;
					data['flipped'] = object.flipped || null;
					break;
				// case 'ellipse':
				// 	data['rx'] = object.rx;
				// 	data['ry'] = object.ry;
				// 	data['radius'] = object.radius;
				// 	break;
				// case 'circle':
				// 	data['radius'] = object.radius;
				// 	break;
				default:
					break;
			}

			return data;
		},
	
		onException(event) {
			var data = event.errorData;
			this.$events.fire('hide-action-notification');
			if (data.errorField) {
				var error = {
					msg: data.message,
					field: data.errorField
				};
				if (this.errors) {
					this.errors.add(error);
				}
				var errorData = {
					type: this.errorAlertMap['error'],
					message: "There are some form validation errors."
				};
				this.$events.fire('show-alert-message', errorData);
			} else {
				var status = data.status || 'error',
					errorDatas = {
						type: this.errorAlertMap[status],
						message: data.message || data.exception
					};
				this.$events.fire('show-alert-message', errorDatas);
			}
		}
	}
}