import {createStore} from "vuex";
import {canvasModule} from "@/store/canvasModule";
import {orderModule} from "@/store/orderModule";
import profileModule from "@/store/profileModule";
// import useAuthStore from "@/store/stores-auth";
// import useUserStore from "@/store/stores-user";

export default createStore({
    state: {
      isAuth: false,
    },
    modules: {
        canvas: canvasModule,
        order: orderModule,
        profileModule: profileModule
        // useAuthStore: useAuthStore,
        // useUserStore: useUserStore
    }
});
