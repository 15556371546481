<template>
	<aside class="layers col-2">
		<div class="thumbnails ml-1 mr-1 mt-1" style="display: flex">
			<div class="front-thumbnail" :class="this.$store.state.canvas.$side === 'front' ? 'active' : ''">			
				<img id="preview-front"					
					@click="selectFrontLayer"
					:src="previewImageFront"/>
				<span class="label-name">Front label</span>
			</div>
			<div v-if="isAdmin || (isProduct && hasBackLayers) || (!isAdmin && !isProduct)" class="back-thumbnail" :class="this.$store.state.canvas.$side === 'back' ? 'active' : ''">
				<div v-if="isIncludedBackLabel"
					@click="removeBackLayers"
					class="deleteLayers"
					title="Delete back label from cart">
					<i class="pi pi-trash"></i>
				</div>						
				<div v-else 
					class="deleteLayers"
					style="background-color: limegreen"
					@click="addBackLayer"
					title="Add back label to cart">
					<i class="pi pi-cart-plus"></i>
				</div>
				<img
					id="preview-back"
					@click="selectBackLayer"
					crossorigin="anonymous"
					:src="previewImageBack"/>
				<span class="label-name" v-if="this.$store.state.order.statusIncludeBackLabel">Back label</span>	
				<span class="label-name" v-else>Add Back label</span>
			</div>
		</div>
		<div class="layers-list">
			<header class="ml-1">
				<font-awesome-icon :icon="layerGroup" />Layers
			</header>
			<div class="layers-list-elements">
				<ul id="drugList">
				<li v-if="list.length == 0">
					<h4 class="title-layer">Layers Panel is empty</h4>
					<p class="text-layer">Select new objects for your own label</p>
				</li>
				<template v-else>
					<template v-for="(layer, index) in list" :key="layer.order">
						<li v-if="layer"
							:id="this.$store.state.canvas.$side + index"
							:data-sort="layer.dataSort"
							:data-id="layer.order"
							class="layer-side pt-1"
							:class="activeMenu == layer.order ? 'active' : ''"
							@mousedown="selectMenu($event)"
							@mouseup="unSelectMenu">
								<img :src="`${this.pathUrl}/images/icons/svg/${layer.type}.svg`">
								{{ layer.type }} <template v-if="layer.text">"{{ layer.text }}"</template>
								<div class="not-draggable">
									<div v-if="layer.type != 'border'"
										class="layer-visible mt-1"
										@click.stop="lockLayer($event, layer)"
										style="margin-right: 20px">
										<template v-if="!layer.lockMovementX && !layer.lockMovementY">
											<i data-lock="1"
												style="font-size: 16px !important; line-height: 1 !important;"
												class="text-2xl mb-2 text-color-secondary pi pi-lock-open">
											</i>
										</template>
										<template v-if="layer.lockMovementX && layer.lockMovementY">
											<i data-lock="0"
												style="font-size: 16px !important; line-height: 1 !important;"
												class="text-2xl mb-2 text-color-secondary pi pi-lock">
											</i>
										</template>
									</div>
									<div class="layer-visible mt-1"
										title="Toggle Visibility"
										@click.stop="showHideLayer($event, layer)">
										<img data-show="1" :src="`${this.pathUrl}/images/icons/svg/eye-open.svg`">
									</div>
									<div class="layer-swatch" title="Eyedrop Color"></div>
								</div> 
						</li>
					</template>
				</template>
				</ul>
			</div>
		</div>
		<div class="layers-toolbox" v-if="list.length > 0">
			<Button @click="setCenterPosition" class="layer-button">
				<img :src="`${this.pathUrl}/images/icons/center-black.svg`" style="width: 17px; height: 18px;"/>
			</Button>
			<Button @click="duplicateLayer" class="layer-button">
				<img :src="`${this.pathUrl}/images/icons/duplicate.svg`" style="width: 17px; height: 18px;"/>
			</Button>
			<Button @click="showDeleteInfo" class="layer-button">
				<img :src="`${this.pathUrl}/images/icons/trash-black.svg`" style="width: 17px; height: 18px;"/>
			</Button>
		</div>
		<ConfirmDialog></ConfirmDialog>
	</aside>
</template>

<script>

import {faLayerGroup, faTrashAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import {mapState, mapActions, mapMutations} from 'vuex';
import draggable from 'vuedraggable';
import {toRaw} from 'vue';

export default {
	name: "LayersComponent",
	props: ['choosedLabel', 'settedShape'],
	components: {
		draggable: draggable
	},
	data() {
		return {
			layerGroup: faLayerGroup,
			trash: faTrashAlt,
			faplus: faPlus,
			list: [],
			previewImageFront: null,
			previewImageBack: null,
			hasBackLayers: false,
			isAdmin: false,
			isProduct: false,
			activeID: null,
			dragElem: null,
			tmpElem: null,
			enabled: true,
			dragging: false,
			activeMenu: 0,
			isIncludedBackLabel: false
		}
	},
	methods: {
		...mapMutations({
			setSide: 'canvas/setSide',
			setStatusIncludeBackLabel: 'order/setStatusIncludeBackLabel'
		}),
		...mapActions({
			setSide: 'canvas/setSide',
			setStatusIncludeBackLabel: 'order/setStatusIncludeBackLabel'
		}),
		unSelectMenu(event) {
			let items = document.querySelector(".active");
			if (items && items.classList && items.classList.contains("active")) {
				items.classList.remove("active");
				event.target.classList.add("active");
			}
			 return false;
		},
		selectMenu(event) {
			this.activeMenu = 0;
			this.focusOnLayer(event.target);
			this.activeMenu = event.target.getAttribute('data-id');
			let target = document.getElementById('drugList');
			target.classList.add("slist");
  			let items = target.getElementsByTagName("li"), current = null;
			for (let i of items) {
				i.draggable = true;
				i.ondragstart = e => {
					current = i;
					for (let it of items) {
						if (it != current) {
							it.classList.add("hint");
						}
					}
				};
				i.ondragenter = e => {
					if (i != current) {
						i.classList.add("activet");
					}
				};
				i.ondragleave = () => i.classList.remove("activet");
				i.ondragend = () => {
					for (let it of items) {
						it.classList.remove("hint");
						it.classList.remove("activet");
					}
				};
				i.ondragover = e => e.preventDefault();
				i.ondrop = e => {
					e.preventDefault();
					if (i != current) {
						let currentpos = 0, droppedpos = 0;
						for (let it=0; it<items.length; it++) {
							if (current == items[it]) {
								currentpos = it; 
								}
							if (i == items[it]) { 
								droppedpos = it;
							}
						}
						if (currentpos < droppedpos) {
							if (i && i.parentNode && current && i.nextSibling) {
								this.layerDown(currentpos, droppedpos);
								i.parentNode.insertBefore(current, i.nextSibling);
							}
						} else {
							if (i && i.parentNode && current) {
								this.layerUp(currentpos, droppedpos);
								i.parentNode.insertBefore(current, i);
							}
						}
					}
					this.renderLayers();
				};
			}
		},
		addBackLayer() {
			this.previewImageBack = this.data.$canvasBack.toDataURL();
			this.setStatusIncludeBackLabel(true);
			this.emitter.emit('setActiveBackSide', true);
			this.isIncludedBackLabel = true;
			this.updatePreview();
			this.selectBackLayer();
		},
		removeBackLayers() {
			this.confirmDelete();
		},
		confirmDelete() {
            this.$confirm.require({
                message: 'Do you want to delete back layers?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
					//this.previewImageBack = null;
					this.selectFrontLayer();
					this.emitter.emit('setActiveBackSide', false);
					// this.emitter.emit('removeBackLayers');
					this.isIncludedBackLabel = false;
					this.setStatusIncludeBackLabel(false);
					this.updatePreview();
                },
                reject: () => {
                    this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected deleting layers!', life: 3000});
                }
            });
        },
		setActiveMenu(activeParams) {
			this.activeMenu = activeParams;
		},
		renderLayers() {
			// setTimeout(()=>{
			let canvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;

			if (canvas) {
				let objs = canvas.getObjects();
				this.list = [];
				// if(isDublicate) {
				// 	for (let i=0; i<objs.length; i++){
				// 		if(objs[i].id == 'cuttingLine') {
				// 			let o = objs[i];
				// 			objs.splice(i, 1);
				// 			objs.push(o);
				// 		}
				// 		if(objs[i].id == 'cuttingLineDashed') {
				// 			let o = objs[i];
				// 			objs.splice(i, 1);
				// 			objs.push(o);
				// 		}
				// 	}
				// }
				
				this.list = objs.map((obj, i) => {
					let layer = {};
					if (obj && obj.type && (obj.type == 'border' || obj.type == 'image' || obj.type == 'i-text' || obj.type == 'text-curved')) {
						let currentText = obj.text && obj.text.length > 0 ? obj.text : '';
						currentText = currentText.length && currentText.length > 15 ? currentText.slice(0, 12) + '...' : currentText;
						layer.text = currentText;
						layer.dataSort = i;
						layer.order = obj.order;
						layer.type = obj.type;
						layer.lockMovementX = obj.lockMovementX;
						layer.lockMovementY = obj.lockMovementY;
						canvas.setActiveObject(obj);
						canvas.renderAll();
						return layer;
					}
				});
				this.list = this.list.reverse();
				this.list = this.list.filter(element => {if (element){return element;}});
				this.updatePreview();	
			}
			// }, 100);
		},
		showDeleteInfo() {
			let side = this.$store.state.canvas.$side;
            let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			const selectedLayer = canvas.getActiveObject();
            if (selectedLayer) {
				this.$confirm.require({
					message: 'Do you want to delete this label?',
					header: 'Delete Confirmation',
					icon: 'pi pi-info-circle',
					acceptClass: 'p-button-danger',
					accept: () => {
						this.list.length = 0;
						this.emitter.emit('deleteObjects', {layer: selectedLayer});
						this.$toast.add({severity:'info', summary:'Confirmed', detail:'Layer deleted', life: 3000});
					},
					reject: () => {
						this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
					}
				});
			}
		},
        setCenterPosition() {
			let side = this.$store.state.canvas.$side;
            let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
            const selectedLayer = canvas.getActiveObjects()[0];
            if (selectedLayer) {
                // let coords = this.getCoords(selectedLayer);
                // coords = {
                //     left: this.data.$activeCanvas.width/2 - Math.round(Math.abs(selectedLayer.aCoords.tl.x - selectedLayer.aCoords.tr.x)) / 2,
                // };
                // selectedLayer.set(coords);
				canvas.centerObjectH(selectedLayer); 
				this.emitter.emit('showActionBlock');
                let params = this.getObjectParams(selectedLayer);
                if (params) {
                    this.emitter.emit('updateHistory', true);
                    canvas.renderAll();

                }
            }
        },
		duplicateLayer() {
			let side = this.$store.state.canvas.$side;
            let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			 const selectedLayer = canvas.getActiveObject();
            if (selectedLayer && 'type' in selectedLayer && selectedLayer.type != 'border') {
				this.emitter.emit('cloneLayer', {layer: selectedLayer});
			}


			// var object = fabric.util.object.clone(canvas.getActiveObject());
			// object.set('order', new Date().getTime());
			// object.set("top", object.top+5);
			// object.set("left", object.left+5);this.renderLayers(true)
			// canvas.add(object);			
			// canvas.renderAll();
			// this.emitter.emit('canvasUpdated');			
			// this.emitter.emit('setToTopCuttingLine');
		},
		focusOnLayer(elem) {
			let orderID = elem.dataset.id;
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let objects = activeCanvas.getObjects();
			for(var i in objects) {
				if (objects[i].order == orderID) {
					activeCanvas.setActiveObject(objects[i]);
					activeCanvas.renderAll();
				}
			}
		},
		lockLayer(event, layer) {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			if (activeCanvas) {
				let objects = activeCanvas.getObjects();
			
				for(var i in objects) {
					if (objects[i].order == layer.order) {
						// objects[i].set('selectable', objects[i].selectable ? false : true);
						// objects[i].set('evented', objects[i].evented ? false : true);
						objects[i].set('lockMovementX', objects[i].lockMovementX ? false : true);
						objects[i].set('lockMovementY', objects[i].lockMovementY ? false: true);
						objects[i].set('lockScalingX', objects[i].lockScalingX ? false : true);
						objects[i].set('lockScalingY', objects[i].lockScalingY ? false : true);
						
						objects[i].set('hasRotatingPoint', objects[i].hasRotatingPoint ? false : true);
						
						activeCanvas.setActiveObject(objects[i]);					
						activeCanvas.renderAll();
					}
				}
				let isVisible = event.target.getAttribute('data-lock');
				if (isVisible == 1) {
					event.target.setAttribute('data-lock', 0);
				} else {
					event.target.setAttribute('data-lock', 1);
				}
				this.renderLayers();
			}
		},
		showHideLayer(event, layer) {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			if (activeCanvas) {
				let objects = activeCanvas.getObjects();
			
				for(var i in objects) {
					if (objects[i].order == layer.order) {
						objects[i].opacity = parseInt(objects[i].opacity) == 0 ? 1 : 0;
						activeCanvas.setActiveObject(objects[i]);					
						activeCanvas.renderAll();
					}
				}
				let isVisible = event.target.getAttribute('data-show');
				if (isVisible == 1) {
					event.target.setAttribute('data-show', 0);
					event.target.setAttribute('src',`${this.pathUrl}/images/icons/svg/eye-close.svg`);
				} else {
					event.target.setAttribute('data-show', 1);
					event.target.setAttribute('src',`${this.pathUrl}/images/icons/svg/eye-open.svg`);
				}
			}
		},
		selectFrontLayer() {
			this.emitter.emit('selectedEditor', 'front');
			this.emitter.emit('canvasUpdated', {updateHistory: true, side: 'front'});
			this.data.$canvasBack.discardActiveObject().renderAll();
			this.emitter.emit('selectShape', {shape: this.settedShape['front'], side: 'front'});
		},
		selectBackLayer() {
			this.emitter.emit('selectedEditor', 'back');
			this.emitter.emit('canvasUpdated', {updateHistory: true, side: 'back'});
			this.data.$canvasFront.discardActiveObject().renderAll();
			this.emitter.emit('selectShape', {shape: this.settedShape['back'], side: 'back'});
		},
		updateLayersList(activeCanvas) {
			let objs = activeCanvas.getObjects();
			this.list = [];
			setTimeout(() => {
				for (let i=objs.length-1; i>=0; i--) {
					let layer = {};
					if (objs[i] && objs[i].type && (objs[i].type == 'border' || objs[i].type == 'image' || objs[i].type == 'i-text'
					|| objs[i].type == 'text-curved')) {
						let currentText = objs[i].text && objs[i].text.length > 0 ? objs[i].text : '';
						currentText = currentText.length && currentText.length > 15 ? currentText.slice(0, 12) + '...' : currentText;
						
						layer.text = currentText;
						layer.dataSort = i;
						layer.order = objs[i].order;
						layer.type = objs[i].type;
						layer.lockMovementX = objs[i].lockMovementX;
						layer.lockMovementY = objs[i].lockMovementY;
						
						this.list.push(layer);
					}
				}
			}, 10);
		},
		layerUp(currentpos, droppedpos) {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			if (activeCanvas) {
				let object = activeCanvas.getActiveObject(); 
				if (!object) {
					return false;
				}
			
				for (let i = 0; i <= currentpos - droppedpos - 1; i++) {
					object.bringForward();
				}
				activeCanvas.getObjects().forEach(element => {
					if (element.id && element.id == 'cuttingLine') {
						element.bringToFront();
					}
				});		
				activeCanvas.getObjects().forEach(element => {
					if (element.id && element.id == 'cuttingLineDashed') {
						element.bringToFront();
					}
				});
				//this.updateLayersList(activeCanvas);
			}
		},
		layerDown(currentpos, droppedpos) {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			if (activeCanvas) {
				this.list = [];
				let object = activeCanvas.getActiveObject();

				if (!object) {
					return false;
				}

				for (let i = 0; i <= droppedpos - currentpos-1; i++) {
					object.sendBackwards();
				}
				activeCanvas.getObjects().forEach(element => {
					if (element.id && element.id=='cuttingLine') {						
						activeCanvas.bringToFront(element);
					}
				});	
				activeCanvas.getObjects().forEach(element => {
					if(element.id && element.id=='cuttingLineDashed') {
						activeCanvas.bringToFront(element);
					}
				});
				// this.updateLayersList(activeCanvas);
			}
		},
		renderPreview() {
			// let activeSide = this.$store.state.canvas.$side;
			// let activeCanvas = activeSide == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			// if (activeCanvas) {
			// 	this.$nextTick(()=>{
			// 		let objects = activeCanvas.getObjects();
			// 		if (objects) {
			// 			objects.pop(); // remove 'path' from the list of objects
			// 			if (activeSide == 'front') {
			// 				this.previewImageFront = activeCanvas.toDataURL();
			// 			} else {
			// 				this.previewImageBack = activeCanvas.toDataURL();
			// 			}
			// 		}
			// 		this.renderLayers();
			// 	});
			// }
		},
		updatePreview() {
			setTimeout(() => {
				this.isAdmin = this.$store.state.profileModule.isAdmin;
				this.isProduct = +this.$store.state.order.isProduct;
				this.hasBackLayers = this.data.$canvasBack.getObjects().length > 2 ?  true : false;
				this.previewImageFront = this.data.$canvasFront.toDataURL(); 
				this.previewImageBack = this.data.$canvasBack.toDataURL(); 
				if (this.$store.state.canvas.$showBackCanvas || this.data.$canvasBack.getObjects().length > 2) {
					this.previewImageBack = this.data.$canvasBack.toDataURL();
				}
			}, 200);
		},
		checkIfAdmin() {
			this.setStatusIncludeBackLabel(false);
			this.isIncludedBackLabel = false;
			// if(!this.$store.state.profileModule.isAdmin) {
				this.emitter.emit('selectedEditor', 'front');
			// }
		}
	},
	mounted() {
		this.emitter.on('setActiveMenu', (data) => this.setActiveMenu(data));
		this.emitter.on('renderPreview', this.renderPreview());
		this.emitter.on('updatePreview', this.updatePreview());
		this.checkIfAdmin();
	},
	computed: {
		...mapState({
			$side: state => state.canvas.$side,
			statusIncludeBackLabel: state => state.order.statusIncludeBackLabel
		})
	}
}
</script>

<style scoped>
.layer-visible {
    cursor: pointer;
    width: 16px;
    height: 16px;
    right: 0px;
    position: absolute;
    top: 0px;
    fill: #000;
}
.layer-side{	
	background: #fff;
	border: 2px solid #fff;
	-webkit-box-shadow: 2px 1px 2px #c3c3c3;
	box-shadow: 2px 1px 2px #c3c3c3;
	color: #333333;
	margin-bottom: 3px;
	cursor: move;
	position: relative;
	height: 31px;
	width: 210px;
	font-size: 12px;
}
.layer-empty{
	background: gray;
	position: relative;
	height: 31px;
	width: 95px;
}
.layer-side:hover{
	font-weight: bold;
	background-color: #d2d2d2;
}
.layer-side.active{
	background-color: #d2d2d2;
	font-weight: bold;
}
#preview-back, #preview-front{
	cursor:pointer;
}
.front-thumbnail,.back-thumbnail{
	display: inline-block;
	position: relative;
	padding: 8px;
	margin-right: 5px;
	border-radius: 8px;
	margin-bottom: 5px;
	cursor: pointer;
	text-align: center;
	font: 12px/14px Georgia, "Times New Roman", Times, serif;
}
.layers-list>header{
	font-size: 13px;
	font-weight: bold;
	color: #000;
}
.layers-list-elements{
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
}
.front-thumbnail.active,.back-thumbnail.active{
	background: #c0c0c0;
	color: white;
}
.layer-button{
	background-color: transparent !important;
}
.layer-button:hover{
	background-color: #e8e8e8 !important;
}
.layers-toolbox{
	display: flex;
	justify-content: end;
	margin-top: 10px;
	border-top: 1px solid #c0c0c0;
}
.title-layer{
	font-size: 16px;
	text-align: center;
} 
.text-layer{
	font-size: 14px;
	width: 200px;
	text-align: center;
}
#drugList{
	user-select: none;
	margin-bottom: 20px;
	padding:0;
	/* display: flex;
  flex-direction: column-reverse; */
}
#drugList>li{
	list-style: none;
}
.layer-side>.inform-text{
	font-size: 10px;
}
.slist li.hint {
  border: 1px solid #EAECEE;
  background: #F8F9F9;
}
.slist li.activet {
  border: 1px solid gray;
  background: #e8e8e8;
}
.emptySide{
	width: 74px;
	height: 74px;
	background-color: white;
	padding: 3px;
}
.emptyInside{
	width: 68px;
	height: 68px;	
	border: 1px dashed gray
} 
.buttton-add{
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 1px solid black;
	font-size: 24px;
	font-weight: bolder;
	background-color: #ed640d;
	margin-left: 54px;
	margin-top: -12px;
	padding: 3px;
}
.buttton-add:hover{
	background-color: #FF9966;
}
.deleteLayers{	
	width: 35px;
	height: 35px;
	border-radius: 50%;
	border: 1px solid black;
	font-size: 40px;
	margin-top: -35px;
    margin-left: 68px;
	padding: 3px;
	background-color: red;
}
.deleteLayers:hover{
	background-color: rgba(255, 0, 0, 0.5);
}
.add-back-text{
	font-size: 13px;
}
</style>