// import { useAuthStore } from '@/store';

import LoginPage from '@/pages/userProfile/LoginPage.vue';
import RegisterPage from '@/pages/userProfile/RegisterPage.vue';
import PasswordForgotPage from '@/pages/userProfile/PasswordForgotPage.vue';
import ProfilePage from '@/pages/userProfile/ProfilePage';
import ProfileEditPage from '@/pages/userProfile/ProfileEditPage';
import SettingsPage from '@/pages/userProfile/SettingsPage';
import OrdersPage from '@/pages/userProfile/OrdersPage';
import UserLabelsPage from '@/pages/userProfile/UserLabelsPage';

export const userRoutes = [
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {
            title: 'Login',
            allowGuest: true
        },
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterPage,
        meta: {
            title: 'Register',
            allowGuest: true
        }
    },
    {
        path: '/password-forgot',
        name: 'passwordForgot',
        component: PasswordForgotPage,
        meta: {
            title: 'Forgot Password',
            allowGuest: true
        }
    },
    {
        path: '/user',
        name: 'profile-page',
        component: ProfilePage,
        meta: {
            title: 'Profile Page',
            allowGuest: false
        },
    }, 
    {
        path: '/user/edit-profile',
        name: 'edit-profile-page',
        component: ProfileEditPage,
        meta: {
            title: 'Edit Profile Page',
            allowGuest: false
        },
    }, 
    {
        path: '/user/settings',
        name: 'settings-page',
        component: SettingsPage,
        meta: {
            title: 'Settings Page',
            allowGuest: false
        }
    },
    {
        path: '/user/orders',
        component: OrdersPage,
        meta: {
            title: 'Orders Page',
            allowGuest: false
        }
    },
    {
        path: '/user/mylabels',
        component: UserLabelsPage,
        meta: {
            title: 'Labels Page',
            allowGuest: false
        }
    },
];

/**
 * Get route args depending on auth state
 */
export const checkUserAuth = (to) => {
  const authStore = null;//useAuthStore();

  // if allow guest, no need to check for loggedIn status
  if (to.meta.allowGuest) {
    return null;
  }

  // if not logged in
  if (!authStore.isLoggedIn) {
    return {
      name: 'login',
      query: {
        redirectTo: to.fullPath,
        message: 'Please login to view that page',
      },
    };
  }

  return null;
};

export default userRoutes;