<template>
  <div v-if="loading" class="preloader">
		<ProgressSpinner strokeWidth="8" animationDuration=".7s"/>
	</div>
	<div class="main" id="editor" v-if="titlePage">
		<div class="header-block">
			<h1 v-html="titlePage"></h1>
		</div>
    <div class="content-page grid" v-if="childPosts.length">
      <div class="col-6" v-for="post in childPosts">
          <div class="text-center p-3 border-50 border-round-sm surface-100 font-bold">
            <router-link class="no-underline" :to="`/${baseUrl}${post.post_name}`" exact>
              <span>{{post.post_title}}</span>
            </router-link>
          </div>
      </div>
    </div>
		<div v-else class="content-page" v-html="contentPage"></div>
  </div>
</template>
  
<script>
import axios from 'axios';
export default {
    name: 'HelpInfoPage',
    data() {
      return {
        titlePage: '',
        contentPage: '',
        loading: false,
        childPosts: [],
        baseUrl: null
      }
    },
    methods: {
        getDataPage(slug, page) {
          this.loading = true;
          let form = new FormData;
          form.append('action', 'dm_get_page_by_slug');
          let pageSlug = page ? 'help-info/' + slug + '/' + page : 'help-info/' + slug + '/';
          if (!slug && !page) {
            pageSlug = 'help-info/';
          }
          this.baseUrl = pageSlug;
          form.append('slug', pageSlug);
          axios({
              method: 'post',
              url: this.$wpAjaxUrl,
              data: form
          }).then(response => {
            this.loading = false;
            this.childPosts = [];
            if (response.data && response.data.status == 'OK') {
                this.titlePage = response.data.pageTitle;
                this.contentPage = response.data.pageContent;
                this.childPosts = response.data.child_posts;
            } else {
                this.$router.push('/404');
            }
          }).catch(error => {
              console.log(error);
          });
        }
    },
    created () {
		this.getDataPage(this.$route.params.page, this.$route.params.slug);
	},
	beforeRouteUpdate (to, from, next) {
		this.getDataPage(to.params.page);
		next();
	}
}
  </script>
  
  <style scoped>
   h1{
	margin-left: 10px;
	font-size: 27px;
  font-family: Oswald;
  }
  #editor{
	display:block;
	margin-top: 21px;
  }
  .content-page{
    background-color: white;
    height: fit-content;
  }
  table{
    border: 1px solid black !important;
  }
  .content-page{
    font-family: 'Open Sans';
  }
  </style>
  
  