<template>
  <div class="main mt-3" >
      <div class="header-block">
        <h1>Edit Profile</h1>
      </div>
      <div class="card">
        <div>
            <div class="flex">
                <left-sidebar />
                <div class="flex-auto flex text-gray-900">
                  <div class="card-container">
                    <div class="block text-lg">Hello <strong class="text-orange-500">{{ this.$store.state.profileModule.userDisplayName }}</strong></div>
                    <div class="block mt-2 mb-1"><span class="font-medium">EDIT YOUR PERSONAL INFORMATION</span></div>
                    <div class="block">
                      <div class="grid">
                        <div class="col-6 mt-1 pr-1">
                          <label class="col-12 p-0 font-normal">First Name*</label>
                          <InputText class="col-12" v-model="first_name" placeholder="Enter your First Name" />                          
                        </div>                        
                        <div class="col-6 mt-1 pl-1">
                          <label class="col-12 p-0 font-normal">Last Name*</label>
                          <InputText class="col-12" v-model="last_name" placeholder="Enter your Last Name" />                          
                        </div>
                    </div>
                    <div class="grid">
                      <div class="col-12 mt-1">
                          <label class="col-12 p-0 font-normal">E-mail*</label>
                          <InputText class="col-12" placeholder="E-mail" disabled v-model="$store.state.profileModule.userEmail"/>                          
                      </div> 
                    </div>
                    <div class="grid">
                      <div class="col-6 mt-1 pr-1">
                          <label class="col-12 p-0 font-normal">Country*</label>
                          <InputText disabled class="col-12" v-model="country" placeholder="Enter your Country" />                          
                      </div>                        
                      <div class="col-6 mt-1 pl-1">
                        <label class="col-12 p-0 font-normal">State*</label>
                        <!-- <InputText class="col-12" v-model="state" placeholder="Enter your State" />  -->
                        <Dropdown
                          datatID="state"
                          v-model="state"
                          :options="states"
                          optionLabel="name"
                          optionValue="code"
                          @change="setStates($event)"
                          placeholder="Select state"
                          class="w-full p-2 m-0" />
                      </div>
                    </div>
                    <div class="grid">
                      <div class="col-6 mt-1 pr-1">
                          <label class="col-12 p-0 font-normal">City*</label>
                          <InputText class="col-12" v-model="city" placeholder="Enter your City" />                          
                      </div>                        
                      <div class="col-6 mt-1 pl-1">
                        <label class="col-12 p-0 font-normal">Postal Code*</label>
                        <InputText class="col-12" v-model="postal_code" placeholder="Enter your Postal Code" />                          
                      </div>
                    </div>
                    <div class="grid">
                      <div class="col-6 mt-1 pr-1">
                          <label class="col-12 p-0 font-normal">Street Address*</label>
                          <InputText class="col-12" v-model="address" placeholder="Enter your Street Address" />                          
                      </div>                        
                      <div class="col-6 mt-1 pl-1">
                        <label class="col-12 p-0 font-normal">Phone*</label>
                        <InputText class="col-12" v-model="phone" placeholder="Enter your Phone" />                          
                      </div>
                    </div>
                    <div class="flex">
                      <Button class="bg-orange-500" @click="updateProfile" label="Update" />
                    </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <Toast />
  </template>
      
  <script>
  import axios from 'axios';
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Image from 'primevue/image';
  import Dropdown from 'primevue/dropdown';
  import LeftSidebar from '@/components/profile/LeftSidebar';
      export default {
        name: 'ProfilePage',
        components: {
          Image: Image,
          Dropdown: Dropdown,
          LeftSidebar: LeftSidebar
        },
        data() {
          return {      
            first_name: null,
            last_name: null,
            country: null,
            state: null,
            city: null,
            postal_code: null,
            address: null,
            phone: null,
            states: []
          }
        },
        methods: {
          getCountries() {
          let form = new FormData;
          form.append('action', 'dm_get_countries');
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            if (response.data.contries.length) {
              this.country = response.data.contries[0].name;
            }
          }).catch(error => {
            console.log(error);
          });
        },
        getZoon() {
          let form = new FormData;
          form.append('action' , 'dm_get_zoon');
          axios({
            method: 'post',
            url: this.$wpAjaxUrl,
            data: form
          }).then((response) => {
            if (response.data.zoon.length) {
                this.states = Array.from(response.data.zoon);             
            }
          }).catch(error => {
            console.log(error);
          });
        },
        setStates(event) {
          this.state = event.value;
        },
          async getPersonalInfo() {
            let personalData = await this.$store.dispatch('getPersonalInfo', {
              'user_email': this.$store.state.profileModule.userEmail
            });
            if (personalData.status.status != 200) {
              this.$toast.add({ severity: 'error', summary: 'Error Message', detail: `Bad request. Try againe later!`, life: 3000 });
            }
            if (personalData) {
              this.first_name = personalData.status.data.first_name;
              this.last_name = personalData.status.data.last_name;
              // this.country = personalData.status.data.country;
              this.state = personalData.status.data.state;
              this.city = personalData.status.data.city;
              this.postal_code = personalData.status.data.postal_code;
              this.address = personalData.status.data.address;
              this.phone = personalData.status.data.phone;
            }
          },
          async updateProfile() {
            let response = await this.$store.dispatch('updatePersonalInfo', {
              user_email: this.$store.state.profileModule.userEmail,
              first_name: this.first_name,
              last_name: this.last_name,
              country: this.country,
              state: this.state,
              city: this.city,
              postal_code: this.postal_code,
              address: this.address,
              phone: this.phone
            });
            if (response && response.status && response.status.type == 'Success') {
              this.$toast.add({severity:'success', summary: 'Success Message', detail: `${response.status.message}`, life: 3000 });
            }
            if (response && response.status && response.status.type == 'Error') {
              this.$toast.add({ severity: 'error', summary: 'Error Message', detail: `${response.status.message}`, life: 3000 });
            }
          }
      },
      async created () {
        if (this.$route.meta.noAuthRequired) {
          return;
        }
        let isValid = await this.$store.dispatch('checkLoginState');
        if (!isValid) {
          this.$router.push({
            name: 'login'
          });
        }
      },
      beforeRouteUpdate (to, from, next) {
          
      },
      mounted() {
        this.getCountries();
        this.getZoon();
        this.getPersonalInfo();
      }
      }
      </script>
      
  <style scoped>
  h1{
    margin-left: 10px;
    font-size: 27px;
    font-family: Oswald;
  }    
  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
    display: flex;
  }
  .loginHd {
    color: #272727;
    font-family: oswaldbook;
    font-size: 26px;
    text-align: center;
    padding: 30px 0;
  }
  .loginBox {
    background: #fff;
    width: 100%;
    max-width: 734px;
    padding: 20px;
    min-height: 200px;
    margin: auto;
    padding-bottom: 50px;
  }
  .returningCustomer {
    width: 50%;
    float: left;
    padding-right: 50px;
  }
  .retCustHd {
    position: relative;
    padding-left: 36px;
  }
  input{
    padding: 7px !important;
  }
  </style>  