<template>
<!-- <Dialog
            header="Save Labels as Product"
            v-model:visible="this.displayDialog"
            :breakpoints="{'960px': '750vw', '640px': '90vw'}" 
            :style="{width: '31vw'}"
            >
            <div class="grid">
                <div class="col-12">
                    <TabView>
                        <TabPanel>
                            <template #header>
                                <span @click="setTypeForSave(true)">
                                    <i class="pi pi-save mr-2"></i>
                                    <span @click="setTypeForSave">Save product</span>
                                </span>
                            </template>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span @click="setTypeForSave(false)">
                                    <i class="pi pi-server mr-2"></i>
                                    <span>Save product as a variation</span>
                                </span>
                            </template>
                        </TabPanel>
                    </TabView>
                </div>
                    <div class="col-12">
                        <div class="p-inputgroup flex-1 mb-3">
                            <InputText
                                class="input-field mb-1"
                                v-model="productName"
                                placeholder="Input product name"
                            /> 
                        </div>
                
                        <div class="col-12">
                            <Button label="Save product" :ref="'saveProd'" class="" @click="saveProduct(false)"/>
                        </div>
                    </div>
            </div>
        </Dialog> -->

    <div>
        <Dialog 
            header="Save Labels" 
            v-model:visible="this.displayDialog" 
            :breakpoints="{'960px': '75vw', '640px': '90vw'}" 
            :style="{width: '25vw'}">
            <div class="grid">
                <div class="col-12">
                    <TabView>
                        <TabPanel>
                            <template #header>
                                <span @click="setTypeForSave(true)">
                                    <i class="pi pi-save mr-2"></i>
                                    <span @click="setTypeForSave">Save</span>
                                </span>
                            </template>
                        </TabPanel>
                        <!-- <TabPanel>
                            <template #header>
                                <span @click="setTypeForSave(false)">
                                    <i class="pi pi-server mr-2"></i>
                                    <span>Save as a variation</span>
                                </span>
                            </template>
                        </TabPanel> -->
                    </TabView>
                    <div class="p-inputgroup">
                        <InputText type="text" class="input-field" v-model="labelName"  placeholder="Label name" />
                        <Button  class="input-field" label="Save"  @click="saveLabelAs"/>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
export default {
	name: "SaveLabel",
    props: ['label'],
    data() {
        return {
            displayDialog: false,
            labelName: this.label.name || '',
            labelID: 0,
            isUsers: null,
            isNewProduct: true
        }
    },
	methods: {
        openSaveLabelPopUp() {
            this.displayDialog = true;
            this.labelName =  this.label.name || '',
            this.labelID = this.label.id || 0
        },
		saveLabelAs() {
            this.emitter.emit('saveLabelAs', {
                labelName: this.labelName || this.label.name, 
                labelID: this.labelID || this.label.id,
                isUsers: this.label.customer_id || null,
                isNewProduct: this.isNewProduct ? 1 : 0
            });
            this.displayDialog = false;
		},
        setTypeForSave(param) {
            this.isNewProduct = param;
        }
	}
}
</script>
<style scoped>
.list-label{
	cursor: pointer;
}
.input-field{
    height: 40px !important;
    border: 1px solid #ced4da !important;
}
</style>