<template>
		<main id="wrapper">
			<div id="editor" class="grid">
				<editor-component :selectedProduct="selectedProduct" ref="editor"></editor-component>
			</div>
		</main>		
</template>
<script>
import EditorComponent from "@/components/editor/EditorComponent";
  
  export default {
    name: 'EditorPage',
    components: {
      EditorComponent
    },
    data() {
      return {
        selectedProduct: null
      }
    },
    methods: {
      selectProduct(uid) {
        this.selectedProduct = uid;
      }
    },
    mounted(){
    },
    created () {
      this.selectProduct(this.$route.params.id);
      if (!this.$route.params.id) {
        this.$store.state.order.productName = '';
      }
    },
    beforeRouteUpdate (to, from, next) {
      // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    // if (answer) {
        this.selectProduct(to.params.id);
        next();
    // } else {
    //   next(false)
    // }
    },
  beforeRouteLeave(to, from, next) {
      if (this.$refs.editor.history['front'].current.length || this.$refs.editor.history['back'].current.length) {
        this.$confirm.require({
          message: 'Are you sure you want to leave this page? All changes will be not saved!',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          rejectClass: 'p-button-secondary p-button-outlined',
          rejectLabel: 'Cancel',
          acceptLabel: 'Yes',
          accept: () => {
            this.$store.state.order.productName = '';
            next();
          },
          reject: () => {
            next(false);
          }
        });
      } else {
        this.$store.state.order.productName = '';
        next();
      }
    }
  }
</script>
  
  <style scoped>

  #editor{
    background-color: none;
	margin-top: 21px;
  width: 1300px;
  }
  </style>
  