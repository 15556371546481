import axios from 'axios';

const mutations = {
  cacheUser(state, { token, email, displayName, isGuest }) {
    state.isLoggedIn = true;
    state.userToken = token;
    state.userEmail = email;
    state.userDisplayName = displayName;
    state.isGuest = isGuest;
  },

  setAdmin(state, { isAdmin }) {
    state.isAdmin = isAdmin;
  },

  deleteUserCache(state) {
    state.isLoggedIn = false;
    state.userToken = '';
    state.userEmail = '';
    state.userDisplayName = '';
    state.isAdmin = '';
    state.isGuest = '';
  },
};

const actions = {
  async login({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/jwt-auth/v1/token`, {
      username: payload.username,
      password: payload.password,
    });
    const data = response.data;

    localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('token', data.token);
    localStorage.setItem('displayName', data.user_display_name);
    localStorage.setItem('email', data.user_email); 
  
    await commit('cacheUser', {
      token: data.token,
      email: data.user_email,
      displayName: data.user_display_name,
    });     
    if (data.user_email) {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/is-admin`, {
        'user_email': data.user_email
      });

      await commit('setAdmin', {
        isAdmin: response.data.is_admin
      });
      localStorage.setItem('isAdmin', response.data.is_admin);
    }
    return true;
  },
//   async checkIsAdmin({ commit }, payload) {
//   const is_admin = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/is-admin`, {
//     'user_email': localStorage.getItem('email')
//   });
// },
  async logout({ commit }) {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('displayName');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isGuest');

    commit('deleteUserCache');
  },

  /**
   * Check if user if logged in
   */
  async checkLoginState({ commit }) {
    const token = localStorage.getItem('token');
    // if no token, empty the loggedIn cache
    if (!token) {
      await commit('deleteUserCache');
      return false;
    }

    // if has token, check if it's still valid
    try {
        await axios.post( 
        `${process.env.VUE_APP_API_URL}/jwt-auth/v1/token/validate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // if still valid, cache it again
      await commit('cacheUser', {
        token,
        email: localStorage.getItem('email'),
        displayName: localStorage.getItem('displayName'),
      });
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/is-admin`, {
        'user_email': localStorage.getItem('email')
      });

      await commit('setAdmin', {
        isAdmin: response.data.is_admin
      });

      return true;
    } catch (error) {
      localStorage.setItem('token', '');
      return false;
    }
  },

  /**
   * User registration
   */
  async register({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/register`, payload);
    return {
      status: response.data,
    };
  },
  async changePassword({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/changePassword`, payload);
    return {
      status: response.data
    };
  },
  async getPersonalInfo({ commit }, payload) {
    let headers = {'Authorization':  `Bearer ${localStorage.getItem('token')}`};
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/personal-info`, payload, {headers});
    return {
      status: response.data
    };
  },
  async updatePersonalInfo({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/update-personal-info`, payload);
    return {
      status: response.data
    };
  },
  async forgotPassword({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/forgot-password`, payload);
    return {
      status: response.data
    };
  },
  async isAdmin({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/is-admin`, payload);

    await commit('setAdmin', {
      isAdmin: response.data.is_admin
    });
  },

  async checkUser({ commit }, payload) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/custom/v1/checkUser`, payload);
    const data = response.data;

   if (data.status == 500) {
    localStorage.setItem('displayName', 'Guest');
    localStorage.setItem('email', payload.userEmail);
    localStorage.setItem('isGuest', true);
   
    await commit('cacheUser', {
      token: '',
      email: payload.userEmail,
      displayName: 'Guest',
      isGuest: true
    });
      
    return true;
    }
         
    return {
      status: response.data.status,
    };
  }
    
  // async registerNonce({ commit }, payload) {
  //   // try {
  
  //     const response = await axios.post(`http://localhost/noontimelablesvue2/wp-json/custom/v1/register/nonce`, payload);
      
  
  //     return {
  //       status: response.status,
  //     };
  //   }
};


const profileModule = {
  // This is global data, use mutations and actions to change this value.
  state: {
    isAdmin: localStorage.getItem('isAdmin')  == true,
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    userToken: localStorage.getItem('token') || '',
    userEmail: localStorage.getItem('email') || '',
    userDisplayName: localStorage.getItem('displayName') || '',
  },

  mutations,
  actions,
  modules: {
  },
  namespace: 'profileModule'
};

export default profileModule;