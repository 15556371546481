<template>
    <div>
     <Dialog
        v-model:visible="showAuthPopUp"
        modal
        header="Sign in to your customer account"
        :style="{ width: '25vw' }">
        <InputText
            v-model.trim="username"
            class="mt-1 w-full"
            placeholder="Enter Email Address" />
        <InputText
            type="password"
            class="mt-1 w-full mb-3"
            v-model.trim="password"
            placeholder="Enter Password" />
            <!-- <div class="alreadyaccount mt-2">
                or register new accout click <a @click="registerNewAccount()">here</a>
            </div> -->
        <template #footer>
            <Button label="Login" type="button" @mouseup="login()"/>
            <Button label="Cancel" @click="showAuthPopUp = false"/>
        </template>
    </Dialog>

    <!-- If need register-->
    <!--<Dialog
        v-model:visible="showRegisterPopUp"
        modal
        header="Create Your Noontime Account"
        :style="{ width: '40vw' }">
       <span>Fields marked with <label class="redStar">*</label> are required</span>
                    <InputText class="mt-1 w-full mb-3" v-model="first_name" placeholder="First Name *" />
                    <InputText class="mt-1 w-full mb-3" v-model="last_name" placeholder="Last Name *"/>
                    <InputText class="mt-1 w-full mb-3" v-model="user_email" placeholder="Email *" />
                    <InputText type="password" class="mt-1 w-full mb-3" v-model="user_pass" placeholder="Password *" />
                    <InputText type="password" class="mt-1 w-full mb-3" v-model="user_pass_confirm" placeholder="Confirm Password *"/>                   
               <div class="alreadyaccount mt-2">				
                    If you already have an account <a @click="showAuthBlock()" class="">click here</a>
                </div> 
        <template #footer>
             <Button class="" type="button" label="Submit" @click="register"/>
             <Button label="Cancel" @click="showRegisterPopUp = false"/>
        </template>
    </Dialog> -->
</div>
</template>
<script>
import InputNumber from 'primevue/inputnumber';

export default {
name: "AuthPopUp",
components: {
    InputNumber: InputNumber
},
data() {
    return {
        showAuthPopUp: false,
        showRegisterPopUp: false,
        username: null,
        password: null,
        first_name: null,
        last_name: null,
        user_email: null,
        user_pass: null,
        user_pass_confirm: null
    }
},
methods: {
    showAuthBlock() {
        this.showAuthPopUp = true;
        this.showRegisterPopUp = false;
    },
    async login() {
        try {
            await this.$store.dispatch('login', {
                username: this.username,
                password: this.password
            });
            let userName = this.$store.state.profileModule.userDisplayName;
            this.$toast.add({ severity: 'info', summary: 'Success', detail: `Now you can continue as ${userName}`, life: 3000 });
            this.showAuthPopUp = false;            
        } catch (error) {
            this.$toast.add({ severity: 'error', summary: 'Error Message', detail: 'Email or password is wrong!', life: 3000 });
        }
    },
    // registerNewAccount() {
    //     this.showAuthPopUp = false;
    //     this.showRegisterPopUp = true;
    // },
    // async register(){
    //     const response = await this.$store.dispatch('register', {
    //         first_name: this.first_name,
    //         last_name: this.last_name,
    //         user_email: this.user_email,
    //         user_email_confirm: this.user_email,
    //         user_pass: this.user_pass,
    //         user_pass_confirm: this.user_pass_confirm,
    //         timestamp: new Date().getTime(),
    //     });
    //     if (response && response.status && response.status.type) {
    //         if (response.status.type == 'Success') {
    //             this.$toast.add({severity: 'success', summary: 'Success Message', detail: `${response.status.message}`, life: 3000 });             
    //             this.showRegisterPopUp = false;
    //             setTimeout(() => {
    //                 this.showAuthPopUp = true;
    //             },3000);                   
    //         }
    //         if (response.status.type == 'Error') {
    //             this.$toast.add({severity: 'error', summary: 'Error Message', detail: `${response.status.message}`, life: 3000 });
    //         }
    //     }
    // }
},
mounted() {
   
}
}
</script>
<style scoped>
input{
    border: 1px solid #c3b8b8 !important;
    color: #9a9aba;
    display: block;
    height: 35px;
    font-family: open_sansregular;
    font-size: 13px;
    font-weight: normal;
}
input:focus{
    border: 1px solid black !important;
}
</style>