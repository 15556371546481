import { createApp } from 'vue';
import App from './App';
import router from "@/router/router";
import mitt from 'mitt';
const emitter = mitt();

import globalsMixins from './mixins/globalsMixins';
import useAuthStore from './store/stores-auth';
import useUserStore from './store/stores-user';



import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Steps from 'primevue/steps';
import DataView from 'primevue/dataview';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import Skeleton from 'primevue/skeleton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'vue-color-kit/dist/vue-color-kit.css'
import '@/libs/fabricCurvedText';
import {convertImg} from '@/libs/converter';


import store from "@/store";

const app = createApp(App);
  
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.productionTip = false;
app.config.globalProperties.data = {
    $canvasFront: null,
    $canvasBack: null,
    $editorFront: null,
    $editorBack: null,
    $shapeFront: null,
    $shapeBack: null,
    $cuttingLineFront: null,
    $cuttingLineBack: null,
    $cuttingLineDashedFront: null,
    $cuttingLineDashedBack: null,
    $scaleRatioFront: 0.8,
    $scaleRatioBack: 0.8,
    $rendering: 0,
    $activeCanvas: null
};

app.config.globalProperties.convertImg = convertImg;

app.config.globalProperties.pathUrl = process.env.VUE_APP_PATH_URL;
app.config.globalProperties.$wpAjaxUrl = process.env.VUE_APP_WP_URL;
app.config.globalProperties.pathPluginImage = process.env.VUE_APP_WP_PATH_PLUGIN_IMAGES;

app.component('font-awesome-icon', FontAwesomeIcon);

app.component('InputText', InputText);
app.component('Button', Button);
app.component('Toast', Toast);
app.component('Dialog', Dialog);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Steps', Steps);
app.component('DataView', DataView);
app.component('Dropdown', Dropdown);
app.component('InputNumber', InputNumber);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Skeleton', Skeleton);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);

app.directive('tooltip', Tooltip);

app.mixin(globalsMixins);

app
    .use(PrimeVue)
    .use(ToastService)
    .use(ConfirmationService)
    .use(store)
    .use(useAuthStore)
    .use(useUserStore)
    .use(router)
    .mount('#app');
