<template>
    <div>
        <Dialog header="Graphics & Galleries" v-model:visible="this.displayGraphics" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '70vw'}">
            <TabView>
                <TabPanel header="Browse Graphics">
                    <div id="graphics-gallery" class="toolbar-gallery">
                    <div class="image-search-row">
                        <ul v-if="this.graphicsFolders" class="search-ideas">
                            <li v-for="(graphic, index) in this.graphicsFolders" :data-name="'graphic'" v-bind:key="index"><a :href="'#' + graphic">{{ graphic }}</a></li>
                        </ul>
                    </div>
                    <div class="gallery-holder">
                        <ul class="pixels-gallery">
                            <li v-for="(graphics, index) in this.graphics" v-bind:key="index">
                                <div :data-name="'index'" :id="index">{{ index }}</div>
                                    <ul class="pixels-gallery">
                                        <li v-for="(graph, index) in graphics" v-bind:key="index">
                                            <img class="library-img" @click="getGraphic($event)" :src="graph">
                                        </li>
                                    </ul>
                                <hr>
                            </li>
                        </ul>
                    </div> 
                </div>
                </TabPanel>
                <TabPanel header="Browse Gallery">
                    <div id="images-gallery" class="toolbar-gallery">
                    <div class="image-search-row">
                        <ul v-if="this.galleries && this.galleries.length" class="search-ideas">
                            <li v-for="gallery in this.galleries" v-bind:key="gallery.id"
                                @click="getGalleries(gallery)"
                            >{{gallery}}</li>
                        </ul>
                    </div>
                    <div class="gallery-holder">
                        <ul class="pixels-gallery" v-if="this.galleryImages && this.galleryImages.length">
                            <li v-for="(image, index) in this.galleryImages"  v-bind:key="index">
                                <img class="library-img2" @click="getImage($event)" :src="image">
                            </li>
                        </ul>
                    </div>
                </div>
                </TabPanel>
            </TabView>
        </Dialog>
    </div>
</template>

<script>
import axios from 'axios';
export default {
	name: "GraphicsComponent",
	data() {
		return {
            displayGraphics: false,
			graphicsFolders: null,
			graphics: null,
            galleries: null,
			galleryName: null,
			galleryImages: null
		}		
	},
	methods: {
        openGraphics() {
            this.displayGraphics = true;
        },
		closeBasic() {
            this.displayGraphics = false;
        },
		getGraphics() {		
			let form = new FormData;
            form.append('action', 'dm_graphics_graphic');
            axios({
                method: 'post',
                url: this.$wpAjaxUrl,
                data: form
            }).then(response => {
                    this.graphicsFolders = Object.getOwnPropertyNames(response.data['graphics']);
                    this.graphics = response.data['graphics'];
            }).catch(error => {
                console.log(error);			
                // this.$events.fire('exception', {errorData: error.response.data});
            });
		},
        getGraphic(event) {
            event.target.classList.remove('library-img');
            this.emitter.emit('addImage', {
                src: event.target.src,
                width: event.target.width,
                height: event.target.height,
                isCreate: true
            });
            this.displayGraphics = false;
        },
        getGalleries(gallery) {		
			let form = new FormData;
				form.append('action', 'dm_galleries_image');
				if(gallery) {
					form.append('gallery-name', gallery);
				}
				axios({
					method: 'post',
					url: this.$wpAjaxUrl,
					data: form
				}).then(response => {
					this.galleries = response.data.galleries;
					this.galleryName = response.data['gallery-name'];
					this.galleryImages = response.data['gallery-images'];
				}).catch(error => {
					console.log(error);
					// this.$events.fire('exception', {errorData: error.response.data});
				});
		},
		getImage(event) {
			this.emitter.emit('addImage', {
				src: event.target.src,
				width: event.target.width,
				height: event.target.height
			});
			this.displayGraphics = false;
		}
	},
	mounted() {
		this.getGraphics();
        this.getGalleries();
	}
}
</script>

<style scoped>
#graphics-gallery .search-ideas{
    padding: 0;
    margin: 0;
}
#graphics-gallery .search-ideas li{
    list-style: none;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.4em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px 10px;
    margin: 0;
}
#graphics-gallery .search-ideas li:hover{	
	font-weight: 500;
    cursor: pointer;
}
.gallery-holder{
	position: relative;
}
#graphics-gallery .pixels-gallery{
	flex-wrap: wrap;
    -webkit-box-pack: justify;
	display: flex;
	/* justify-content: space-between; */
    padding: 10px;
    list-style: none;
    margin: 5px;
    position: relative;
    min-width: 60px;
}
#graphics-gallery .pixels-gallery li img{
	background: #fff;
	box-shadow: 0px 3px 3px #b6b6b6;
}
.library-img{
	max-height: 80px;
    padding: 1px;
    margin: 5px;
}
#graphics-gallery .pixels-gallery li{
    list-style: none;
    display: inline-block;
    cursor: pointer;
}



#images-gallery .search-ideas{
    padding: 0;
    margin: 0;
}
#images-gallery .search-ideas li{
    list-style: none;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.4em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px 10px;
    color: #fff;
    border-radius: 15px;
    margin: 0;
    background: #aaaaaa;
    border: 1px solid white;
}
#images-gallery .search-ideas li:hover{
	border: 1px solid black;
	background: #b59393;
}
.gallery-holder{
	position: relative;
}
#images-gallery .pixels-gallery{
	flex-wrap: wrap;
    -webkit-box-pack: justify;
	display: flex;
	justify-content: space-between;
    padding: 10px;
    margin: 0;
    min-height: 80px;
}
#images-gallery .pixels-gallery li img{
	background: #fff;
	box-shadow: 0px 3px 3px #b6b6b6;
    width: 200px;
}
.library-img2{
	width: 200px;
}
#images-gallery .pixels-gallery li{
    list-style: none;
    display: inline-block;
    cursor: pointer;
}
</style>