<template>
	<section id="tool-bar">
		<header class="header">
			<div class="toolbar-button" @click="uploadImage">
				<font-awesome-icon :icon="icons.image"/>
				<span>&nbsp;Add image</span>
			</div>
			<div class="toolbar-button" @click="showGraphics">
				<font-awesome-icon :icon="icons.faBrush"/>
				<span>&nbsp;Add graphic</span>
			</div>
			<div class="toolbar-button" @click="addText">
				<font-awesome-icon :icon="icons.text"/> 
				<span>&nbsp;Add text</span>
			</div>
			<div class="toolbar-button" @click="addBorder">
				<font-awesome-icon :icon="icons.border"/>
				<span>&nbsp;Add border</span> 
			</div>
		</header>
		<header class="header-bottom">
			<div class="action-area">
				<div class="tool-bar">
					<Button
						class="p-button-text bottom-menu"
						@click="startOverPopUp"
						:disabled="(history.front.current.length === 0 && history.front.trash.length === 0) &&
							(history.back.current.length === 0 && history.back.trash.length === 0)">
							<font-awesome-icon :icon="icons.restart" class="flip"/> 
							<span>&nbsp;Start Over</span>
					</Button>
					<Button variant="light" :disabled="history[this.$store.state.canvas.$side].current.length === 0" @click="undo" class="p-button-text bottom-menu">
						<font-awesome-icon :icon="icons.undo"/>&nbsp;Undo</Button>
					<Button variant="light" :disabled="history[this.$store.state.canvas.$side].trash.length === 0" @click="redo" class="p-button-text bottom-menu">
						<font-awesome-icon :icon="icons.redo"/>&nbsp;Redo</Button>
					<Button variant="light" @click="increaseCanvas" class="p-button-text bottom-menu"><font-awesome-icon :icon="icons.zoomIn"/></Button>
						<span class="zoom-value"><span class="text-sm">Zoom In</span>&nbsp;<!--{{zoom}}%--></span>
					<Button variant="light" @click="decreaseCanvas" class="p-button-text bottom-menu"><font-awesome-icon :icon="icons.zoomOut"/></Button><span class="text-sm">Zoom Out</span>
				</div>
			</div>
		</header>
		<Dialog v-model:visible="setOverPopUp" modal header="Start over" :style="{ width: '25vw' }">
        <h6><i class="pi pi-info-circle" style="font-size: 1rem"></i> Are you sure to start over? All layers will not be saved!!!</h6>
    	<div id="block-type">
		</div>
        <template #footer>
            <Button label="Yes" type="button" severity="danger" @click="startOver"/>
            <Button label="No" @click="setOverPopUp = false"/>
        </template>
    </Dialog>
	</section>	
</template>
<script>
import {
	faBorderStyle,
	faFont,
	faPencilRuler,
	faShapes,
	faSearchPlus,
	faSearchMinus,
	faTrashAlt,
	faShoppingCart,
	faReply,
	faShare,
	faRedo,
	faUpload,
	faSave, faBrush
} from "@fortawesome/free-solid-svg-icons";
import {faImage} from "@fortawesome/free-regular-svg-icons";
import {mapState} from 'vuex';

export default {
	name: "ToolBar",
	props: ['history', 'settedShape', 'activeEditor', 'is_admin', 'is_admin_edit'],
	data() {
		return {
			setOverPopUp: false,
			zoom: 100,
			sideZoom: {
				front: 100,
				back: 100
			},
			icons: {
				shapes: faShapes,
				image: faImage,
				pencilRuler: faPencilRuler,
				text: faFont,
				border: faBorderStyle,
				zoomIn: faSearchPlus,
				zoomOut: faSearchMinus,
				trash: faTrashAlt,
				cart: faShoppingCart,
				undo: faReply,
				redo: faShare,
				restart: faRedo,
				upload: faUpload,
				faSave: faSave,
				faBrush: faBrush
			},
			isAdmin: false		
		}
	},
	methods: {
		startOverPopUp() {
			this.setOverPopUp = true;
		},
		addText() {
			this.emitter.emit('addIText', {text: 'New text'});
		},
		addCurvedText() {
			this.emitter.emit('addCurvedText', {text: 'Curved text'});
		},
		addBorder() {
			this.emitter.emit('addBorder');
		},
		showGraphics() {
			this.emitter.emit('showGraphics');
		},		
		uploadImage() {
			this.emitter.emit('showUploadImages');
		},
		checkObjects() {
			this.emitter.emit('checkObjects', (data) => this.checkObjects(data));
		},
		increaseCanvas() {
			// let settedZoom = this.sideZoom[this.$side];
			// settedZoom += 5;
			// if (settedZoom > 300) {
			// 	settedZoom = 300;
			// 	this.zoom = 300;
			// 	return;
			// } else {
			// 	this.zoom = settedZoom;
			// }
			// this.sideZoom[this.$side] = settedZoom;
			// this.emitter.emit('increaseCanvas', {zoom: settedZoom});
			this.emitter.emit('increaseCanvas');
		},
		decreaseCanvas() {
			// let settedZoom = this.sideZoom[this.$side];
			// settedZoom -= 5;
			// if (settedZoom < 40) {
			// 	settedZoom = 40;
			// 	this.zoom = 40;
			// 	return;
			// } else {
			// 	this.zoom = settedZoom;
			// }	
			// this.sideZoom[this.$side] = settedZoom;
			// this.emitter.emit('decreaseCanvas', {zoom: settedZoom});
			this.emitter.emit('decreaseCanvas');
		},
		undo() {
			this.emitter.emit('undoCanvas');
		},
		redo() {
			this.emitter.emit('redoCanvas');
		},
		startOver() {
			this.emitter.emit('startOver');
			this.setOverPopUp = false;
		},
		// loadLabelsList() {
		// 	if (!this.$store.state.profileModule.isLoggedIn && this.$router.currentRoute.value.fullPath == '/editor/') {
        // 		this.emitter.emit('popUpLogin');
       	// 	} else {
		// 		this.emitter.emit('loadLabelsList')
		// 	} 
		// },
		// saveLabelModal() {
		// 	this.emitter.emit('showSaveLabelPopUp');
		// },
		// saveProductModal() {
		// 	this.emitter.emit('showSaveProductPopUp');
		// },
		setDefaultZoom() {
			// this.zoom = 100;
			// this.sideZoom.front = 100;
			// this.sideZoom.back = 100;
		}
	},
	unmounted() {
		this.emitter.all.clear();
	},
	mounted() {
		this.emitter.on('setDefaultZoom', () => this.setDefaultZoom());
	},
	computed: {
		...mapState({
			$side: state => state.canvas.$side
		})
	}
}
</script>
<style scoped>
.p-button-text{
	color: black !important;
}
.bottom-menu {
	padding: 0;
	margin: 8px;
}
.size-field{
	width: 70px;
	box-sizing: border-box;
	display: inline;
}
.custom-size{
	width: 250px;
}
.action-area{
	width: 100%;
	margin: 0 0 20px 0;
}
.tool-bar{
	width: 460px;
	margin: 0 auto;
	padding: 0 5px;
	text-align: center;
	background: #e8e8e8;
	border-radius: 12px;
}
.tool-bar>.btn-light{
	background: #e8e8e8;
	border: none;
	font: 12px/14px Georgia, "Times New Roman", Times, serif;
}

.tabset-holder{
	/* margin-top: -25px; */
	display: flex;
	background-color: #dee2e6;
}
.tabset{
	display: flex;
	width: 50%;
	padding: 0;
	margin: 0;
	list-style: none;
	font: 15px/16px Roboto, Geneva, sans-serif;
	font-weight: normal;
	font-weight: 500;
}
.tabset li{
	display: flex;
	max-width: 32%;
	width: auto;
	flex: 1 0 auto;
	-webkit-box-align: stretch;
	-webkit-box-flex: 1;
	align-items: stretch;
}
.tabset .active{
	color: #b93028;
	background: #f4f4f4;
	text-decoration: none;
	border-color: #d2d2d2;
	position: relative;
	border-bottom: 0;
	margin-bottom: -1px;
	z-index: 0;
}
.tabset a{
	margin-right: 4px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	line-height: 1.2em;
	padding: 8px;
	box-sizing: border-box;
	text-align: center;
	border: 1px solid #d2d2d2;
	border-width: 1px 1px 0;
	border-radius: 10px 10px 0 0;
	flex: 1 1 auto;
}
.control {
  display: inline-block;
  padding: 2px 4px 3px 4px;
  border: none;
  cursor: pointer;
  background: none;
  color: black;
  margin: 0 0 10px 10px;
}
.header, .header-bottom{
	background: #f4f4f4;
}
.header{
	justify-content: center;
}
.toolbar-button{
	display: inline-block;
	background: inherit;
	color: #000;
	width: auto;
	margin-right: 20px;
	cursor: pointer;
	font-size: 15px;
	padding: 7px 0 7px 0;
}
.header-item{
	display: inline-block;
	margin-right: 10px;
	font: 12px/14px Georgia, "Times New Roman", Times, serif;
	margin: 20px 0;
}
.nonactive{
	margin-right: 0;
	background: #635f5c;
	color: #fff;
	border-radius: 10px 10px 0 0;
	border-width: 1px 1px 0;
	line-height: 2;
    padding: 5px;
	cursor: pointer;
}
.zoom-value{
	line-height: 2;
}
</style>